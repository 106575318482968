import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {feedbackActions, hideModalPopup} from "../../../redux/actions";
import InputField from "../../common/input/InputField";
import {colors} from "../../../utils/style/style";
import {ROUTE_MY_SUBSCRIPTION} from "../../../routes/constants";

export const BookingFeedback = (props) => {
	const [rating, setRating] = useState(5)
	const [detailsMessage, setDetailsMessage] = useState('')
	const [selection, setSelection] = useState(0);

	const dispatch = useDispatch()
	const {booking, subscriptionId} = props
	const agentName = booking?.agents ? booking.agents[0]?.user?.name : '-'


	const Star = ({marked, starId}) => {
		return (
			<span data-star-id={starId} className="star" role="button">
      {marked ? '\u2605' : '\u2606'}
    </span>
		);
	};


	const hoverOver = event => {
		let val = 0;
		if (event && event.target && event.target.getAttribute('data-star-id'))
			val = event.target.getAttribute('data-star-id');
		setSelection(val);
	}

	const onSubmitFeedback = () => {
		dispatch(hideModalPopup())
		const params = [booking.id, rating, detailsMessage]
		if (subscriptionId) {
			params.push(ROUTE_MY_SUBSCRIPTION + "?id=" + subscriptionId)
		}
		dispatch(feedbackActions(...params))
	}

	return (
		<div className="text-center">
			<p className="mb-0">Evalueaza experienta ta {agentName ? "cu partenerul " + agentName : ""}</p>
			<div
				style={{fontSize: '2rem', color: colors.brand, marginBottom: '1rem'}}
				onMouseOut={() => hoverOver(null)}
				onClick={e => setRating(e.target.getAttribute('data-star-id') || rating)}
				onMouseOver={hoverOver}
			>
				{Array.from({length: 5}, (v, i) => (
					<Star
						starId={i + 1}
						key={`star_${i + 1}`}
						marked={selection ? selection >= i + 1 : rating >= i + 1}
					/>
				))}
			</div>

			<InputField
				className="w-100"
				type="text"
				isTextarea={true}
				rows={3}
				placeholder="Spune-ne mai multe detalii"
				value={detailsMessage}
				setValue={setDetailsMessage}
				onSubmitForm={onSubmitFeedback}
			/>

			<button
				className="button-gradient-primary"
				style={{display: 'initial'}}
				onClick={onSubmitFeedback}
			>
				Trimite
			</button>

		</div>
	)
}
export default BookingFeedback