import { HIDE_INTRO_SLIDER, LOGOUT_RESET } from '../constants'

const initialState = true

export const introSlider = (state = initialState, action) => {
    switch (action.type) {
        case HIDE_INTRO_SLIDER:
            return false
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}