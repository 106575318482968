import React from "react";
import GuestTemplate from "./GuestTemplate";
import {Col, Container, Row} from "react-bootstrap";

import partener from "../../assets/images/new/partener.png"
import "../../assets/css/guest/partener.scss"

export const PartnerPage = (props) => {
	return (
		<GuestTemplate title="Devino partener Zumzi" subtitle="Castiga bani transparent" image={null}>
			<Container className="partner-container mb-5">
				<Row>
					<Col lg={4} className="flex-center offset-lg-1 mb-5">
						<img src={partener} alt="partener zumzi"/>

						<a href="https://www.agent.zumzi.com/register"
						   className="d-none d-lg-block button-gradient-primary">
							Inscrie-te
						</a>
					</Col>
					<Col lg={6} className="offset-lg-1" style={{display: 'flex', alignItems: 'center'}}>
						<div>
							<h4 style={{marginBottom: '2rem'}}>
								Alatura-te echipei noastre de profesionisti si ai parte de cele mai tari beneficii:
							</h4>
							<ul>
								<li>
									Castigi bani transparent
								</li>
								<li>
									Volum mai mare de clienti
								</li>
								<li>
									Lucrezi cat vrei, dupa propriul program
								</li>
								<li>
									Primesti comenzi din zona ta
								</li>
								<li>
									Alegi comenzile potrivite pentru tine
								</li>
								<li>
									Castigi bonusuri pe baza aprecierilor clientilor
								</li>
								<li>
									Primesti recomandari din partea clientilor
								</li>
								<li>
									Poti veni cu propriul serviciu pe care il vom integra in platforma
								</li>
							</ul>
							<div className="text-center">
								<a href="https://www.agent.zumzi.com/register"
								   className="d-block d-lg-none button-gradient-primary m-auto mt-5">
									Inscrie-te
								</a>
							</div>

						</div>
					</Col>
				</Row>
			</Container>
		</GuestTemplate>
	)
}

export default PartnerPage