import {
	GET_PRODUCT_OPTIONS_SUCCESS,
	HIDE_SPINNER,
	SET_ALL_PRODUCT_OPTIONS,
	SET_SELECTED_PRODUCT_OPTIONS,
	SHOW_ALERT_MODAL,
	SHOW_SPINNER
} from "../constants";
import {axiosRequest} from "../../api/axiosRequest";
import {ROUTE_HOME} from "../../routes/constants";
import {batch} from 'react-redux'

export const getProductOptionsByProduct = (productId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/product_options?product.id=${productId}&sortKey=0&enabled=1`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_OPTIONS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Pagina dorita nu exista', route: ROUTE_HOME}})
		})
	}
}

export const getProductOptionsByParent = (productId, productOptionId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/product_options?product.id=${productId}&enabled=1&dependsOn.id=${productOptionId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_OPTIONS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Pagina dorita nu exista', route: ROUTE_HOME}})
		})
	}
}

export const setSelectedProductOptions = (optionsIds) => async dispatch => {
	dispatch({type: SET_SELECTED_PRODUCT_OPTIONS, payload: optionsIds})
}

export const setAllProductOptions = (optionsIds) => async dispatch => {
	dispatch({type: SET_ALL_PRODUCT_OPTIONS, payload: optionsIds})
}