import React, {useEffect, useState} from 'react'

import {translate} from "../../../utils/i18n";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ROUTE_ORDER_FORM, ROUTE_ORDER_STEP} from "../../../routes/constants";
import {useSelector} from "react-redux";

import "../../../assets/css/guest/services/service.scss"

export const ServiceDescription = (props) => {
	const [image, setImage] = useState({})
	const [defaultLabelPrefix, setDefaultLabelPrefix] = useState('product.')
	const {hideButton, isDark, description} = props

	const product = useSelector(state => state.product)

	useEffect(() => {
		if (product.code) {
			if (product.fixedPrice) {
				if (product.productOptions.length > 1) {
					setDefaultLabelPrefix("product_option." + product.code)
				} else {
					setDefaultLabelPrefix("product_option." + product.code)
				}
			} else {
				setDefaultLabelPrefix("product." + product.code)
			}

			try {
				setImage(require(`../../../assets/images/new/services/${product?.code}.png`))
			} catch (err) {
			}
		}
	}, [product])


	return (
		<Container className="mb-5">
			<Row>
				<Col md={6} className="flex-center mb-5 mb-md-0">
					{
						image.default
						&& <img alt={translate('product.' + product.code)} src={image.default}/>
					}
					{
						!hideButton
							? <Link
								to={product.fixedPrice ? `${ROUTE_ORDER_STEP}?productId=${product.id}` : `${ROUTE_ORDER_FORM}?productId=${product.id}`}
								className="button-gradient-primary">
								Comanda
							</Link>
							: null
					}

				</Col>
				<Col md={6} style={{display: 'flex', alignItems: 'center'}}>
					<div
						className={"service-description-default " + (isDark ? "service-description-dark" : "service-description")}>
						{
							!description
							? <>
									<h4>
									{translate(defaultLabelPrefix + ".details.title")}
								</h4>
								<p className="mb-0" style={{whiteSpace: "break-spaces"}}>
									{"\n"}
								</p>
								<p style={{whiteSpace: "break-spaces"}}>
									{`${translate(defaultLabelPrefix + ".details.desc")}`}
								</p>
							</>
							: <p style={{whiteSpace: "break-spaces"}}>
									{description}
								</p>
						}

						<p>
							Servicii oferite de profesioniștii, din cartierul tău, verificate de noi!
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	)
}