import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";
import {HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from "../constants";

export const changeUserCardForSubscriptionAction = (subscriptionId, userCardId, callback = null) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/subscriptions/${subscriptionId}/user_card`, {userCard: {id: userCardId}})
		dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: "Card modificat cu succes"}})
		if (callback) {
			dispatch(callback)
		} else {
			dispatch({type: HIDE_SPINNER})

		}
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
