
import {SET_ORDER_REQUEST_DETAILS_SUCCESS} from "../constants";
import {orderRequestDetailsInitialState} from "../../utils/model/orderRequestDetails";

export const increaseOrderRequestDetailsStepAction = (orderRequestDetails, stepSize = 1) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetails, currentStep: orderRequestDetails.currentStep + stepSize} })
}

export const decreaseOrderRequestDetailsStepAction = (orderRequestDetails, stepSize = 1) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetails, currentStep: orderRequestDetails.currentStep - stepSize} })
}

export const goToStepOrderRequestAction = (orderRequestDetails, step) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetails, currentStep: step} })
}

export const setOrderRequestDetailsPropertyAction = (orderRequestDetails, property) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetails, property} })
}

export const resetOrderRequestDetailsAction = (currentStep = 1) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetailsInitialState, currentStep}})
}

export const setOrderRequestDetailsTotalPrice = (orderRequestDetails, totalPrice) => async dispatch => {
	dispatch({ type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: {...orderRequestDetails, totalPrice}})
}

export const setOrderRequestDetails = (orderRequestDetails) => async dispatch => {
	dispatch({type: SET_ORDER_REQUEST_DETAILS_SUCCESS, payload: orderRequestDetails})
}
