export const getStringProperty = (property) => {
	let str = "";

	let vars = [
		['number', ', nr: '],
		['block', ', bl: '],
		['stairwell', ', scara: '],
		['floorNumber', ', etaj: '],
		['apartment', ', ap: '],
		['intercom', ', interfon: ']
	]
	vars.forEach((item) => {
		if (property[item[0]] && property[item[0]] !== '0') {
			str += item[1] + property[item[0]]
		}
	})
	str += property.accessMode ? ", acces: " + property.accessMode : ""
	if (str[0] === ',') {
		str = str.substring(2, str.length)
	}
	return str
}