import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {getProductFormAction} from "../../../redux/actions";
import ProductFormInput from "./ProductFormInput";
import {AccountTemplate} from "../template/AccountTemplate";

export const ProductFormPage = (props) => {
    const productFormId = getItemFromUrl(props.location.search, 'id', null)
    const productForm = useSelector(state => state.productForm)

    const dispatch = useDispatch()

    useEffect(() => {
        if (productFormId) {
            dispatch(getProductFormAction(productFormId))
        }
    }, [])


    return (
        <AccountTemplate label="my_product_form">
            {
                productForm?.id
                && <ProductFormInput/>
            }
        </AccountTemplate>

    )
}
export default ProductFormPage