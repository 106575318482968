import React from 'react';
import {Form, InputGroup} from "react-bootstrap";

export const CheckboxField = ({id, noMargin, label, validation, value, setValue, colorType, extraClassName = ''}) => {
    const type = "checkbox"
    return (
        <Form.Group className={noMargin ? "" : "mb-3"} controlId={id}>
            <InputGroup
                hasValidation={!!validation}
            >
                <Form.Check
                    className={"w-100 " + extraClassName}
                    type={type}
                    label={label}
                    isInvalid={validation && !validation.isValid()}
                >
                    <Form.Check.Input
                        checked={value}
                        type={"checkbox"}
                        className={"" + (colorType === 'dark' ? 'dark' : '')}
                        isInvalid={validation && !validation.isValid()}
                        onChange={setValue}
                    />
                    {
                        label
                        ? <Form.Check.Label onChange={setValue}>
                            {label}
                        </Form.Check.Label>
                        : null
                    }

                    {
                        validation && !validation.isValid()
                        && <Form.Control.Feedback type="invalid" style={{marginLeft: '-1.5rem'}}>
                            {validation.inputError}
                        </Form.Control.Feedback>
                    }
                </Form.Check>

            </InputGroup>
        </Form.Group>
    )
}
export default CheckboxField