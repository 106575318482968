import {LOGOUT_RESET, SET_PURCHASE_OPTION} from '../constants'

const initialState = {
    subscription: null,
    subscriptionDuration: null,
    shoppingItems: [
    ]
}

export const purchaseOption = (state = initialState, action) => {
    switch (action.type) {
        case SET_PURCHASE_OPTION:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}