import React from "react";
import {useSelector} from "react-redux";

export const ProfilePicture = (props) => {
    const user = useSelector(state => state.login)

    return (
        <img src={user.image} alt="profil" style={{width: '100px', borderRadius: '100%'}}/>
    )
}
export default ProfilePicture