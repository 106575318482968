import { GET_CITIES_SUCCESS, GET_COMPLEXES_SUCCESS, CLEAR_COMPLEXES_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {
    cities: [],
    complexes: []
}

export const selectComplex = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITIES_SUCCESS:
            return { ...state, cities: action.payload }
        case GET_COMPLEXES_SUCCESS:
            return { ...state, complexes: action.payload }
        case CLEAR_COMPLEXES_SUCCESS:
            return { ...state, complexes: [] }
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}