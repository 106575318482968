import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateUserAction} from "../../../redux/actions";
import {translate} from "../../../utils/i18n";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isEmail, isName, isPhone} from "../../../utils/validation/conditions";
import InputField from "../../common/input/InputField";

import "../../../assets/css/account/personal_data/account_data.scss"

export const PersonalData = (props) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [disabledObject, setDisabledObject] = useState({
        email: true,
        name: true,
        phone: true,
    })

    const validations = {
        email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
        name: useInputValidation(name, isName(name), translate('validation.name.invalid')),
        phone: useInputValidation(phone, isPhone(phone), translate('validation.phone.invalid')),
    }


    const user = useSelector(state => state.login)
    const dispatch = useDispatch()



    useEffect(() => {
        updateUserState()
    }, [user])

    const updateUserState = () => {
        setEmail(user.email)
        setName(user.name)
        setPhone(user.phone)
    }

    const toggleDisableInput = (key) => {
        setDisabledObject({...disabledObject, [key]: !disabledObject[key]})
    }

    const onEditUser = (key, value) => {
        if (!validations[key].inputCheckError()) {
            dispatch(updateUserAction(user.id, {[key]: value}))
            toggleDisableInput(key)
        }
    }

    const onCloseEdit = (keyValue) => {
        updateUserState()
        toggleDisableInput(keyValue)
    }

    const inputs = [
        {
            label: "Nume",
            keyValue: 'name',
            value: name,
            setValue: setName,
            type: 'text',
        },
        {
            label: "Email",
            keyValue: 'email',
            value: email,
            setValue: setEmail,
            type: 'email',
        },
        {
            label: "Telefon",
            keyValue: 'phone',
            value: phone,
            setValue: setPhone,
            type: 'text',
        },
    ]


    return (
        <>
            {
                inputs.map((item, index) => {
                    return (
                        <div key={index} style={{marginBottom: '2rem', clear: 'both'}}>
                            <h6 style={{marginBottom: '0.5rem'}}>
                                {item.label}
                            </h6>
                            <div className="w-100">
                                <InputField
                                    className="input-medium"
                                    type={item.type}
                                    placeholder={translate(`global.placeholder.${item.keyValue}`)}
                                    value={item.value}
                                    setValue={item.setValue}
                                    validation={validations[item.keyValue]}

                                    onUpdate={() => onEditUser(item.keyValue, item.value)}
                                    onCloseEdit={() => onCloseEdit(item.keyValue)}

                                    disabledObject={disabledObject[item.keyValue]}
                                    toggleDisabledInput={() => toggleDisableInput(item.keyValue)}
                                />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}
export default PersonalData