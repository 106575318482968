import React from 'react';
import GuestTemplate from "../GuestTemplate";
import "../../../assets/css/guest/leagal.scss"
import {Col, Container, Row} from "react-bootstrap";

export const PrivacyPage = () => {
	return (
		<GuestTemplate label="privacy" title="Politica de confidentialitate" image={null}>
			<Container>
				<Row>
					<Col sm={12} className="terms-content">
						<p>Utilizarea Platformei Zumzi (site web și aplicație mobilă) presupune încredințarea de
							către dumneavoastră a unor date cu caracter personal către noi (Zumzi), încredere pe
							care ne angajăm să o păstrăm.
							<br/><br/>
							Scopul acestei Politici de confidențialitate este să vă explice ce date prelucrăm,
							de ce le prelucrăm și ce facem cu ele. Vă luăm în serios confidențialitatea și nu
							vindem niciodată liste sau adrese de e-mail, facem tot posibilul să le stocăm în
							siguranță și să le prelucrăm cu atenție.
							<br/><br/>
							În cazul în care anumite Servicii determină existența unor Condiții suplimentare
							(de exemplu politici pentru un anumit eveniment, o anumită activitate sau
							promoție), respectivele condiții suplimentare vă vor fi prezentate, ele se
							adaugă acestor Condiții și vor fi considerate parte integrantă din acestea.
							Condițiile suplimentare vor prevala față de aceste Condiții în cazul unui
							conflict cu privire la Serviciile aplicabile.
							<br/><br/>
							Cine suntem noi? Casta Clean Tech SRL, o societate cu răspundere limitată cu
							sediul în Municipiul Pitești, Bulevardul Republicii, nr. 222, Județul Argeș.
							<br/><br/>
							Potrivit legislației, compania noastră este operator de date cu caracter
							personal. Pentru ca datele dumneavoastră să fie prelucrate în siguranță,
							am depus toate eforturile să implementăm măsuri rezonabile pentru a
							proteja informațiile tale personale.
						</p>
						<br/>
						<ol type="I" style={{'paddingLeft': '1rem'}}>
							<li>Datele pe care le colectăm
								<ol>
									<li>
										<ul className="policy-ul">Date pe care
											dumneavoastră le puneți la dispoziția noastră:
											<li>Datele pe care le introduceți la crearea unui cont și plasarea
												unei comenzi (în formularul de comandă sau în formularul de
												creare cont): nume, prenume, adresă de email, număr de telefon.
												<br/>
												În ceea ce privește datele cardului dumneavoastră bancar pe
												care îl utilizați pentru plata on-line, Zumzi nu are acces
												la ele, acestea fiind colectate de către platforma
												Stripe;
											</li>
											<li>Informații despre locul unde doriți să fie prestat serviciul,
												care poate fi domiciliul dumneavoastră: adresa, numărul și tipul
												de încăperi;
											</li>
											<li>Evaluări sau recenzii pe care le acordați, exclusiv la alegerea
												dumneavoastră, Agenților de curățenie (în cazul în care sunteți
												utilizator) sau Utilizatorilor (în cazul în care sunteți Agent
												de curățenie);
											</li>
											<li>Informații colectate prin intermediul unor sondaje pe care le
												veți completa, exclusiv la alegerea dumneavoastră, referitoare
												la calitatea serviciilor și nivelul dumneavoastră de satisfacție
												sau moduri de îmbunătățire a calității serviciilor.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Date pe care
											le obținem prin utilizarea Platformei Zumzi de către dumneavoastră
											<li>Este posibil să colectăm informații despre vizita dumneavoastră
												pe Platformă. Aceste informații pot include adresa IP, sistemul
												de operare, browserul, activitatea de navigare și alte
												informații despre modul în care ați interacționat cu Platforma.
												Putem colecta aceste informații prin folosirea cookie-urilor sau
												a altor tehnologii de similare.
											</li>
										</ul>
									</li>
								</ol>
							</li>
							<li>Scopul colectării datelor
								<ol>
									<li>
										<ul className="policy-ul">Înregistrarea
											contului și procesarea comenzii dumneavoastră
											<li>Colectăm numele, mail-ul și numărul de telefon pentru a vă
												înregistra pe Platforma noastră ( pentru a crea un cont), etapă
												necesară în vederea plasării unei comenzi și încheierii, pe
												această cale, în măsura în care noi acceptăm comanda, a unui
												contract. Pentru a plasa o comandă mai avem nevoie și de adresa
												și tipul locului în care Utilizatorul dorește prestarea
												Serviciului. Adresa este necesară pentru ca Agentul de curățenie
												să se poată deplasa pentru a aduce la îndeplinire comanda, iar
												tipul locului este necesar pentru a putea stabili prețul
												serviciilor prestate.
											</li>
											<li>În lipsa acestor date, Zumzi nu poate furniza, în mod rezonabil
												serviciile. Utilizatorii sunt obligați să furnizeze aceste date
												pentru a plasa o comandă. În lipsa acestor date nu poate fi
												creat un cont și nici nu poate fi plasată o comandă, cu
												consecința ca Zumzi să nu poată presta serviciul dorit de către
												Utilizator
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru
											îmbunătățirea calității serviciilor
											<li>Colectăm, de exemplu, în acest scop, datele constând în
												recenzii/evaluări sau informații colectate prin intermediul unor
												sondaje.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru a
											putea răspunde la întrebări și solicitări, deci pentru a vă oferi
											asistență cu privire la orice problemă pe care o aveți;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">În scop de
											marketing, însă doar în situația în care avem consimțământul
											dumneavoastră prealabil;
											<li>Zumzi oferă Utilizatorilor posibilitatea de a fi informați cu
												privire la diverse noutăți sau promoții. Această informare se
												face prin transmiterea de mesaje pe telefonul mobil sau prin
												email. Această informare se face exclusiv pe baza
												consimțământului dumneavoastră.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru a
											diagnostica sau a remedia probleme tehnice;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru
											prevenirea și combaterea utilizării frauduloase a Serviciilor;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru a ne
											conforma legislației;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Pentru
											constatarea sau revendicarea unui drept în instanță.
										</ul>
									</li>
								</ol>
							</li>
							<li>Temeiul legal al prelucrării datelor
								<ol>
									<li>
										<ul className="policy-ul">Prelucrarea
											este necesară pentru încheierea sau executarea unui contract;
											<li>De exemplu, numele, mail-ul și numărul de telefon sunt necesare
												pentru a vă înregistra pe Platforma noastră ( pentru a crea un
												cont), etapă necesară în vederea plasării unei comenzi și
												încheierii, pe această cale, în măsura în care noi acceptăm
												comanda, a unui contract. Totodată, alături de adresa și tipul
												locului aceste date sunt necesare pentru ca Zumzi să-și poată
												executa, în mod rezonabil, obligația de a presta serviciul
												solicitat de dumneavoastră, astfel cum se regăsește menționat și
												la secțiunea imediat anterioară.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Prelucrarea
											este necesară pentru satisfacerea intereselor legitime ale Zumzi;
											<li>Interesele legitime pe care Zumzi le are în vedere sunt:
												optimizarea serviciilor oferite și îmbunătățirea calității
												acestora, prevenirea, detectarea și combaterea utilizării
												frauduloase a serviciilor.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Prelucrarea
											se face cu consimțămȃntul dumneavoastră.
											<li>Zumzi oferă Utilizatorilor posibilitatea de a fi informați cu
												privire la diverse noutăți sau promoții. Această informare se
												face prin transmiterea de mesaje pe telefonul mobil sau prin
												email. Această informare se face exclusiv pe baza
												consimțământului dumneavoastră, pe care vi-l exprimați prin
												bifarea căsuței „Doresc să primesc cele mai noi oferte”. Vă
												puteți retrage ȋn orice moment acest consimțămȃnt cu consecința
												că Zumzi nu vă va mai transmite niciun fel de informații ȋn
												acest scop. Consimțămȃntul poate fi retras prin transmiterea
												unui mesaj cu această solicitare din partea dumneavoastră pe
												adresa de e-mail <a
													href="mailto:hello@zumzi.com">hello@zumzi.com</a>
											</li>
										</ul>
									</li>
								</ol>
							</li>
							<li>Dezvăluirea/distribuirea datelor
								<p>De exemplu, numele, mail-ul și numărul de telefon sunt necesare pentru a vă
									înregistra pe Platforma noastră ( pentru a crea un cont), etapă necesară în
									vederea plasării unei comenzi și încheierii, pe această cale, în măsura în
									care noi acceptăm comanda, a unui contract. Totodată, alături de adresa și
									tipul locului aceste date sunt necesare pentru ca Zumzi să-și poată
									executa, în mod rezonabil, obligația de a presta serviciul solicitat de
									dumneavoastră, astfel cum se regăsește menționat și la secțiunea imediat
									anterioară.
								</p>
								<p>Zumzi poate transfera datele, prin dezvăluirea sau acordarea unor drepturi
									de acces de la distanță, numai prin intermediul aplicațiilor securizate,
									unor terțe părți, cum ar fi entități afiliate și alți parteneri comerciali
									ai Zumzi, care acționează în calitate de împuterniciţi, procesând date cu
									caracter personal pentru și în numele Zumzi (de exemplu, stocare a datelor
									pe servere tip “cloud”), cu care Zumzi a încheiat acordurile contractuale
									necesare în conformitate cu reglementările UE și naționale.
								</p>
								<p>De asemenea, putem dezvălui datele dumneavoastră către parteneri de afaceri
									ca urmare a unui efort comun de a oferi un produs sau un serviciu. Vom
									transfera sate către terțe părți exclusiv în măsura necesară îndeplinirii
									scopurilor de prelucrare aplicabile pentru care datele dumneavoastră sunt
									colectate și procesate, menționate ȋn prezenta Politică.
								</p>
								<p>Zumzi poate dezvălui Datele pentru a respecta prevederile legale sau ca
									răspuns la o cerere din partea unei instanțe sau a unei alte autorități
									publice. Zumzi va putea, de asemenea, să divulge informațiile, dacă
									consideră, cu bună-credință, că este necesar pentru a preveni sau împiedica
									savârşirea unor fraude sau limitarea consecinţelor acestora, pentru a
									proteja Zumzi și angajații săi sau pentru a respecta Termenii și condițiile
									de utilizare.
								</p>
							</li>
							<li>Perioada stocării datelor
								<p>Zumzi păstrează datele dumneavostră doar pe perioada necesară ȋndeplinirii
									scopurilor pentru care au fost colectate, astfel cum acestea au fost
									detaliate mai sus.
								</p>
								<p>Datele cu caracter personal colectate și utilizate pentru crearea contului
									dumneavoastră vor fi șterse imediat, in cazul în care vă hotărȃți să vă
									ștergeți contul.
								</p>
								<p>Puteți oricȃnd solicita ștergerea contului prin trimiterea unui e-mail la
									adresa <a href="mailto:hello@zumzi.com">hello@zumzi.com</a> sau prin
									intermediul butonului de șterge cont din pagina de setări siguranță.
								</p>
								<p>Datele cu caracter personal colectate și utilizate pentru prestarea
									Serviciilor de către Zumzi vor fi stocate pentru o perioadă de 5 ani de la
									încetarea relațiilor contractuale sau orice altă perioadă mai lungă impusă
									de lege, regulamente sau norme aplicabile privind obligaţii de păstrare a
									documentelor contabile sau solicitări ale autorităţilor publice.
								</p>
								<p>Pentru a vă asigura că datele nu sunt păstrate mai mult decât este necesar,
									Zumzi va revizui periodic datele și, dacă este cazul, le va șterge.
								</p>
							</li>
							<li>Protejarea datelor dumneavoastră
								<p>Am luat măsuri tehnice și organizatorice pentru a asigura un nivel de
									securitate corespunzător riscurilor pe care le comportă prelucrarea datelor,
									în special prin utilizarea necorespunzătoare, distrugerea accidentală,
									accesarea ilegală sau neautorizată, pierderea, alterarea, divulgarea,
									manipularea intenționată sau accidentală, accesul terților, ștergerea și
									modificarea. În acest scop, am dezvoltat și implementat politici de
									securitate a datelor și alte practici de confidențialitate. În plus,
									procedurile noastre de securitate sunt revizuite în permanență pe baza
									noilor evoluții tehnologice.
								</p>
								<p>Veți fi notificat cu privire la o încălcare a securității datelor, într-o
									perioadă rezonabilă de timp după descoperirea unei astfel de încălcări, cu
									excepția cazului în care un organism public abilitat determină că
									notificarea ar împiedica o cercetare penală sau ar aduce prejudicii
									securității naționale. În acest caz, notificarea va fi amânată, conform
									instrucțiunilor unui astfel de organism. Vom răspunde cu promptitudine la
									întrebările dumneavoastră referitoare la o astfel de încălcare a securității
									datelor.
								</p>
							</li>
							<li>Drepturile dumneavoastră
								<ol>
									<li>
										<ul className="policy-ul">Dreptul de
											retragere a consimțământului;
											<li>În situația în care prelucrarea se bazează pe consimțământ, vă
												puteți retrage consimțământul în orice moment prin transmiterea
												unui mesaj cu această solicitare din partea dumneavoastră pe
												adresa de e-mail <a
													href="mailto:hello@zumzi.com">hello@zumzi.com.</a>
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul de
											acces;
											<li>Aveți dreptul de a obține din partea noastră o confirmare că se
												prelucrează sau nu date cu caracter personal care vă privesc și,
												în caz afirmativ, aveți dreptul de acces la datele respective.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul la
											rectificare;
											<li>Aveți dreptul de a obține de la noi, rectificarea datelor cu
												caracter personal inexacte care vă privesc. Ținându-se seama de
												scopurile în care au fost prelucrate datele, aveți dreptul de a
												obține completarea datelor cu caracter personal care sunt
												incomplete, inclusiv prin furnizarea unei declarații
												suplimentare.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul la
											ștergerea datelor („dreptul de a fi uitat”);
											<li>În situațiile în care datele nu mai sunt necesare pentru
												îndeplinirea scopurilor, s-a retras consimțământul și nu există
												un alt temei juridic pentru prelucrare, vă opuneți prelucrării
												și nu există motive legitime care să prevaleze în ceea ce
												privește prelucrarea sau datele cu caracter personal au fost
												prelucrate ilegal, aveți dreptul de a obține ștergerea datelor
												care vă privesc.
											</li>
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul la
											restricționarea prelucrării;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul de a
											transmite datele pe care le avem despre tine către alt operator;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul de a
											nu face obiectul unei decizii bazate exclusiv pe prelucrarea
											automată, inclusiv crearea de profiluri;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul de a
											depune o plângere în fața unei autorități de supraveghere;
										</ul>
									</li>
									<li>
										<ul className="policy-ul">Dreptul de a
											te adresa justiției;
										</ul>

									</li>
								</ol>
								<p>Exercitarea drepturilor dumneavoastră se poate face prin transmiterea unei
									cereri scrise, semnate și datate la adresa de e-mail <a
										href="mailto:hello@zumzi.com">hello@zumzi.com.</a>
								</p>
								<p>Drepturile enumerate mai sus nu sunt absolute. Există excepții, de aceea
									fiecare cerere primite va fi analizată astfel încât să decidem dacă este
									întemeiată sau nu. În măsura în care cererea este întemeiată, vom proceda
									conform solicitării dumneavoastră. Dacă cererea este neîntemeiată, o vom
									respinge, motivat.
								</p>
								<p>Dacă nu se va reuși identificarea dumneavoastră și nu ne oferiți informații
									suplimentare ȋn acest sens nu suntem obligați să dăm curs solicitării.
								</p>
							</li>
							<li>Schimbarea Politicii de confidențialitate
								<p>Putem schimba această Politică de confidențialitate în orice moment. Toate
									actualizările și modificările prezentei Politici sunt valabile imediat după
									notificare, pe care o vom realiza prin afișare pe site și/sau notificare pe
									e-mail.
								</p>
							</li>
							<li>Ȋntrebări și solicitări
								<p>Orice întrebare sau solicitare pe care o aveți cu privire la Politica de
									confidențialitate o puteți adresa pe adresa de email <a
										href="mailto:hello@zumzi.com">hello@zumzi.com</a>
								</p>
							</li>
						</ol>
					</Col>
				</Row>
			</Container>

		</GuestTemplate>
	)
}
export default PrivacyPage