import React from 'react';


export const TYPE_CLEAN_CUSTOM = 3,
	TYPE_CLEAN_B2C = 4,
	TYPE_CLEAN_B2B = 5;

export const PRODUCT_CODE_CUSTOM = 'custom_service';
export const PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION = 'home_management_subscription';
export const PRODUCT_ID_MANAGEMENT_SUBSCRIPTION = 84;
export const PRODUCT_ID_ONE_TIME_CLEANING = 77;
export const PRODUCT_ID_MASSAGE = 78;
export const PRODUCT_ID_CLEAN_COUCH = 72;
export const PRODUCT_ID_CARPET_CLEANING = 83;
export const PRODUCT_ID_PLUMBER = 79;
export const PRODUCT_ID_ELECTRICIAN = 80;

export const PRODUCT_CODE_TEXTILE = 'textile';

export const OPTION_GROUP_CUSTOM = 'clean_custom_order';

export const OPTION_CODE_IRONY = 'clean_service_irony';
export const OPTION_GROUP_ANIMAL = 'animal_1';

export const DESC = "desc",
	IMG = "img",
	CTA = "cta",
	URL = "url",
	PRICE = "price";

export const LOCKBOX_ID = 71

export const TOP_ONE_TIME_PRODUCT_CODES = ['massage', 'clean_couch', 'plumber']