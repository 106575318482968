import {batch} from 'react-redux'

import {
	GET_SHARE_VOUCHER_SUCCESS,
	GET_VOUCHERS_SUCCESS,
	HIDE_SPINNER,
	SHOW_ALERT_MODAL,
	SHOW_SPINNER
} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getShareVoucherActions = userId => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/users/${userId}/voucher_referral/current`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_SHARE_VOUCHER_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const getVouchersActions = userId => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/users/${userId}/voucher_targets?exists[usedAt]=false`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_VOUCHERS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const addVoucherActions = (userId, code) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const respData = await axiosRequest.post(`/users/${userId}/voucher_targets`, {'voucher': {code}})
		if (!respData) {
			batch(() => {
				dispatch({type: HIDE_SPINNER})
				dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Codul introdus este incorect'}})
			})
			return
		}
		batch(() => {
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: "Voucher adaugat"}})
			dispatch(getVouchersActions(userId))
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updateVoucherActions = (userId, voucherTargetId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/voucher_targets/${voucherTargetId}/current`, {})
		batch(() => {
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: "Voucher curent actualizat"}})
			dispatch(getVouchersActions(userId))
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}