import React from "react";
import {trackEvent} from "../../../utils/analytics";
import zumzi_android from "../../../assets/images/zumzi_android.png";
import zumzi_ios from "../../../assets/images/zumzi_ios.png";
import {ANDROID_URL, IOS_URL} from "../../../utils/constants/app";
import "../../../assets/css/guest/downloadLink.scss"

export const DownloadLinks = () => {
	return (
		<div className="text-center">
			<a href={ANDROID_URL} onClick={() => trackEvent('downloadAppButtonAndroid')}>
				<img src={zumzi_android} alt="zumzi android" className="download-app-image download-app-image-first"/>
			</a>
			<a href={IOS_URL} onClick={() => trackEvent('downloadAppButtonIOS')}>
				<img src={zumzi_ios} alt="zumzi ios" className="download-app-image"/>
			</a>
		</div>
	)
}
export default DownloadLinks