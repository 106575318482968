import {SET_PRODUCT_FORM_DETAILS} from "../constants";
import {productFormDetailsInitialState} from "../../utils/model/productFormDetails";
import {registerActions} from "./register.actions";
import {loadFromLocalStorage} from "../../utils/storage";
import {setCustomComplexActions} from "./selectComplex.actions";
import {createProductFormAction} from "./productForm.actions";

export const increaseProductFormDetailsStepAction = (productFormDetails, stepSize = 1) => async dispatch => {
	dispatch({
		type: SET_PRODUCT_FORM_DETAILS,
		payload: {...productFormDetails, currentStep: productFormDetails.currentStep + stepSize}
	})
}

export const setProductFormSpecificDetailsAction = (productFormDetails, description, productId, clientValue, files, currentStep = 2) => async dispatch => {
	dispatch({
		type: SET_PRODUCT_FORM_DETAILS, payload: {
			...productFormDetails, currentStep, description, clientValue, files, product: {
				id: productId
			}
		}
	})
}

export const setProductFormPublicAddressAction = (productFormDetails, publicAddress, currentStep = 3) => async dispatch => {
	dispatch({
		type: SET_PRODUCT_FORM_DETAILS, payload: {
			...productFormDetails, currentStep, publicAddress
		}
	})
}

export const setProductFormPublicUserAction = (productFormDetails, name, email, phone, password, toSubmit = true) => async dispatch => {
	const payload = {
		...productFormDetails, user: {name, email, phone, password}
	}
	await dispatch({
		type: SET_PRODUCT_FORM_DETAILS, payload
	})
	if (toSubmit) {
		dispatch(submitFromProductFormDetails(payload))
	}
}

export const decreaseProductFormDetailsStepAction = (productFormDetails, stepSize = 1) => async dispatch => {
	dispatch({
		type: SET_PRODUCT_FORM_DETAILS,
		payload: {...productFormDetails, currentStep: productFormDetails.currentStep - stepSize}
	})
}

export const goToProductFormStepAction = (productFormDetails, step) => async dispatch => {
	dispatch({type: SET_PRODUCT_FORM_DETAILS, payload: {...productFormDetails, currentStep: step}})
}

export const setProductFormDetailsPropertyAction = (productFormDetails, property) => async dispatch => {
	dispatch({type: SET_PRODUCT_FORM_DETAILS, payload: {...productFormDetails, property}})
}

export const resetProductFormDetails = () => async dispatch => {
	dispatch({type: SET_PRODUCT_FORM_DETAILS, payload: productFormDetailsInitialState})
}

export const submitFromProductFormDetails = (productFormDetails) => async dispatch => {
	await dispatch(registerActions(productFormDetails.user.name, productFormDetails.user.email, productFormDetails.user.phone, productFormDetails.user.password, true))
	const user = loadFromLocalStorage('user')

	if (user?.id) {
		await dispatch(setCustomComplexActions(productFormDetails.publicAddress, user?.id, true))
		await dispatch(createProductFormAction(productFormDetails.description, productFormDetails.product.id, user.id, productFormDetails.clientValue, productFormDetails.files, true))
	}
}
