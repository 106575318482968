import {batch} from 'react-redux'

import {GET_PUBLIC_ADDRESSES_SUCCESS, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getPublicAddressesAction = (userId, toHideSpinner = true) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/users/${userId}/public_addresses`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PUBLIC_ADDRESSES_SUCCESS, payload})
			if (toHideSpinner) {
				dispatch({type: HIDE_SPINNER})
			}
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}