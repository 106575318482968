import {Route, Switch} from "react-router-dom";
import React, {Suspense} from "react";
import {PageLoader} from "../components/common/PageLoader";

import {
	ROUTE_3DS_PAYMENT,
	ROUTE_ACCOUNT_PRODUCT_FORM,
	ROUTE_ADD_ADDRESS,
	ROUTE_BOOKING,
	ROUTE_BOOKING_FEEDBACK,
	ROUTE_CONTACT,
	ROUTE_COOKIES,
	ROUTE_DASHBOARD,
	ROUTE_FAQ,
	ROUTE_HOME, ROUTE_HOME_SERVICES,
	ROUTE_LOGIN,
	ROUTE_LOGOUT,
	ROUTE_MY_BOOKING,
	ROUTE_MY_NEIGHBOURHOOD,
	ROUTE_MY_ORDERS,
	ROUTE_MY_PRODUCT_FORM,
	ROUTE_MY_SUBSCRIPTION,
	ROUTE_ORDER,
	ROUTE_ORDER_FAST_PRODUCT_FORM,
	ROUTE_ORDER_FORM, ROUTE_ORDER_FORM_REQUEST_STEP,
	ROUTE_ORDER_STEP,
	ROUTE_PARTNER,
	ROUTE_PAYMENT_METHODS,
	ROUTE_PAYMENTS,
	ROUTE_PERSONAL_DATA,
	ROUTE_PRIVACY,
	ROUTE_RECOVER_PASSWORD,
	ROUTE_REGISTER,
	ROUTE_RESET_PASSWORD,
	ROUTE_SERVICE,
	ROUTE_SERVICES,
	ROUTE_TERMS_AND_CONDITIONS,
	ROUTE_VOUCHERS
} from "./constants";
import {loadFromLocalStorage} from "../utils/storage";
import {useDispatch} from "react-redux";
import {reAuthAction} from "../redux/actions";

import RegisterPage from "../components/authentication/RegisterPage";
import LoginPage from "../components/authentication/LoginPage";
import ResetPassword from "../components/authentication/ResetPassword";
import RecoverPassword from "../components/authentication/RecoverPassword";
import Logout from "../components/authentication/Logout";
import UserCardsPage from "../components/account/userCard/UserCardsPage";
import VouchersPage from "../components/account/voucher/VouchersPage";
import PersonalDataPage from "../components/account/personalData/PersonalDataPage";
import PaymentsPage from "../components/account/payments/PaymentsPage";


import MyOrdersPage from "../components/account/myOrders/MyOrdersPage";
import OrderServicePage from "../components/account/order/OrderServicePage";
import ThreeDSSecurePaymentPage from "../components/account/order/ThreeDSSecurePaymentPage";
import OrderFormPage from "../components/account/orderForm/OrderFormPage";
import ProductFormPage from "../components/account/productForm/ProductFormPage";
import ServicesPage from "../components/guest/services/ServicesPage";
import FAQPage from "../components/guest/FAQPage";
import TermsAndConditionsPage from "../components/guest/legal/TermsAndConditionsPage";
import PrivacyPage from "../components/guest/legal/PrivacyPage";
import CookiesPage from "../components/guest/legal/CookiesPage";
import PartnerPage from "../components/guest/PartnerPage";
import OrderStepPage from "../components/account/order/step/OrderStepPage";
import SubscriptionBookingPage from "../components/account/myOrders/SubscriptionBookingPage";
import BookingPage from "../components/account/myOrders/BookingPage";
import PageNotFound from "../components/common/PageNotFound";
import AddNewAddressPage from "../components/account/neighbourhood/AddNewAddressPage";
import HomePage from "../components/guest/home/HomePage";
import ProductFormStepPage from "../components/guest/productForm/ProductFormStepPage";
import ScrollToTop from "../components/common/ScrollToTop";
import OrderRequestStepPage from "../components/account/productFormRequest/OrderRequestStepPage";
import ServiceDescriptionPage from "../components/guest/services/ServiceDescriptionPage";
import ContactPage from "../components/guest/ContactPage";
import HomeServicesPage from "../components/guest/homeServices/HomeServicesPage";

const RoutesComponent = () => {
	const dispatch = useDispatch()

	const user = loadFromLocalStorage('user');
	if (user?.token) {
		dispatch(reAuthAction(user))
	}

	return (
		<Suspense fallback={<PageLoader/>}>
			<ScrollToTop/>
			<Switch>
				{/* guest */}
				<Route exact path={ROUTE_HOME} component={HomePage}/>
				<Route exact path={ROUTE_HOME_SERVICES} component={HomeServicesPage}/>

				<Route exact path={ROUTE_SERVICES} component={ServicesPage}/>
				<Route exact path={ROUTE_SERVICE} component={ServiceDescriptionPage}/>
				<Route exact path={ROUTE_FAQ} component={FAQPage}/>
				<Route exact path={ROUTE_CONTACT} component={ContactPage}/>
				<Route exact path={ROUTE_PARTNER} component={PartnerPage}/>
				<Route exact path={ROUTE_ORDER_FAST_PRODUCT_FORM} component={ProductFormStepPage}/>

				<Route exact path={ROUTE_TERMS_AND_CONDITIONS} component={TermsAndConditionsPage}/>
				<Route exact path={ROUTE_PRIVACY} component={PrivacyPage}/>
				<Route exact path={ROUTE_COOKIES} component={CookiesPage}/>


				{/* authentication*/}
				<Route exact path={ROUTE_LOGOUT} component={Logout}/>
				<Route exact path={ROUTE_LOGIN} component={LoginPage}/>
				<Route exact path={ROUTE_REGISTER} component={RegisterPage}/>
				<Route exact path={ROUTE_RESET_PASSWORD} component={ResetPassword}/>
				<Route exact path={ROUTE_RECOVER_PASSWORD} component={RecoverPassword}/>

				{/* account*/}
				<Route exact path={ROUTE_DASHBOARD} component={OrderServicePage}/>
				<Route exact path={ROUTE_MY_NEIGHBOURHOOD} component={OrderServicePage}/>

				<Route exact path={ROUTE_PAYMENT_METHODS} component={UserCardsPage}/>
				<Route exact path={ROUTE_VOUCHERS} component={VouchersPage}/>
				<Route exact path={ROUTE_PERSONAL_DATA} component={PersonalDataPage}/>
				<Route exact path={ROUTE_PAYMENTS} component={PaymentsPage}/>
				<Route exact path={ROUTE_ADD_ADDRESS} component={AddNewAddressPage}/>


				<Route exact path={ROUTE_MY_ORDERS} component={MyOrdersPage}/>
				<Route exact path={ROUTE_MY_SUBSCRIPTION} component={SubscriptionBookingPage}/>
				<Route exact path={ROUTE_MY_BOOKING} component={BookingPage}/>
				<Route exact path={ROUTE_MY_PRODUCT_FORM} component={ProductFormPage}/>

				<Route exact path={ROUTE_ACCOUNT_PRODUCT_FORM} component={ProductFormPage}/>

				<Route exact path={ROUTE_ORDER} component={OrderServicePage}/>
				<Route exact path={ROUTE_ORDER_STEP} component={OrderStepPage}/>
				<Route exact path={ROUTE_ORDER_FORM_REQUEST_STEP} component={OrderRequestStepPage}/>

				<Route exact path={ROUTE_3DS_PAYMENT} component={ThreeDSSecurePaymentPage}/>
				<Route exact path={ROUTE_ORDER_FORM} component={OrderFormPage}/>

				<Route exact path='*' component={PageNotFound}/>
			</Switch>
		</Suspense>
	);
};

export default RoutesComponent;
