import React, {useCallback, useEffect, useState} from "react"

export const CustomInputSlider = ({min = 1, max = 4, step = 1}) => {
	const [value, setValue] = useState(1)

	const [labelStyle, setLabelStyle] = useState({})

	useEffect(() => {
		if (value === 1) {
			setLabelStyle({marginLeft: `0px`, textAlign: 'left'})

		} else if (value === 2) {
			setLabelStyle({marginLeft: `calc(33% - 25px)`, textAlign: 'left'})

		} else if (value === 3) {
			setLabelStyle({marginLeft: `calc(66% - 38px)`, textAlign: 'left'})
		}

		else {
			setLabelStyle({marginLeft: `0px`, textAlign: 'right'})
	}
	}, [value])

	const getPriceByValue = useCallback(() => {
		const dict = {
			1: 700,
			2: 1000,
			3: 1500,
			4: 1800,
		}
		return dict[value]
	}, [value])

	return (
		<div>
			<p className="color-white mb-4 mt-6" style={{fontSize: '24px'}}>
				Cate camere ai?
				</p>
			<input
				type='range'
				onChange={(e) => {
					setValue(parseInt(e?.target?.value))
				}}
				min={min}
				max={max}
				step={step}
				value={value}
				className="custom-slider"
			/>
			<div className="mt-2 mb-6 " style={labelStyle}>
				<span className="color-white ">
					{
						value === 1
						? "o camera"
						: value + " camere"
					}
				</span>
			</div>

			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1.5rem'}}>
				<span className="color-white" style={{fontSize: '24px'}}>
					Incasari / luna
				</span>
				<span className="color-white font-weight-bold" style={{fontSize: '24px'}}>
					{getPriceByValue(value)} EUR
				</span>
			</div>
		</div>

	)
}
