import {Container, Nav, Navbar} from "react-bootstrap";
import zumzi_logo from "../../../assets/images/logo_zuzmi_alb_text.png";
import {colors} from "../../../utils/style/style";
import React from "react";
import {useWindowResize} from "../../../utils/hooks/useWindowResize";
import ButtonNav from "../../guest/nav/ButtonNav";

export const AccountNavbar = () => {
	const {width} = useWindowResize()

	return (
		<Navbar className="navbar-account " expand="md" style={{
			backgroundColor: width > 991 ? '#1953BC' : 'inherit',
			borderBottom: width > 991 ? `1px solid ${colors.white}` : 'none',
			zIndex: 1,
		}}>
			<Container fluid className="desktop-navbar">
				<Navbar.Brand href="/">
					<img style={{height: '67px', marginLeft: '2rem'}} src={zumzi_logo} alt="zumzi logo"/>
				</Navbar.Brand>


				<Nav style={{marginRight: '1rem'}}>
					<ButtonNav
						style={{
							marginLeft: "1rem",
						}}
						hideSecond={true}
					/>
				</Nav>
			</Container>
		</Navbar>
	)
}

export default AccountNavbar