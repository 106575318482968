import React, {useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {
    calendarActions, getProductOptionsByParent,
    resetSelectedCalendarAction, setAllProductOptions,
    setSelectedProductOptions,
    showModalPopup
} from "../../../../redux/actions";
import {getTimeFromDuration} from "../../../../utils/datetime";
import {GET_CALENDAR_SUCCESS, GET_PRODUCT_OPTION, SET_ORDER_DETAILS_SUCCESS} from "../../../../redux";
import {colors} from "../../../../utils/style/style";
import InputField from "../../../common/input/InputField";
import {ServiceDescription} from "../../../guest/services/ServiceDescription";
import {Form} from "react-bootstrap";
import ButtonVertical from "../../../common/buttons/ButtonVertical";
import {calculatePriceByOptions, getProductName} from "../../../../utils/model/product";
import SliderComponent from "../../../common/SliderComponent";
import {useWindowResize} from "../../../../utils/hooks/useWindowResize";

let productPrice = ''
let productOldPrice = ''
export const Step1 = (props) => {
    const [serviceNotes, setServiceNotes] = useState('')

    const dispatch = useDispatch()

    const product = useSelector(state => state.product)
    const userNeighbourhood = useSelector(state => state.userNeighbourhood)

    const productOptions = useSelector(state => state.productOptions)
    const selectedProductOptions = useSelector(state => state.selectedProductOptions)
    const allProductOptions = useSelector(state => state.allProductOptions)

    const orderDetails = useSelector(state => state.orderDetails)
    const selectedCalendar = useSelector(state => state.selectedCalendar)
    const {width} = useWindowResize()

    useEffect(() => {
        if (productOptions.length) {
            dispatch(setAllProductOptions([...allProductOptions, productOptions]))
        }
    }, [productOptions])

    useEffect(() => {
        if (selectedProductOptions?.length) {
            if (selectedProductOptions[selectedProductOptions.length - 1].publicPrice) {
                productPrice = parseInt(selectedProductOptions[selectedProductOptions.length - 1].publicPrice?.value)
                productOldPrice = parseInt(selectedProductOptions[selectedProductOptions.length - 1].publicPrice?.oldValue)
            }
        }
    }, [selectedProductOptions])

    useEffect(() => {
        if (product.productOptions?.length === 1) {
            dispatch(setSelectedProductOptions(product.productOptions))
        }
    }, [product])

    useEffect(() => {
        setServiceNotes(orderDetails.serviceNotes)
    }, [orderDetails?.serviceNotes])

    const onChangeProductOption = (productOption, level) => {
        if (selectedProductOptions.length >= level + 1 && productOption.id === selectedProductOptions[level].id) {
            return
        }
        const newOptions = [...selectedProductOptions]

        dispatch(setSelectedProductOptions([...newOptions.slice(0, level), productOption]))
        dispatch(setAllProductOptions([...allProductOptions.slice(0, level + 1)]))
        dispatch(getProductOptionsByParent(product?.id, productOption?.id))
    }

    const onShowServiceDetails = (description = null) => {
        dispatch(showModalPopup(getProductName(product), <ServiceDescription hideButton={true} isDark={true} description={description}/>, null, 'xl'))
    }

    const ExtraDetails = () => {
        if (!selectedProductOptions.length) {
            return <></>
        }
        const productOptionTime = selectedProductOptions[selectedProductOptions.length - 1]?.productOptionTime

        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
                {
                    product.subscription
                        ? <p style={{marginBottom: '0.25rem'}}>
                            <span style={{color: colors.brandSecondary}}>{productOptionTime?.sessionsPerMonth}</span> sesiuni / luna
                        </p>
                        : null
                }
                <p style={{marginBottom: '0.25rem'}}>
                    <span style={{color: colors.brandSecondary}}>
                        {getTimeFromDuration(productOptionTime?.durations[0])}
                    </span>
                    {" "} ore / sesiune
                </p>
                {
                    productOptionTime?.numberOfPersons
                    ? <p className="mb-0">
                        <span style={{color: colors.brandSecondary}}>
                            {productOptionTime?.numberOfPersons}
                        </span>
                          {" "}agenti
                      </p>
                  : <></>
                }

            </div>
            <div style={{textAlign: 'right'}}>
                {
                    !!productOldPrice &&
                    <span className="service-old-price">{productOldPrice} RON</span>
                }
                {
                    productPrice
                        ?
                        <p className="service-price">{productPrice} RON</p>
                        : null
                }
            </div>
        </div>
    }

    const onSubmitNextStep = () => {
        dispatch({ type: GET_CALENDAR_SUCCESS, payload: null })

        const stepSize = userNeighbourhood.userPublicAddress?.address?.property ? 2 : 1
        batch(() => {
            dispatch(calendarActions(product.id, selectedProductOptions.map((option) => {return option.id})))
            dispatch({type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, serviceNotes, currentStep: orderDetails.currentStep + stepSize}})
            dispatch(resetSelectedCalendarAction(selectedCalendar))
        })
    }

    return (
        <div>
            {
                product.productOptions?.length === 1
                    ?
                    <div className="service-description-container">
                        <h4 className="text-center color-brand mb-4">
                            {getProductName(product)}
                        </h4>

                        <ExtraDetails/>
                    </div>
                    : null
            }

            {/* duration header */}
            {
                product.productOptions?.length > 1
                    ? <div>
                            <h4 className="color-brand mb-4">
                                {getProductName(product)}
                            </h4>
                          <h6 className="mb-4 font-weight-bold">
                              Alege optiune
                          </h6>

                      {
                          allProductOptions.map((options, level) => {
                              return <div key={level}>
                                  {
                                      width > 550
                                    ? (options.length > 3
                                          ? <SliderComponent
                                          autoplay={false}
                                          slidesToShow={3.01}
                                          items={options}
                                          hideNavigation={true}
                                          centerMode={false}
                                          ItemComponent={({item, index}) => {
                                              const title = item.title ?? (item?.productOptionTime?.durations ? (" " + getTimeFromDuration(item.productOptionTime.durations[0]) + "H") : "")
                                              return <ButtonVertical
                                                key={index}
                                                active={selectedProductOptions[level]?.id === item.id}
                                                onClick={() => { onChangeProductOption(item, level) }}
                                                title={title}
                                              />
                                          }}
                                        />
                                        : <div style={{display: 'flex'}}>
                                                {options.map((item, index) => {
                                                    const title = item.title ?? (item?.productOptionTime?.durations ? (" " + getTimeFromDuration(item.productOptionTime.durations[0]) + "H") : "")

                                                    return <ButtonVertical
                                                      key={index}
                                                      active={selectedProductOptions[level]?.id === item.id}
                                                      onClick={() => { onChangeProductOption(item, level) }}
                                                      title={title}
                                                    />
                                                })}
                                          </div>
                                    ) : (options.length > 2
                                          ? <SliderComponent
                                            autoplay={false}
                                            slidesToShow={2.01}
                                            items={options}
                                            hideNavigation={true}
                                            centerMode={false}
                                            ItemComponent={({item, index}) => {
                                                const title = item.title ?? (item?.productOptionTime?.durations ? (" " + getTimeFromDuration(item.productOptionTime.durations[0]) + "H") : "")

                                                return <ButtonVertical
                                                  key={index}
                                                  active={selectedProductOptions[level]?.id === item.id}
                                                  onClick={() => { onChangeProductOption(item, level) }}
                                                  title={title}
                                                />
                                            }}
                                          />
                                          : <div style={{display: 'flex'}}>
                                                {
                                                    options.map((item, index) => {
                                                        const title = item.title ?? (item?.productOptionTime?.durations ? (" " + getTimeFromDuration(item.productOptionTime.durations[0]) + "H") : "")

                                                        return <ButtonVertical
                                                          key={index}
                                                          active={selectedProductOptions[level]?.id === item.id}
                                                          onClick={() => { onChangeProductOption(item, level) }}
                                                          title={title}
                                                        />
                                                    })
                                                }
                                          </div>
                                        )
                                  }

                              </div>
                          })
                      }
                      {
                          selectedProductOptions.length && selectedProductOptions[selectedProductOptions.length - 1]?.productOptionTime
                            ? <>
                                <Form.Label>Detalii serviciu</Form.Label>

                                <div className="service-description-container">
                                    <ExtraDetails/>
                                </div>
                            </>
                            : <></>
                      }


                        </div>
                    : null
            }
            {
                selectedProductOptions[selectedProductOptions.length - 1]?.productOptionTime
                ? <div className="mb-4" style={{textAlign: 'right'}}>
                    <span className="see-details-text" onClick={() => onShowServiceDetails(selectedProductOptions[selectedProductOptions.length - 1].description)}>
                        vezi mai mult
                    </span>
                  </div>
                : <></>
            }

            <div>

                <div>
                    <InputField
                        label="Cum ai vrea sa te mai ajutam?"
                        type="text"
                        placeholder="Ex: schimbare ghiveci cu flori, curatare frigider, curatare terasa"
                        value={serviceNotes}
                        setValue={setServiceNotes}
                        isTextarea={true}
                        maxLength={700}
                        rows={3}
                    />
                </div>
            </div>

        <button
            className="button-gradient-primary"
            onClick={onSubmitNextStep}
            disabled={!selectedProductOptions.length || !selectedProductOptions[selectedProductOptions.length - 1].productOptionTime}
            style={{opacity: (!selectedProductOptions.length || !selectedProductOptions[selectedProductOptions.length - 1].productOptionTime) ? 0.5 : 1}}
        >
            Continua
        </button>
    </div>
    )
}
export default Step1