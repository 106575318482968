import React, {useEffect} from 'react'

import {translate} from "../../../utils/i18n";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {getProductActions} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import GuestTemplate from "../GuestTemplate";

import {ServiceDescription} from "./ServiceDescription";
import {getProductName} from "../../../utils/model/product";

export const ServiceDescriptionPage = (props) => {
	const productId = getItemFromUrl(props.location.search, 'productId')

	const product = useSelector(state => state.product)

	const dispatch = useDispatch()

	useEffect(() => {
		if (productId) {
			dispatch(getProductActions(productId))
		}
	}, [productId])

	return (
		<GuestTemplate label="service" title={getProductName(product)}
					   subtitle="Descopera detaliile serviciului nostru"
					   moreDetails={false}
					   image={null}
		>
			<ServiceDescription/>
		</GuestTemplate>
	)
}
export default ServiceDescriptionPage