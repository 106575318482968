import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import SideBar from "./nav/SideBar";
import {NavBar} from "./nav/NavBar";
import {useWindowResize} from "../../utils/hooks/useWindowResize";
import Footer from "./Footer";

import "../../assets/css/guest/headerTitle.scss"
import "../../assets/css/guest/guest.scss"

import {translate} from "../../utils/i18n";
import CookieBanner from "../common/CookieBanner";
import {Col, Container, Row} from "react-bootstrap";
import zumzi_casa from "../../assets/images/new/casa_zumzi.png";
import {Link} from "react-router-dom";
import {ROUTE_ORDER} from "../../routes/constants";
import {PRODUCT_ID_MANAGEMENT_SUBSCRIPTION} from "../../utils/constants/product";
import {Icon} from "@iconify/react/dist/iconify";
import {getPathWithParams} from "../../utils/interface/queryHandler";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import {isDevelopmentEnv} from "../../utils/app";

// todo seo for each page
export const GuestTemplate = ({label, children, title, subtitle, image = zumzi_casa, cta, moreDetails, showHeroBanner = true}) => {
	const [isActiveSidebarMenu, setIsActiveSidebarMenu] = useState(false)

	const {width, height} = useWindowResize()


	const toggleMenu = () => {
		setIsActiveSidebarMenu(!isActiveSidebarMenu)
	}

	useEffect(() => {
		if (isActiveSidebarMenu && width > 991) {
			toggleMenu()
		}
	}, [width])

	return (
		<>
			<Helmet>
				<title>{typeof title === 'object' ? "Grija pentru casa ta" : title}</title>
				<meta name="title" content={typeof title === 'object' ? "Grija pentru casa ta" : title}/>
				<meta name="description" content={`${translate('meta.title.' + label)}`}/>
				<meta name="keywords" content={`${translate('meta.keywords.' + label)}`}/>
				<meta property="og:url" content={`${'https://www.zumzi.com/' + window.location.pathname}`}/>
			</Helmet>

			<CookieBanner/>


			<div className="guest-background">
				<NavBar
					toggleMenu={toggleMenu}
				/>

				{
					isActiveSidebarMenu
					&& <div
						className="sidebar-wrapper"
					>
						<SideBar
							toggleMenu={toggleMenu}
						/>
					</div>
				}
				{
					showHeroBanner
					? <Container style={{paddingTop: '6rem'}}>
							<Row className="mb-5">
								<Col lg={6} className="guest-center-container offset-lg-3 text-center mt-4"
										 style={{margin: 'auto'}}>
									{
										image
											? <img alt="zumzi cartier" src={image} className="mb-4 mt-4"/>
											: <div style={{height: '4rem'}}/>
									}


									<h1 className="guest-title mt-4 mb-3">
										{title}
									</h1>
									{
										subtitle
											? <h4 className="guest-subtitle mb-5">
												{subtitle}
											</h4>
											: <div style={{height: '6rem'}}/>
									}

									{
										cta
											? <Row className="guest-header-buttons">
												<Col lg={12}>
													<Link to={`${ROUTE_ORDER}?productId=${PRODUCT_ID_MANAGEMENT_SUBSCRIPTION}`}
																className="button-cta-sec p-font-size mb-4">
														Aboneaza-te la Zumzi Plus
													</Link>
												</Col>
											</Row>
											: null
									}
									{
										moreDetails
											? <div className="guest-more-details-container"
														 style={{textAlign: 'center', marginBottom: '2rem'}}>
												<a href={`${getPathWithParams()}#mai-mult`}>
													<p className="color-grey-text mb-1">
														Afla toate detaliile
													</p>
													<Icon icon="akar-icons:arrow-down" width={24} className="color-grey-text"/>
												</a>
											</div>
											: (
												subtitle ?
													<div style={{height: '6rem'}}/>
													: null
											)
									}

								</Col>
							</Row>
						</Container>
					: <></>
				}
			</div>


			<div id="mai-mult">
				{children}
			</div>
			{
				!isDevelopmentEnv()
					? <MessengerCustomerChat
						pageId="269260359492"
						appId="519177942383630"
					/>
					: null
			}
			<Footer/>
		</>

	)
}

export default GuestTemplate
