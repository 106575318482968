import {batch} from 'react-redux'
import {axiosRequest} from '../../api/axiosRequest';

import {GET_ONE_TIME_BOOKINGS_SUCCESS, SHOW_SPINNER} from '../constants'
import {showDefaultErrorAction} from "./alertModal.actions";

export const getOneTimeBookingsActions = () => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/bookings?exists[sessionNumber]=0&sort[bookingDate]=desc`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_ONE_TIME_BOOKINGS_SUCCESS, payload: payload})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}