import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "react-bootstrap";
import {setCustomComplexActions, showDefaultErrorAction} from "../../../redux/actions";
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete';
import {translate} from "../../../utils/i18n";
import {city} from "../../../redux/reducers/city.reducer";
import InputField from "../../common/input/InputField";
import {Icon} from "@iconify/react/dist/iconify";

import "../../../assets/css/components/autocomplete/autocomplete.scss";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {
	increaseProductFormDetailsStepAction,
	setProductFormPublicAddressAction
} from "../../../redux/actions/productFormDetails.actions";
import {axiosRequest} from "../../../api/axiosRequest";
import {HIDE_SPINNER, SHOW_SPINNER} from "../../../redux";
import {getAddressFromPlacesDetails} from "../../../utils/googleMap/placesAutocomplete";

export const NewComplex = ({backCallback, isLocalSave = false, showOnlyAddress = false}) => {
	const [newComplex, setNewComplex] = useState('')
	const [value, setValue] = useState('')
	const [publicAddress, setPublicAddress] = useState({})
	const [placeId, setPlaceId] = useState(null)
	const forcedRedirection = getItemFromUrl(window.location.search, 'forcedRedirection', null)
	const [googleError, setGoogleError] = useState(false)

	const user = useSelector(state => state.login)

	const selectedCity = useSelector(state => state.city)
	const productFormDetails = useSelector(state => state.productFormDetails)

	const dispatch = useDispatch()

	useEffect(() => {
		if (productFormDetails?.publicAddress?.address?.streetAddress) {
			setPublicAddress(productFormDetails?.publicAddress)
		}
	}, [])

	useEffect(() => {
		if (publicAddress?.address?.streetAddress) {
			setValue(publicAddress?.address?.streetAddress + " " + publicAddress?.address?.streetNumber)
		}
	}, [publicAddress])

	useEffect(() => {
		if (value?.length && value !== publicAddress?.address?.streetAddress + " " + publicAddress?.address?.streetNumber) {
			setPublicAddress({})
		}
	}, [value])

	const handleSelect = (address, placeId) => {
		setPlaceId(placeId)
		setValue(address)
	}

	const onChangeAutoComplete = (value) => {
		setValue(value)
		setGoogleError(false)
	}

	const getPlacesDetails = async () => {
		if (!placeId) {
			return {}
		}

		const res = await geocodeByPlaceId(placeId)
		return res[0]
	}

	const submitNewComplex = async () => {
		if (isLocalSave) {
			if (publicAddress?.address) {
				dispatch(increaseProductFormDetailsStepAction(productFormDetails))
				return
			}
		}

		const address = getAddressFromPlacesDetails(await getPlacesDetails())
		if (!address) {
			setGoogleError(true)
			return
		}

		let name = ''
		if (newComplex.length > 0) {
			name = newComplex
		} else {
			name = address.streetAddress + " " + address.streetNumber
		}

		const publicAddressBody = {
			city: {
				id: selectedCity?.id ? parseInt(selectedCity?.id) : -1
			},
			name,
			address
		}
		if (isLocalSave) {
			// validate public address for services in order to continue
			try {
				dispatch({type: SHOW_SPINNER})
				await axiosRequest.put('/public_address/valid', publicAddressBody)
				dispatch(setProductFormPublicAddressAction(productFormDetails, publicAddressBody))
				dispatch({type: HIDE_SPINNER})

			} catch (err) {
				dispatch(showDefaultErrorAction(err))
			}
		} else {
			dispatch(setCustomComplexActions(publicAddressBody, user?.id))
		}

	}

	return (
		<>
			{
				forcedRedirection
					? <p>
						Pentru a vedea ce servicii avem disponibile, avem nevoie de adresa ta
					</p>
					: null
			}
			{
				!showOnlyAddress
					? <>
						<Form.Label>
							Oras: {selectedCity.name}
						</Form.Label>
						<br/>
						<Form.Label className="font-weight-normal">
							Daca locatia ta nu are un nume, completeaza doar adresa
						</Form.Label>
						<InputField
							className="input-small"
							type="text"
							placeholder={translate('global.placeholder.complex')}
							value={newComplex}
							setValue={setNewComplex}

						/>
					</>
					: <Form.Label>
						Introduceti adresa:
					</Form.Label>
			}


			<PlacesAutocomplete
				value={value}
				onChange={onChangeAutoComplete}
				onSelect={handleSelect}
				debounce={300}
				searchOptions={{
					types: ['address'],
					location: new window.google.maps.LatLng(city.latitude, city.longitude),
					radius: 20000
				}}
				shouldFetchSuggestions={value.length > 3}
			>
				{({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
					<div className="mb-4 input-small" style={{position: 'relative'}}>
						<input
							{...getInputProps({
								placeholder: showOnlyAddress ? 'Strada si numar' : 'Introduceti adresa',
								className: 'location-search-input input-text  form-control padding-left-icon',
							})}
						/>
						<div style={{position: 'absolute', top: '5px', right: '10px'}}>
							<Icon icon="codicon:search"/>
						</div>
						<div className="autocomplete-dropdown-container">

							{loading && <div className="loading">Se incarca...</div>}
							{
								suggestions?.length
									?
									<div className="suggestion-item-container">
										{suggestions.map(suggestion => {
											const className = suggestion.active
												? 'suggestion-item-active'
												: 'suggestion-item';
											return (
												<div
													{...getSuggestionItemProps(suggestion, {
														className,
													})}
													key={suggestion.index}
												>
													<div>{suggestion.description}</div>
												</div>
											);
										})}
									</div>
									: null
							}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
			{
				googleError && <p style={{color: 'red', marginTop: '-1rem', alignSelf: 'flex-start'}}>
					Selecteaza strada si numarul
				</p>
			}
			<div className={"d-flex " + (isLocalSave ? "justify-content-center mt-5" : "")}>
				{
					backCallback
						? <button className="button-small" style={{background: 'none', marginRight: '1rem'}}
								  onClick={backCallback}>
							{'<'} Inapoi
						</button>
						: null
				}

				<button className="button-gradient-primary" onClick={submitNewComplex}>
					{isLocalSave ? "Continua" : "Salveaza"}
				</button>
			</div>


		</>
	)
}
export default NewComplex