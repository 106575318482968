import {batch} from 'react-redux'
import {axiosRequest} from '../../api/axiosRequest';

import {GET_BOOKING_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {ROUTE_MY_ORDERS} from "../../routes/constants";
import {showDefaultErrorAction} from "./alertModal.actions";

export const getBookingAction = (bookingId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/bookings/${bookingId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: GET_BOOKING_SUCCESS, payload: payload})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}


export const feedbackActions = (bookingId, rating, answer, redirectPath = ROUTE_MY_ORDERS) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.post(`/bookings/${bookingId}/reviews`, {
			'data': {
				rating,
				answer
			}
		})
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {message: 'Multumim pentru feedback', type: 'success', route: redirectPath}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const cancelSessionActions = (bookingId, redirectPath = ROUTE_MY_ORDERS) => async dispatch => {
	dispatch({type: SHOW_SPINNER})

	try {
		await axiosRequest.put(`/bookings/${bookingId}/cancel`, {
			"cancelType": 1, // hardcodat
			"reason": ""
		})
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {type: 'success', message: 'Sedinta anulata', route: redirectPath}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updateBookingNotesAction = (bookingId, notes) => async dispatch => {
	dispatch({type: SHOW_SPINNER})

	try {
		await axiosRequest.put(`/bookings/${bookingId}`, {
			updateType: 1,
			notes,
		})
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {type: 'success', message: 'Note actualizate'}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updateBookingDateTimeAction = (bookingId, bookingDate, updateType = 1, sameAgent = 0, callbackFunction) => async dispatch => {
	dispatch({type: SHOW_SPINNER})

	try {
		await axiosRequest.put(`/bookings/${bookingId}/datetime`, {
			updateType,
			sameAgent,
			bookingDate,
		})

		await callbackFunction()

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {type: 'success', message: 'Data si ora actualizata'}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}