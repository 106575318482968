import {GET_PRODUCT_FORMS_SUCCESS, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getProductFormsAction = (userId) => async dispatch => {
    dispatch({ type: SHOW_SPINNER })
    try {
        const resp = await axiosRequest.get(`/users/${userId}/product_forms`)
        const payload = resp.data
        dispatch({ type: GET_PRODUCT_FORMS_SUCCESS, payload })
    } catch (err) {
        dispatch(showDefaultErrorAction((err)))
    }
}
