import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getBookingAction,
	getCalendarByBookingAction,
	getSubscriptionBookingAction,
	hideModalPopup,
	resetCalendarAction,
	updateBookingDateTimeAction
} from "../../../redux/actions";
import DateTimeCalendar from "../../common/DateTimeCalendar";
import {hasAgent, isFromSubscription} from "../../../utils/model/booking";
import CheckboxField from "../../common/input/CheckboxField";
import {convertToServerDateTime} from "../../../utils/datetime";
import {Overlay, Tooltip} from "react-bootstrap";
import "../../../assets/css/components/tooltip/tooltip.scss"
import {Icon} from "@iconify/react/dist/iconify";

export const BookingChangeDateTime = ({booking, subscriptionId}) => {
	const [sameAgent, setSameAgent] = useState(true)
	const [withSubscription, setWithSubscription] = useState(false)

	const calendar = useSelector(state => state.calendar)
	const dispatch = useDispatch()
	const [showTooltip, setShowTooltip] = useState(false)
	const tooltipRef = useRef(null)

	useEffect(() => {
		dispatch(resetCalendarAction())
	}, [sameAgent, withSubscription])

	const onShowCalendar = () => {
		dispatch(getCalendarByBookingAction(booking?.id, +sameAgent, +withSubscription))
	}

	const onUpdateBookingDateTime = async (date, time) => {
		const dateTime = convertToServerDateTime(date + " " + time)

		const callbackFunction = async () => {
			if (subscriptionId) {
				await dispatch(getSubscriptionBookingAction(subscriptionId))
			} else {
				await dispatch(getBookingAction(booking?.id))
			}
			await dispatch(hideModalPopup())


		}
		await dispatch(updateBookingDateTimeAction(booking?.id, dateTime, withSubscription ? 2 : 1, +sameAgent, callbackFunction))
	}

	const onClickTooltip = (e) => {
		e.preventDefault()
		setShowTooltip(true)
		setTimeout(function () {
			setShowTooltip(false)
		}, 3000)
	}

	return (
		<div className="mb-4">
			{
				hasAgent(booking)
					? <CheckboxField
						id="sameAgent"
						value={sameAgent}
						noMargin={true}
						colorType='dark'
						setValue={() => {
							setSameAgent(!sameAgent)
						}}
						extraClassName="text-left"
						label={
							<div>
								<p>Doresc pastrarea aceluiasi partener{" "}
									<span style={{cursor: 'pointer'}} ref={tooltipRef}
										  onClick={(e) => onClickTooltip(e)}>
										<Icon icon="akar-icons:info" width={18}/>
									</span>
								</p>
								<Overlay target={tooltipRef.current} show={showTooltip}
										 placement="bottom">
									{(props) => (
										<Tooltip {...props}>
											In cazul schimbarii parternelui, este nevoie de confirmarea acestuia.
										</Tooltip>
									)}
								</Overlay>
							</div>
						}
					/>
					: null
			}
			{
				isFromSubscription(booking)
					? <CheckboxField
						id="withSubscription"
						value={withSubscription}
						colorType='dark'
						noMargin={true}
						setValue={() => {
							setWithSubscription(!withSubscription)
						}}
						extraClassName="text-left"
						label={
							<div>
								<p>Aplic modificarea si pentru sedintele viitoare</p>
							</div>
						}
					/>
					: null
			}
			<button onClick={onShowCalendar} className="button-small-edit mt-3 mb-4">
				Vezi date disponibile
			</button>
			{
				calendar?.minDate
					? <DateTimeCalendar
						onSubmitCalendar={onUpdateBookingDateTime}
						buttonText="Actualizeaza"
					/>
					: null
			}
		</div>
	)
}
export default BookingChangeDateTime