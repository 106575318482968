const APP_NAME = 'app_name'
const ZUMZI = 'zumzi';
const CLEANY = 'cleany';
const ONE_YEAR = 60 * 24 * 365;

export const APP_GROUP_CLEANY = 1;
export const APP_GROUP_ZUMZI = 2;


export const isDevelopmentEnv = () => {
	return window.REACT_APP_ENV === 'dev'
}

export const isStagingEnv = () => {
	return window.REACT_APP_ENV === 'stag'
}

export const isProductionEnv = () => {
	return window.REACT_APP_ENV === 'prod'
}