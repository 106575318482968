export const getStringValue = (value, valueType, currency = 'RON') => {
    if (valueType === 1) {
        return value + " " + currency
    } else if (valueType === 2) {
        return value + "%"
    }
    return ''
}

export const getValueDiscount = (refferenceValue, value, valueType) => {
    if (valueType === 1) {
        return parseInt(value)
    } else if (valueType === 2) {
        return parseInt(refferenceValue * value / 100)
    }
    return ''
}