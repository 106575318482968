import { LOGOUT_RESET, GET_SUBSCRIPTION_BOOKING_SUCCESS } from '../constants'

const initialState = {}

export const subscriptionBooking = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_BOOKING_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}