export const BILLING_DATA_TYPE_PERSON = 0
export const BILLING_DATA_TYPE_COMPANY = 1

export const getNormalizedAddress = (billingData) => {
    billingData.address = {
        streetAddress: billingData.address
    }
    return billingData
}

export const getEmptyBillingData = () => {
    return  {
        name: '',
        phone: '',
        email: '',
        taxId: '',
        idNumber: '',
        address: '',
        iban: '',
        bank: '',
    }
}