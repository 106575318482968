import React, {useState} from 'react';
import GuestTemplate from "./GuestTemplate";
import {useDispatch} from "react-redux";
import {contactUnauthAction} from "../../redux/actions";
import {Col, Container, Row} from "react-bootstrap";
import {Icon} from "@iconify/react/dist/iconify";
import {colors} from "../../utils/style/style";
import zumzi_casa from "../../assets/images/zumzi_casa.png";

import "../../assets/css/guest/contact.scss"
import {Link} from "react-router-dom";
import {ROUTE_TERMS_AND_CONDITIONS} from "../../routes/constants";
import {useInputValidation} from "../../utils/hooks/useInputValidation";
import {isEmail, isMessage, isName, isPhone} from "../../utils/validation/conditions";
import {translate} from "../../utils/i18n";
import {isValidObject} from "../../utils/validation/validate";
import InputField from "../common/input/InputField";
import CheckboxField from "../common/input/CheckboxField";

export const ContactPage = (props) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')
	const [acceptedTerms, setAcceptedTerms] = useState(false)

	const validations = {
		name: useInputValidation(name, isName(name), translate('validation.name.invalid')),
		email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
		phone: useInputValidation(phone, isPhone(phone), translate('validation.phone.invalid')),
		message: useInputValidation(message, isMessage(message), translate('validation.message.invalid')),
		acceptedTerms: useInputValidation(acceptedTerms, acceptedTerms === true, translate('validation.terms.invalid'))
	}

	const dispatch = useDispatch()
	const color = colors.brand

	const onSubmitForm = async () => {
		if (isValidObject(validations)) {
			dispatch(contactUnauthAction({email, name, phone, message}))
		}
	}

	return (
		<GuestTemplate label="contact" title="Contacteaza-ne" subtitle="Ai o intrebare la care iti putem raspunde?"
					   image={null}>
			<Container className="mb-5">
				<Row>
					<Col lg={4} className="contact-left-container">
						<h4>
							Date de contact
						</h4>
						<p>
							Simte-te liber sa ne scrii
						</p>

						<div className="contact-info-container">
							<a href="#">
								<Icon icon="ic:round-schedule" color={color} width={20} height={20}/>
								<span>
                                    L-V 08:00 - 18:00
                                </span>
							</a>


							<a href="tel:+40756844975">
								<Icon icon="akar-icons:phone" color={color} width={20} height={20}/>
								<span>
                                    +40 756 844 975
                                </span>
							</a>
							<a href="mailto:hello@zumzi.com">
								<Icon icon="ant-design:mail-outlined" color={color} width={20} height={20}/>
								<span>
                                    hello@zumzi.com
                                </span>
							</a>
							<a href="https://www.google.ro/maps/place/Bulevardul+Tudor+Vladimirescu+22,+Bucure%C8%99ti/@44.4194867,26.0773359,17z/data=!3m1!4b1!4m5!3m4!1s0x40b1ff741cfcfb3b:0xac539363b85a224e!8m2!3d44.4194867!4d26.0795246">
								<Icon icon="bx:bx-map" color={color} width={20} height={20}/>
								<span>
                                    Bulevardul Tudor Vladimirescu 22, București
                                </span>
							</a>
						</div>
						<div style={{display: 'flex', justifyContent: 'center'}}>
							<img src={zumzi_casa} alt="zumzi servicii" className="contact-image"/>
						</div>

						<div className="contact-social-container">
							<a href="https://www.facebook.com/zumzi" className="footer-social-link">
								<Icon icon="brandico:facebook" color={color} width={20} height={20}/>
							</a>
							<a href="https://www.instagram.com/zumzi.app" className="footer-social-link">
								<Icon icon="ant-design:instagram-outlined" color={color} width={20} height={20}/>
							</a>
						</div>
					</Col>
					<Col lg={8} className="contact-right-container">
						<InputField
							className="w-lg-50"
							label="Nume"
							type="text"
							placeholder="Nume si prenume"
							value={name}
							setValue={setName}
							validation={validations.name}
						/>
						<InputField
							className="w-lg-50"
							label="Email"
							type="email"
							placeholder="Adresa de email"
							value={email}
							setValue={setEmail}
							validation={validations.email}
						/>
						<InputField
							className="w-lg-50"
							label="Telefon"
							type="text"
							placeholder="Numar de telefon"
							value={phone}
							setValue={setPhone}
							validation={validations.phone}
						/>
						<InputField
							className="w-100"
							label="Cu ce te putem ajuta?"
							type="text"
							isTextarea={true}
							rows={3}
							placeholder="Mesajul tau"
							value={message}
							setValue={setMessage}
							validation={validations.message}
							onSubmitForm={onSubmitForm}
						/>
						<CheckboxField
							id="acceptedTerms"
							value={acceptedTerms}
							setValue={() => {
								setAcceptedTerms(!acceptedTerms)
							}}
							label={<>Accept <Link to={ROUTE_TERMS_AND_CONDITIONS}>termenii si conditiile</Link></>}
							validation={validations.acceptedTerms}
						/>

						<div className="text-center-n-lg">
							<button className="button-cta-sec d-inline-block" onClick={onSubmitForm}>
								Trimite
							</button>
						</div>
					</Col>
				</Row>
			</Container>
		</GuestTemplate>
	)
}
export default ContactPage
