import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	decreaseOrderDetailsStepAction,
	getLockboxAction,
	getVouchersActions,
	showModalPopup
} from "../../../../redux/actions";
import {SET_ORDER_DETAILS_SUCCESS} from "../../../../redux";
import {Redirect} from "react-router-dom";
import {ROUTE_ORDER, ROUTE_VOUCHERS} from "../../../../routes/constants";
import CheckboxField from "../../../common/input/CheckboxField";
import EditButton from "../../../common/buttons/EditButton";
import AddButtonSmall from "../../../common/buttons/AddButtonSmall";
import Vouchers from "../../voucher/Vouchers";
import Lockbox from "./Lockbox";
import {getValueDiscount} from "../../../../utils/valueType";
import {translate} from "../../../../utils/i18n";
import {getProductName} from "../../../../utils/model/product";


let servicePrice = ''
let serviceOldPrice = ''
let lockboxPrice = ''
let lockboxOldPrice = ''
let voucher = ''
let voucherCode = ''
let fullPrice = ''
let totalPrice = ''

export const Step4 = (props) => {
	const [buyLockbox, setBuyLockbox] = useState(false)
	const [goToVoucher, setGoToVoucher] = useState(false)

	const user = useSelector(state => state.login)
	const product = useSelector(state => state.product)
	const selectedProductOptions = useSelector(state => state.selectedProductOptions)
	const lockbox = useSelector(state => state.lockbox)
	const vouchers = useSelector(state => state.vouchers.userVouchers)

	const dispatch = useDispatch()
	const orderDetails = useSelector(state => state.orderDetails)

	try {
		if (selectedProductOptions.length) {
			const productOption = selectedProductOptions[selectedProductOptions.length - 1]
			servicePrice = parseInt(productOption.publicPrice?.value)
			serviceOldPrice = parseInt(productOption.publicPrice?.oldValue)
		}
		if (lockbox) {
			lockboxPrice = parseInt(lockbox.publicPrice?.value)
			lockboxOldPrice = parseInt(lockbox.publicPrice?.oldValue)
		}
		if (vouchers.length > 0) {
			voucher = getValueDiscount(servicePrice, vouchers[0]?.voucher?.offer?.value, vouchers[0]?.voucher?.offer?.valueType)
			voucherCode = vouchers[0]?.voucher?.code
		}

		fullPrice = parseInt(serviceOldPrice || servicePrice || 0) + parseInt(buyLockbox ? lockboxOldPrice || lockboxPrice || 0 : 0)
		totalPrice = servicePrice + parseInt(buyLockbox ? lockboxPrice || 0 : 0) - parseInt(voucher || 0)
	} catch (err) {
	}

	useEffect(() => {
		dispatch(getLockboxAction())
		dispatch(getVouchersActions(user?.id))

		setBuyLockbox(orderDetails.buyLockbox)
		setGoToVoucher(false)
	}, [])

	const saveOrderDetails = () => {
		dispatch({type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, buyLockbox}})

	}

	const navigateToVoucher = () => {
		saveOrderDetails()
		dispatch(showModalPopup("", <Vouchers hideReferral={true}/>, null, 'lg'))
	}

	const onSubmit = () => {
		dispatch({
			type: SET_ORDER_DETAILS_SUCCESS,
			payload: {...orderDetails, buyLockbox, currentStep: orderDetails.currentStep + 1, totalPrice}
		})
	}

	const onShowLockbox = () => {
		dispatch(showModalPopup("Lockbox", <Lockbox/>))
	}

	if (goToVoucher) {
		return <Redirect to={`${ROUTE_VOUCHERS}?redirectPath=${ROUTE_ORDER}`}/>
	}


	return (
		<div>
			<div className="service-description-container">
				<div style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<CheckboxField
						id="lockbox1"
						value={buyLockbox}
						colorType="dark"
						noMargin={true}
						setValue={() => {
							setBuyLockbox(!buyLockbox)
						}}
						label={<>Vreau lockbox</>}
					/>
					<div style={{textAlign: 'right'}}>
						{
							!!lockboxOldPrice &&
							<span className="service-old-price">{lockboxOldPrice} RON</span>
						}
					</div>
					<p className="service-price">
						{lockboxPrice} RON
					</p>
				</div>

			</div>
			<div className="mb-4" style={{textAlign: 'right'}}>
                <span className="see-details-text" onClick={() => {
					onShowLockbox()
				}}>
                    ce este un lockbox?
                </span>
			</div>

			{/* price summary */}
			<div className="service-summary-container">
				<p className="font-weight-bold color-brand">Sumar</p>

				<div className="mb-2" style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<CheckboxField
						id="product"
						value={true}
						setValue={() => {
						}}
						colorType="dark"
						noMargin={true}
						label={getProductName(product)}
					/>
					<p className="service-price">
						{servicePrice} RON
					</p>
				</div>
				<div className="mb-2" style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}>
					<CheckboxField
						id="lockbox"
						value={buyLockbox}
						setValue={() => setBuyLockbox(!buyLockbox)}
						colorType="dark"
						noMargin={true}
						label="Lockbox"
					/>
					{
						buyLockbox
							? <p className="service-price">
								{lockboxPrice} RON
							</p>
							: null
					}

				</div>

				<div className="mb-2" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<div>
							<div className="float-left" style={{marginRight: '0.5rem'}}>
								<CheckboxField
									id="voucher"
									value={!!voucher}
									setValue={() => {
									}}
									colorType="dark"
									noMargin={true}
									label="Voucher:"
								/>
							</div>
							<span className="color-brand-secondary">
                            {voucher ? voucherCode : "-"}
                        </span>
						</div>

						{
							voucher
								? <EditButton
									text="Schimba voucher"
									className="bg-transparent"
									onClick={navigateToVoucher}
								/>
								: <AddButtonSmall
									text="Adauga cod"
									className="bg-transparent"
									onClick={navigateToVoucher}
								/>
						}
					</div>

					{
						!!voucher
							? <p className="service-discount-price">
								- {voucher} RON
							</p>
							: null
					}

				</div>
			</div>
			<div className="service-summary-price-container">
				{
					fullPrice > totalPrice
						? <p>
							{fullPrice} RON
						</p>
						: null
				}
				<h6>TOTAL: {totalPrice} RON</h6>
				<span>
					*include TVA de 19%
				</span>
			</div>

			<div>
				<button
					className="button-back float-left"
					onClick={() => dispatch(decreaseOrderDetailsStepAction(orderDetails))}
				>
					{"< Inapoi"}
				</button>
				<button
					className="button-gradient-primary"
					onClick={onSubmit}
				>
					Continua
				</button>
			</div>

		</div>
	)
}
export default Step4