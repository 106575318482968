import React, {useState} from "react";
import {AuthTemplate} from "./AuthTemplate";
import {useDispatch} from "react-redux";
import {loginActions} from "../../redux/actions";
import {useInputValidation} from "../../utils/hooks/useInputValidation";
import {isEmail, isPassword} from "../../utils/validation/conditions";
import {translate} from "../../utils/i18n";
import {isValidObject} from "../../utils/validation/validate";
import {FormGroup} from "react-bootstrap";
import InputField from "../common/input/InputField";
import {Link} from "react-router-dom";
import {ROUTE_REGISTER, ROUTE_RESET_PASSWORD} from "../../routes/constants";

export const LoginPage = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const validations = {
        email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
        password: useInputValidation(password, isPassword(password), translate('validation.password.invalid')),
    }

    const dispatch = useDispatch()

    const onSubmitLogin = () => {
        if (isValidObject(validations)) {
            dispatch(loginActions(email, password))
        }
    }

    return (
        <AuthTemplate title="Bine ai venit" subtitle="Te rugam sa completezi datele de mai jos">
            <FormGroup>
                <InputField
                    className="w-100"
                    type="email"
                    placeholder="Email"
                    icon="email"
                    value={email}
                    setValue={setEmail}
                    validation={validations.email}
                />
                <InputField
                    className="w-100"
                    type="password"
                    placeholder={translate('global.placeholder.password')}
                    icon="password"
                    value={password}
                    setValue={setPassword}
                    validation={validations.password}
                    onSubmitForm={onSubmitLogin}
                />
                <div className="auth-extra-links">
                    <p>
                        Nu ai cont?{" "}
                        <Link to={ROUTE_REGISTER}>
                            Inregistreaza-te
                        </Link>
                    </p>
                    <Link to={ROUTE_RESET_PASSWORD}>
                        Ai uitat parola?
                    </Link>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="button-gradient-primary"  onClick={onSubmitLogin}>
                        Conectare
                    </button>
                </div>
                <div className="text-center">
                    <p className="d-lg-none mt-3">
                        Nu ai cont?{" "}
                        <Link to={ROUTE_REGISTER}>
                            Inregistreaza-te
                        </Link>
                    </p>
                </div>
            </FormGroup>
        </AuthTemplate>
    )
}
export default LoginPage