import React from 'react';

import {ReactComponent as Carpet_cleaning} from '../../assets/images/new/services/icons/carpet_cleaning.svg';
import {ReactComponent as Cleaning} from '../../assets/images/new/services/icons/cleaning.svg';
import {
	ReactComponent as Clean_after_construction
} from '../../assets/images/new/services/icons/clean_after_construction.svg';
import {ReactComponent as Clean_couch} from '../../assets/images/new/services/icons/clean_couch.svg';
import {ReactComponent as Clean_general_new} from '../../assets/images/new/services/icons/clean_general_new.svg';
import {ReactComponent as Clean_one_time} from '../../assets/images/new/services/icons/clean_one_time.svg';
import {
	ReactComponent as Clean_weekly_2_5_hours
} from '../../assets/images/new/services/icons/clean_weekly_2.5_hours.svg';
import {ReactComponent as Custom_service} from '../../assets/images/new/services/icons/custom_service.svg';
import {ReactComponent as Disinfection} from '../../assets/images/new/services/icons/disinfection.svg';
import {ReactComponent as Disinsection} from '../../assets/images/new/services/icons/disinsection.svg';
import {ReactComponent as Electrician} from '../../assets/images/new/services/icons/electrician.svg';
import {ReactComponent as Massage} from '../../assets/images/new/services/icons/massage.svg';
import {ReactComponent as Plumber} from '../../assets/images/new/services/icons/plumber.svg';
import {
	ReactComponent as Professional_cleaning
} from '../../assets/images/new/services/icons/professional_cleaning.svg';
import {ReactComponent as Textile} from '../../assets/images/new/services/icons/textile.svg';
import {
	ReactComponent as Home_management_subscription
} from '../../assets/images/new/services/icons/home_management_subscription.svg';
import {ReactComponent as Dog_walking} from '../../assets/images/new/services/icons/dog_walking.svg';
import {ReactComponent as Repairs} from '../../assets/images/new/services/icons/repairs.svg';
import {ReactComponent as Locksmith} from '../../assets/images/new/services/icons/locksmith.svg';

import {
	PRODUCT_CODE_CUSTOM,
	PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION,
	PRODUCT_CODE_TEXTILE
} from "../constants/product";

const imagesByCode = {
	Carpet_cleaning,
	Clean_after_construction,
	Clean_couch,
	Clean_general_new,
	Clean_one_time,
	Clean_weekly_2_5_hours,
	Cleaning,
	Custom_service,
	Disinfection,
	Disinsection,
	Electrician,
	Massage,
	Plumber,
	Professional_cleaning,
	Textile,
	Home_management_subscription,
	Dog_walking,
	Repairs,
	Locksmith
}

export const getSVGByServiceCode = (code, width = 48) => {
	const updatedCode = code?.charAt(0).toUpperCase() + code?.slice(1).replace('.', '_');
	const SVGComponent = imagesByCode[updatedCode]
	if (!SVGComponent) {
		return null
	}
	const props = {
		className: "fill-white",
		width: 60,
		height: '100%',
	}
	if ([PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION, PRODUCT_CODE_CUSTOM].includes(code)) {
		props.className = ''
	} else if (code === PRODUCT_CODE_TEXTILE) {
		props.className = 'stroke-white'
	}
	return <SVGComponent  {...props}/>
}
