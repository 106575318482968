import React from 'react';
import GuestTemplate from "../GuestTemplate";
import {Col, Container, Row} from "react-bootstrap";
import {colors} from "../../../utils/style/style";

import "../../../assets/css/guest/home/home.scss"

import maitenance from "../../../assets/images/new/home/maintenance.png"
import calendar from "../../../assets/images/new/home/calendar.png"
import residents from "../../../assets/images/new/home/residents.png"
import listing from "../../../assets/images/new/home/listing.png"
import benefitsImage from "../../../assets/images/new/home/zumzi_benefits.png"
import bookingImage from "../../../assets/images/new/home/booking_logo.png"
import airbnbImage from "../../../assets/images/new/home/airbnb_logo.png"

import mihai_ionita from "../../../assets/images/new/homeServices/zumzi_mihai_ionita.png";
import ana_canja from "../../../assets/images/new/homeServices/zumzi_ana_canja.png";
import calin_fusu from "../../../assets/images/new/homeServices/zumzi_calin_fusu.png";
import SliderComponent from "../../common/SliderComponent";
import {useWindowResize} from "../../../utils/hooks/useWindowResize";
import {ContactForm} from "./ContactForm";
import {CustomInputSlider} from "./CustomInputSlider";

const benefits = [
	{
		img: maitenance,
		title: "Mentenață completă asigurată ",
		desc: "Asigurăm servicii de curățenie, sisteme electrice și instalații pentru toate locațiile tale",
		alt: "mentenanta",
	},
	{
		img: calendar,
		title: "Fără pauze în venit ",
		desc: "Nu mai pierzi timpul între chiriași prin sistemul de închiriere pe termen scurt. Ne ocupăm de oaspeți complet, de la rezervare la checkout.",
		alt: "fara pauze",
	},
	{
		img: residents,
		title: "Transparență și siguranța închirieri",
		desc: "Verificăm cu atenție oaspeții tăi pentru ca tu să ai cele mai mici costuri de uzură, întreținere sau daune imprevizibile. Ai toate datele despre proprietatea ta într-un singur loc",
		alt: "rezidenti",
	},
	{
		img: listing,
		title: "Listare pe mai multe platforme",
		desc: "Ne asigurăm că proprietatea ta apare pe cele mai populare platforme (airbnb, booking), iar tu o administrezi dintr-un singur cont fără să-ți faci griji de overbooking.",
		alt: "listare",
	},
]

const stats = [
	{
		title: "Grad de ocupare",
		value: "85%",
		note: "in medie"
	},
	{
		title: "Recenzii",
		value: "4.9+",
		note: "peste 100 de clienti",
	},
]

const howItWorksSteps = [
	{
		title: 'Completează formularul de contact',
	},
	{
		title: 'Adaugă detaliile proprietății tale',
	},
	{
		title: 'Te listă pe cele mai populare platforme de închiriere',
	},
	{
		title: 'Ne ocupăm de managmentul oaspeților tăi și de mentenața proprietății ',
	},
]

const testimonials = [
	{
		img: calin_fusu,
		title: 'Calin Fusu',
		subtitle: 'CEO Neogen Capital',
		desc: 'Am fost atât de impresionat de servicii și de platforma încât am și investit în companie',
	},
	{
		img: mihai_ionita,
		title: 'Mihai Ionita',
		subtitle: 'Avocat PBNI',
		desc: 'E uimitor ca ai un randament mult mai bun decat in cazul inchirierii pe termen lung',
	},
	{
		img: ana_canja,
		title: 'Ana Canja',
		subtitle: 'Freelancer',
		desc: 'Cel mai bun serviciu de home management din oraș! Inchiriere fara batai de cap',
	},
]

const listingPlatforms = [
	{
		image: airbnbImage,
		alt: 'aribnb logo',
	},
	{
		image: bookingImage,
		alt: 'booking logo',
	}
]

export const HomePage = ({location}) => {
	const {width} = useWindowResize()


	return (
		<GuestTemplate
			label="home"
			title={<>Partener cu Zumzi</>}
			subtitle="Zumzi oferta proprietarilor, dezvoltatorilor imobiliari si proprietarilor venituri sigure prin închiriere pe termen scurt."
			showHeroBanner={false}
		>
			<Container className="banner-container" id="contact-form">
				<Row>
					<Col lg={8} className="d-none d-lg-block">
						<div  className="banner-img"/>
					</Col>
					<Col lg={6} className="banner-form">
						<ContactForm/>
					</Col>
				</Row>
			</Container>



			{/* benefits*/}
			<Container style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row className="mt-6">
					<Col lg={8}>
						<div>
							<h1 className="font-weight-bold text-center-n-lg">
								Beneficiile noastre
							</h1>
							<p className="text-center-n-lg" style={{fontWeight: 500}}>
								De ce să alegi Zumzi?
							</p>
						</div>
						<Row style={{marginTop: '3rem'}}>
							{
								benefits.map((benefit, index) => {
									return <Col lg={6} className="mb-5" key={index}>
										<img src={benefit.img} alt={benefit.alt}/>
										<h4 className="font-weight-bold" style={{marginTop: '2rem', marginBottom: '1rem'}}>
											{benefit.title}
										</h4>
										<p className="color-grey-text">
											{benefit.desc}
										</p>
									</Col>
								})
							}
						</Row>
					</Col>

					<Col lg={4} style={{textAlign: 'center'}}>
						<img src={benefitsImage} alt="beneficii" className="benefits-image"/>
					</Col>
				</Row>
			</Container>

			{/* calculator */}
			<Container>
				<Row>
					<Col lg={6}>
						<div className="home-form">
							<h1 className="color-white mb-4">
								Cifre pentru proprietari
							</h1>

							<CustomInputSlider/>

							<a href="#contact-form" className="button-cta-sec mt-3 p-font-size d-block w-100">
								Devino partener
							</a>
						</div>
					</Col>
					<Col lg={6} style={{paddingTop: '3rem', paddingBottom: '3rem'}}>
						<div style={{height: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
							{
								stats.map((stat, index) => {
									return <div className="text-center mb-4" key={index}>
										<h4 className="font-weight-bold" style={{marginBottom: '1rem'}}>
											{stat.title}
										</h4>
										<p style={{fontWeight: 'bold', fontStyle: "italic", fontSize: '28px'}}>
											{stat.value}
										</p>
										<p>
											{stat.note}
										</p>
									</div>
								})
							}
						</div>

					</Col>
				</Row>
			</Container>

			{/* how it works */}
			<Container fluid style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row>
					<div>
						<h1 className="font-weight-bold text-center">
							Cum funcționează?
						</h1>
						<p className="text-center" style={{fontWeight: 500}}>
							În 4 pași simpli ai devenit partener Zumzi și poți să faci bani din proprietatea ta
						</p>
					</div>
				</Row>
				<Row className="mt-6">
					<Col lg={4} className="d-none d-lg-block how-it-works-left-img">
					</Col>
					<Col lg={5}>
						<div className="how-it-works-center">
							{
								howItWorksSteps.map((step, index) => {
									return <div key={index}>
										<div style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											flexWrap: ''
										}}>
											<div style={{
												background: "linear-gradient(270deg, #345AC6 0.18%, #2541A1 100.18%)",
												borderRadius: '100%',
												marginRight: '1rem',
												height: '4rem',
												alignItems: 'center',
												display: 'flex',
												justifyContent: 'center',
												color: 'white',
												fontSize: '1.75rem',
												padding: '1.5rem'
											}}>
												{index + 1}
											</div>
											<span className="color-grey-text" style={{
												textDecoration: index ? 'none' : 'underline',
												fontSize: '1.75rem',
												lineHeight: '2rem'
											}}>
												{
													index === 0
													? <a href="#contact-form" className="color-grey-text">
															{step.title}
														</a>
													: step.title
												}

											</span>
										</div>
										{
											index + 1 !== howItWorksSteps.length
												? <div
													style={{
														borderLeft: '2px dashed #C4C4C4',
														height: '4rem',
														marginLeft: 'calc(2rem - 1px)'
													}}
												/>
												: <></>
										}
									</div>
								})
							}
						</div>

					</Col>

					<Col lg={3} className="d-none d-lg-block how-it-works-right-img">
					</Col>

					<Col lg={12} className="text-center mt-6 mb-6">
						<a href="#contact-form" className="button-cta-sec p-font-size button-big">
							Devino partener Zumzi
						</a>
					</Col>

				</Row>
			</Container>

			{/* testimonials */}
			<Container className="mt-6 mb-6" id="form">
				<Row>
					<Col lg={12} className="mb-4 text-center-n-lg">
						<h1 className="font-weight-bold">
							Ce spun partenerii noștri:
						</h1>
					</Col>
					<Col lg={12}>

						<SliderComponent
							slidesToShow={1}
							items={testimonials}
							ItemComponent={({item}) => {
								return <div className="testimonials-container">
									<div>
										<h1 style={{fontWeight: 400, color: 'white', marginTop: 0, marginBottom: '2rem'}}>
											"{item.desc}"
										</h1>
									</div>
									<div className="t-header-container">
										<img src={item.img} alt="zumzi user"/>
										<div>
											<p style={{color: 'white', fontWeight: 'bold'}}>
												{item.title}
											</p>
											<p style={{color: 'white', fontWeight: 100}}>
												{item.subtitle}
											</p>
										</div>
									</div>
								</div>
							}}
							extraClassName="slick-slider-top-right-buttons"
						/>
					</Col>
				</Row>
			</Container>

			{/*	our locations */}
			<Container style={{marginTop: '5rem', marginBottom: '3rem'}}>
				<Row>
					<Col lg={12} className="d-block d-lg-none" style={{marginTop: '3rem'}}/>
					<Col lg={12}>
						<h1 className="font-weight-bold text-center-n-lg" style={{paddingBottom: '2rem'}}>
							Locațiile noastre:
						</h1>
					</Col>
				</Row>
			</Container>


			<Container fluid>
				<Row className="locations-first-row">
					<SliderComponent
						slidesToShow={width > 991 ? 2.45 : 1.4}
						items={[1, 2, 3, 4 ,5]}
						ItemComponent={({item, index}) => {
							return <div className={`locations locations-first-${index + 1}`}/>
						}}
						extraClassName="slick-slider-gallery"
					/>
				</Row>

				<Row className="locations-second-row">
					<SliderComponent
						slidesToShow={width > 991 ? 2.1 : 1.6}
						items={[1, 2, 3, 4, 5]}
						ItemComponent={({item}) => {
							return <div className={`locations locations-second-${item}`}/>
						}}
						extraClassName="slick-slider-gallery"
					/>
				</Row>
			</Container>

			{/* listing platforms */}
			<Container style={{marginTop: '3rem', marginBottom: '3rem'}}>
				<Row>
					<Col lg={12} className="d-block d-lg-none" style={{marginTop: '3rem'}}/>
					<Col lg={12}>
						<h1 className="font-weight-bold text-center-n-lg" style={{paddingBottom: '2rem'}}>
							Platforme de listare:
						</h1>
					</Col>
					{
						listingPlatforms.map((platform, index) => {
							return (
								<Col sm={12 / listingPlatforms.length} lg={12 / listingPlatforms.length} index={index} style={{marginBottom: "2rem"}}>
									<div style={{padding: '8rem 4rem', textAlign: 'center', backgroundColor: '#f2f2f2', borderRadius: '16px'}}>
										<img src={platform.image} alt={platform.alt} style={{margin: 'auto'}}/>
									</div>
								</Col>
							)
						})
					}
				</Row>
			</Container>

		</GuestTemplate>
	)
}
export const styles = {
	title: {},
	list: {
		marginTop: '1rem',
		paddingLeft: '1.5rem',
		color: colors.white,
		fontSize: '1.25rem',
	},
	serviceTitle: {
		color: colors.white,
		marginTop: '5rem',
		marginBottom: '3rem',
	},
}
export default HomePage
