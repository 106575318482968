import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {useDispatch, useSelector} from "react-redux";


import "../../../assets/css/components/breadcrumb/breadcrumb.scss"
import {goToProductFormStepAction} from "../../../redux/actions/productFormDetails.actions";

export const ProductFormStepBreadcrumb = (props) => {
	const productFormDetails = useSelector(state => state.productFormDetails)


	const titles = ['Serviciu', 'Adresa', 'Creare cont']
	const dispatch = useDispatch()

	return (
		<Breadcrumb className="mb-4">
			{
				titles.map((title, index) => {
					const props = {key: index}
					if (index === productFormDetails.currentStep - 1) {
						props.active = true
						props.className = "font-weight-bold color-brand"
					} else if (index > productFormDetails.currentStep - 1) {
						props.active = true
					} else {
						props.onClick = () => {
							dispatch(goToProductFormStepAction(productFormDetails, index + 1))
						}
					}
					return <Breadcrumb.Item {...props}>{title}</Breadcrumb.Item>
				})
			}
		</Breadcrumb>
	)
}
export default ProductFormStepBreadcrumb