import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {decreaseProductFormDetailsStepAction} from "../../../redux/actions/productFormDetails.actions";
import Register from "../../authentication/Register";

export const Step3 = () => {
	const productFormDetails = useSelector(state => state.productFormDetails)
	const dispatch = useDispatch()

	const backCallback = () => {
		dispatch(decreaseProductFormDetailsStepAction(productFormDetails))
	}

	return (
		<Register
			isLocalSave={true}
			backCallback={backCallback}
		/>
	)
}
export default Step3