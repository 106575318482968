import {LOGOUT_RESET, SET_ORDER_DETAILS_SUCCESS} from '../constants'
import {orderDetailsInitialState} from "../../utils/model/orderDetails";

export const orderDetails = (state = orderDetailsInitialState, action) => {
    switch (action.type) {
        case SET_ORDER_DETAILS_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return orderDetailsInitialState
        default:
            return state
    }
}