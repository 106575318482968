import React, {useEffect, useState} from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import {useDispatch, useSelector} from "react-redux";
import {getOneTimeBookingsActions, getProductFormsAction, getSubscriptionsAction} from "../../../redux/actions";
import NoDataAvailable from "../../common/NoDataAvailable";
import {buildBookingModel} from "../../../utils/model/booking";

import "../../../assets/css/account/my_orders/my_orders.scss"
import {buildSubscriptionModel} from "../../../utils/model/subscription";
import {buildProductFormModel} from "../../../utils/model/productForm";
import OrderDetailsItem from "./OrderDetailsItem";
import {
	GET_ONE_TIME_BOOKINGS_SUCCESS,
	GET_PRODUCT_FORMS_SUCCESS,
	GET_SUBSCRIPTIONS_SUCCESS,
	HIDE_SPINNER
} from "../../../redux";

export const MyOrdersPage = (props) => {
	const [models, setModels] = useState({
		show: false,
		isBookingDone: false,
		isSubscriptionDone: false,
		isProductFormDone: false,
		elements: []
	})

	const bookings = useSelector(state => state.bookings)
	const subscriptions = useSelector(state => state.subscriptions)
	const productForms = useSelector(state => state.productForms)
	const user = useSelector(state => state.login)

	const dispatch = useDispatch()

	useEffect(() => {
		unsubscribeCallback()

		dispatch(getSubscriptionsAction(user.id))
		dispatch(getOneTimeBookingsActions())
		dispatch(getProductFormsAction(user?.id))
	}, [])

	const unsubscribeCallback = () => {
		setModels({
			show: false,
			isBookingDone: false,
			isSubscriptionDone: false,
			isProductFormDone: false,
			elements: []
		})
		dispatch({type: GET_SUBSCRIPTIONS_SUCCESS, payload: null})
		dispatch({type: GET_ONE_TIME_BOOKINGS_SUCCESS, payload: null})
		dispatch({type: GET_PRODUCT_FORMS_SUCCESS, payload: null})
	}

	const buildModel = (list, buildGenericModel, modelKey) => {
		if (list) {
			const newModels = []
			if (list.length) {
				list.forEach((item) => {
					newModels.push(buildGenericModel(item))
				})
			}
			setModels({...models, [modelKey]: true, elements: models.elements.concat(newModels)})
		}
	}

	useEffect(() => {
		buildModel(subscriptions, buildSubscriptionModel, 'isSubscriptionDone')
	}, [subscriptions])

	useEffect(() => {
		buildModel(bookings, buildBookingModel, 'isBookingDone')
	}, [bookings])

	useEffect(() => {
		buildModel(productForms, buildProductFormModel, 'isProductFormDone')
	}, [productForms])

	useEffect(() => {
		if (models.isSubscriptionDone && models.isProductFormDone && models.isBookingDone) {
			dispatch({type: HIDE_SPINNER})
			if (models.elements.length) {
				let newModels = models.elements
				newModels.sort((model1, model2) => {
					return new Date(model2.sortKey) - new Date(model1.sortKey)
				})
				newModels = removeProductFormsWithBooking(newModels)

				setModels({...models, show: true, elements: newModels})
			} else {
				setModels({...models, show: true})
			}
		}
	}, [models.isSubscriptionDone, models.isProductFormDone, models.isBookingDone])

	const removeProductFormsWithBooking = (newModels) => {
		const productFormToDeleteIds = newModels
			.filter((model) => {
				return model.type === 'booking' && !!model.data.listing?.productForm?.id
			}).map((model) => {
				return model.data.id
			})

		if (productFormToDeleteIds.length) {
			return newModels.filter((model) => {
				if (model.type === 'productForm') {
					if (productFormToDeleteIds.includes(model.data?.id)) {
						return false
					}
				}
				return true
			})
		}
		return newModels
	}

	return (
		<AccountTemplate label="my_orders" title="Comenzile mele">
			<h4 className="account-title">
				Comenzile mele
			</h4>
			{
				models.show && !models.elements?.length
				&& <NoDataAvailable
					text={'Nu ai plasat nicio comanda'}
				/>
			}
			{
				models.show && models?.elements?.length && models.elements.map((model, index) => (
					<OrderDetailsItem key={index} model={model}/>
				))
			}

		</AccountTemplate>
	)
}
export default MyOrdersPage