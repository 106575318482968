import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {ROUTE_ADD_ADDRESS, ROUTE_LOGIN} from "../../../routes/constants";
import {LAST_AUTH_PATH} from "../../../utils/constants/constants";
import {saveToLocalStorage} from "../../../utils/storage";
import {getUserNeighbourhoodActions, setOrderDetailsPropertyAction} from "../../../redux/actions";

import "../../../assets/css/account/account.scss"
import AccountMenu from "./AccountMenu";
import AccountHeaderMobile from "./nav/AccountHeaderMobile";
import Reward from "./Reward";

export const AccountTemplate = ({title, children, label}) => {

	const user = useSelector(state => state.login)
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)
	const orderDetails = useSelector(state => state.orderDetails)
	const dispatch = useDispatch()

	useEffect(() => {
		if (user?.token && !userNeighbourhood?.id) {
			dispatch(getUserNeighbourhoodActions(user.id))
		}
	}, [])

	useEffect(() => {
		if (userNeighbourhood?.userPublicAddress?.address?.property) {
			dispatch(setOrderDetailsPropertyAction(orderDetails, userNeighbourhood?.userPublicAddress?.property))
		}
	}, [userNeighbourhood])

	if (!user.token) {
		let path = window.location.pathname + window.location.search
		saveToLocalStorage(LAST_AUTH_PATH, path)

		return <Redirect to={ROUTE_LOGIN}/>
	}

	if (userNeighbourhood === null && ![ROUTE_ADD_ADDRESS].includes(window.location.pathname)) {
		return <Redirect to={`${ROUTE_ADD_ADDRESS}?forcedRedirection=1`}/>
	}

	// if (userNeighbourhood?.id && !userNeighbourhood?.city?.availableForServices) {
	// 	if ([ROUTE_ORDER, ROUTE_ORDER_FORM, ROUTE_ORDER_STEP].includes(window.location.pathname)) {
	// 		return <Redirect to={ROUTE_DASHBOARD}/>
	// 	}
	// }

	return (
		<div className="account-background">
			<AccountMenu/>
			<div className="account-container">
				<div className="account-container-left">
					<div className="d-lg-none">
						<AccountHeaderMobile/>
					</div>

					{children}
				</div>
				<div className="account-container-right">
					<Reward/>
				</div>
				<div style={{clear: 'both'}}/>
			</div>
		</div>
	)
}
