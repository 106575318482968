import {useEffect, useState} from 'react'

export const useInputValidation = (inputValue, condition, errorMessage = 'Camp invalid') => {
    const [inputError, setInputError] = useState(null)

    const isValid = () => {
        if (inputError === null) {
            return true
        }
        return condition
    }

    const inputCheckError = () => {
        if (condition) {
            setInputError('')
            return false
        } else {
            setInputError(errorMessage)
            return true
        }
    }

    const inputReset = () => setInputError(null)

    useEffect(() => {
        if (inputError !== null) {
            if (condition) {
                setInputError('')
            } else {
                setInputError(errorMessage)
            }
        }
    }, [inputValue])

    return {
        inputError,
        inputReset,
        inputCheckError,
        isValid,
    }
}