import React from "react";
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {hideModalPopup} from "../../redux/actions";

export const ModalPopup = (props) => {
	const modalPopup = useSelector(state => state.modalPopup)

	const dispatch = useDispatch()

	const onClose = () => {
		if (modalPopup.onCloseCallback) {
			modalPopup.onCloseCallback()
		}
		dispatch(hideModalPopup())
	}
	const onSubmitCallback = () => {
		modalPopup.onSubmitCallback()
		dispatch(hideModalPopup())
	}

	return (
		<Modal size={modalPopup.size} show={modalPopup.show} onHide={onClose} centered>
			<Modal.Header closeButton style={modalPopup.hideHeaderBorder ? {borderBottom: 0} : {}}>
				<Modal.Title>
					{modalPopup.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					modalPopup.body
				}
			</Modal.Body>
			{
				modalPopup.onSubmitCallback
					? <Modal.Footer>
						<button onClick={onClose}>Inchide</button>
						<button
							className={(modalPopup.type === 'delete' ? " button-small-remove" : "button-gradient-primary")}
							onClick={onSubmitCallback}>
							{
								modalPopup.actionText
									? modalPopup.actionText
									: modalPopup.type === 'delete' ? 'Sterge' : 'Salveaza'
							}
						</button>
					</Modal.Footer>
					: null
			}
		</Modal>
	)
}
export default ModalPopup
