import {batch} from 'react-redux'

import {GET_PRODUCT, GET_PRODUCT_OPTION, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {ROUTE_HOME} from "../../routes/constants";

export const getProductActions = (productId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/products/${productId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT, payload})
			dispatch({type: HIDE_SPINNER})
			if (payload.productOptions.length) {
				dispatch({type: GET_PRODUCT_OPTION, payload: payload.productOptions[0]})
			}
		})
	} catch (err) {
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Pagina dorita nu exista', route: ROUTE_HOME}})
		})
	}
}
