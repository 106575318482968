import { LOGOUT_RESET, GET_BOOKING_SUCCESS } from '../constants'

const initialState = {}

export const booking = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_SUCCESS:
      return action.payload
    case LOGOUT_RESET:
      return initialState
    default:
      return state
  }
}