import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {updatePasswordAction} from "../../../redux/actions";
import {translate} from "../../../utils/i18n";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isPassword} from "../../../utils/validation/conditions";
import {isValidObject} from "../../../utils/validation/validate";
import InputField from "../../common/input/InputField";

export const UpdatePassword = () => {
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('********')

    const [disabledObject, setDisabledObject] = useState({
        password: true,
    })

    const validations = {
        oldPassword: useInputValidation(oldPassword, isPassword(oldPassword), translate('validation.password.invalid')),
        password: useInputValidation(password, isPassword(password), translate('validation.password.invalid')),
    }


    const dispatch = useDispatch()

    const toggleDisableInput = (key) => {
        if (disabledObject[key]) {
            setOldPassword('')
            setPassword('')
        }
        setDisabledObject({...disabledObject, [key]: !disabledObject[key]})
    }

    const onUpdatePassword = () => {
        if (isValidObject(validations)) {
            dispatch(updatePasswordAction(password, oldPassword))
            setPassword('********')
            setOldPassword('')
            toggleDisableInput('password')
        }
    }

    const onCloseEdit = () => {
        setOldPassword('')
        setPassword('********')
        toggleDisableInput('password')
    }

    return (
        <>
            <div style={{marginBottom: '2rem', display: 'flow-root'}}>
                <h6 style={{marginBottom: '0.5rem'}}>
                    Parola
                </h6>
                <div>
                    {
                        !disabledObject.password
                            ?
                            <>
                                <InputField
                                    className="input-medium"
                                    type="password"
                                    placeholder="********"
                                    value={oldPassword}
                                    setValue={setOldPassword}
                                    validation={validations.oldPassword}
                                />
                                <h6 style={{marginBottom: '0.5rem'}}>
                                    Parola noua
                                </h6>
                            </>
                            : null
                    }

                    <InputField
                        className="input-small float-left"
                        type="password"
                        placeholder="********"
                        value={password}
                        setValue={setPassword}
                        validation={validations.password}

                        onUpdate={onUpdatePassword}
                        onCloseEdit={() => onCloseEdit()}
                        disabledObject={disabledObject.password}
                        toggleDisabledInput={ () => toggleDisableInput('password')}
                    />
                </div>
            </div>
        </>
    )
}
export default UpdatePassword