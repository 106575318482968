import { LOGOUT_RESET, GET_SUBSCRIPTION_BOOKINNGS_SUCCESS } from '../constants'

const initialState = null

export const subscriptionBookings = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_BOOKINNGS_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}