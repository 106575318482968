import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropertyData from "../../personalData/PropertyData";
import {SET_ORDER_REQUEST_DETAILS_SUCCESS} from "../../../../redux";


export const Step1 = ({}) => {
	const dispatch = useDispatch()
	const orderRequestDetails = useSelector(state => state.orderRequestDetails)

	const onSubmitNextStep = (property) => {
		dispatch({
			type: SET_ORDER_REQUEST_DETAILS_SUCCESS,
			payload: {...orderRequestDetails, currentStep: orderRequestDetails.currentStep + 1, property}
		})
	}

	return (
		<div>
			<PropertyData
				property={orderRequestDetails?.property}
				toSubmit={false}
				parentSubmit={onSubmitNextStep}
			/>
		</div>
	)
}
export default Step1