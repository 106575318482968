import GA4React from "ga-4-react";
import ReactPixel from "react-facebook-pixel";
import {POSSIBLE_CLIENT} from "./constants/constants";
import {loadFromLocalStorage} from "./storage";
import {ROUTE_3DS_PAYMENT} from "../routes/constants";

const ga4react = new GA4React("G-XDME3XR271");
let globalGA4 = null;

export const trackEvent = (category, action = 'submit') => {
    try {
        if (loadFromLocalStorage('cookies')) {
            if (window.REACT_APP_ENV === 'prod') {
                if (globalGA4) {
                    globalGA4.gtag('event', category, {
                        method: 'Google',
                        action: action,
                        category: category,
                    });
                }

                ReactPixel.track(category, {
                    email: loadFromLocalStorage(POSSIBLE_CLIENT).email
                });
            }
        }
    } catch (e) {
        console.log("Track event error", e)
    }

}

export const configureAnalytics = () => {
    try {
        if (window.REACT_APP_ENV === 'prod') {
            if (window.location.pathname !== ROUTE_3DS_PAYMENT) {
                ga4react.initialize().then((ga4) => {
                    globalGA4 = ga4
                    globalGA4.pageview(window.location.pathname + window.location.search)
                }, (err) => {
                    console.error(err)
                })

                const options = {
                    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                    debug: false, // enable logs
                };
                ReactPixel.init('861802408357829', null, options);
                ReactPixel.pageView();
            }
        }
    } catch (e) {
        console.log("configureAnalytics", e)
    }

}
