export const getAddressFromPlacesDetails = (googleDetails) => {
	let locality = ''
	let streetAddress = ''
	let streetNumber = 0
	let latitude = 0
	let longitude = 0

	if (Object.keys(googleDetails).length === 0) {
		return null
	} else {
		if (googleDetails?.address_components) {
			let check = false
			//get locality
			for (let i = 0; i < googleDetails?.address_components.length; i++) {
				const loc = googleDetails?.address_components[i]?.types.includes('locality')
				if (loc) {
					locality = googleDetails?.address_components[i]?.long_name
					break
				}
			}
			//get streetAddress
			for (let i = 0; i < googleDetails?.address_components.length; i++) {
				const strAddress = googleDetails?.address_components[i]?.types.includes('route')
				if (strAddress) {
					streetAddress = googleDetails?.address_components[i]?.long_name
					break
				}
			}
			//get street number
			for (let i = 0; i < googleDetails?.address_components.length; i++) {
				const strNumber = googleDetails?.address_components[i]?.types.includes('street_number')
				if (strNumber) {
					check = true
					streetNumber = googleDetails?.address_components[i]?.long_name
					break
				}
			}
			if (!check) {
				return null
			}
		}
		const lat = googleDetails?.geometry?.location?.lat
		const long = googleDetails?.geometry?.location?.lng
		latitude = typeof lat === 'function' ? lat() : lat
		longitude = typeof long === 'function' ? long() : long
	}

	return {
		locality,
		streetAddress,
		streetNumber,
		latitude: String(latitude),
		longitude: String(longitude)
	}
}