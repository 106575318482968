export const orderRequestDetailsInitialState = {
	property: {
		number: '',
		block: '',
		stairwell: '',
		floorNumber: '',
		apartment: '',
		intercom: '',
		accessMode: '',
	},
	currentStep: 1,
	totalPrice: '',
	currency: 'RON'
}