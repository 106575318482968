import { NEIGHBOURHOOD_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {}

export const userNeighbourhood = (state = initialState, action) => {
    switch (action.type) {
        case NEIGHBOURHOOD_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}