import {axiosRequest} from '../../api/axiosRequest';
import {batch} from 'react-redux'
import {GET_CALENDAR_SUCCESS, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {showDefaultErrorAction} from "./alertModal.actions";

export const calendarActions = (productId, productOptionIds) => async dispatch => {
	// dispatch({ type: SHOW_SPINNER })
	try {
		let optionsIds = ''
		productOptionIds.forEach((id) => {
			optionsIds += `&optionIds[]=${id}`
		})
		const resp = await axiosRequest.get(`/calendar/product/${productId}?${optionsIds}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_CALENDAR_SUCCESS, payload})
			// dispatch({ type: HIDE_SPINNER })
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const getCalendarByProductFormRequestId = (ProductFormRequestId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {

		const resp = await axiosRequest.get(`/calendar/product_form_request/${ProductFormRequestId}`)

		const payload = resp.data
		batch(() => {
			dispatch({type: GET_CALENDAR_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}


export const getCalendarByBookingAction = (bookingId, sameAgent = 0, withSubscription = 0) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {

		const resp = await axiosRequest.get(`/calendar/booking/${bookingId}?sameAgent=${sameAgent}&withSubscription=${withSubscription}`)

		const payload = resp.data
		batch(() => {
			dispatch({type: GET_CALENDAR_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const resetCalendarAction = () => async dispatch => {
	dispatch({type: GET_CALENDAR_SUCCESS, payload: {}})
}