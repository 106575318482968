export const HIDE_INTRO_SLIDER = 'HIDE_INTRO_SLIDER'

export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'

export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL'
export const HIDE_ALERT_MODAL = 'HIDE_ALERT_MODAL'

// modal popup
export const SET_MODAL_POPUP = 'SET_MODAL_POPUP'

// account sidebar
export const SHOW_ACCOUNT_SIDEBAR = 'SHOW_ACCOUNT_SIDEBAR'
export const HIDE_ACCOUNT_SIDEBAR = 'HIDE_ACCOUNT_SIDEBAR'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'


//select complex
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_COMPLEXES_SUCCESS = 'GET_COMPLEXES_SUCCESS'
export const CLEAR_COMPLEXES_SUCCESS = 'CLEAR_COMPLEXES_SUCCESS'

export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'


//user get and set neighbourhood
export const NEIGHBOURHOOD_SUCCESS = 'NEIGHBOURHOOD_SUCCESS'

//get calendar details
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS'

// user cards
export const GET_USER_CARDS_SUCCESS = 'GET_USER_CARDS_SUCCESS'
export const ADD_USER_CARDS_SUCCESS = 'ADD_USER_CARDS_SUCCESS'

// payments
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS'

// vouchers
export const GET_SHARE_VOUCHER_SUCCESS = 'GET_SHARE_VOUCHER_SUCCESS'
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS'
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS'

export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS'

export const SET_CUSTOM_COMPLEX_SUCCESS = 'SET_CUSTOM_COMPLEX_SUCCESS'

export const LOGOUT_RESET = 'LOGOUT_RESET'

// orders sessions
export const GET_SUBSCRIPTION_BOOKINNGS_SUCCESS = 'GET_SUBSCRIPTION_BOOKINNGS_SUCCESS'
export const GET_SUBSCRIPTION_BOOKING_SUCCESS = 'GET_SUBSCRIPTION_BOOKING_SUCCESS'

// categories
export const GET_ROOT_CATEGORIES = 'GET_ROOT_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'

// products
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_OPTION = 'GET_PRODUCT_OPTION'
export const GET_LOCKBOX_ACTION = 'GET_LOCKBOX_ACTION'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'

// bookings
export const GET_ONE_TIME_BOOKINGS_SUCCESS = 'GET_ONE_TIME_BOOKINGS_SUCCESS'
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS'

// recommedantions
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS'

// path
export const GET_PATH_SUCCESS = 'GET_PATH_SUCCESS'

// marketing consent
export const GET_MARKETING_CONSENTS_SUCCESS = 'GET_MARKETING_CONSENTS_SUCCESS'

// public address
export const GET_PUBLIC_ADDRESSES_SUCCESS = 'GET_PUBLIC_ADDRESSES_SUCCESS'
export const SET_PUBLIC_ADDRESS_SUCCESS = 'SET_PUBLIC_ADDRESS_SUCCESS'

// user
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

// order details
export const SET_ORDER_DETAILS_SUCCESS = 'SET_ORDER_DETAILS_SUCCESS'


// selected calendar
export const SET_SELECTED_CALENDAR = 'SET_SELECTED_CALENDAR'

// shopping items
export const SET_PURCHASE_OPTION = 'SET_PURCHASE_OPTION'

// product form
export const GET_PRODUCT_FORM_SUCCESS = 'GET_PRODUCT_FORM_SUCCESS'

// product forms
export const GET_PRODUCT_FORMS_SUCCESS = 'GET_PRODUCT_FORMS_SUCCESS'

// subscriptions
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'

// billing data
export const GET_BILLING_DATA_SUCCESS = 'GET_BILLING_DATA_SUCCESS'

// product form details
export const SET_PRODUCT_FORM_DETAILS = 'SET_PRODUCT_FORM_DETAILS'

// product form requests
export const GET_PRODUCT_FORM_REQUESTS_SUCCESS = 'GET_PRODUCT_FORM_REQUESTS_SUCCESS'

// product form request
export const GET_PRODUCT_FORM_REQUEST_SUCCESS = 'GET_PRODUCT_FORM_REQUEST_SUCCESS'

// order details
export const SET_ORDER_REQUEST_DETAILS_SUCCESS = 'SET_ORDER_REQUEST_DETAILS_SUCCESS'

// product options
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS'
export const SET_SELECTED_PRODUCT_OPTIONS = 'SET_SELECTED_PRODUCT_OPTIONS'
export const SET_ALL_PRODUCT_OPTIONS = 'SET_ALL_PRODUCT_OPTIONS'
