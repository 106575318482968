import React from 'react';
import {useSelector} from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {colors} from "../../utils/style/style";

export const Spinner = (props) => {
    const isSpinner = useSelector(state => state.spinner)

    return (
        isSpinner ?
            <div style={styles.spinner}>
                <Loader
                    type="Oval"
                    color={colors.brand}
                    height={60}
                    width={60}
                />
            </div>
            : null
    )
}

const styles = {
    spinner: {
        position: 'fixed',
        width: '100%',
        display: 'flex',
        zIndex: 9999,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
        alignItems: 'center',
        justifyContent: 'center'
    }
}