import {translate} from "../i18n";

export const getProductOptionName = (productOption) => {
	if (productOption.title) {
		return productOption.title
	}
	return translate("product_option." + productOption.code)
}

export const getProductOptionsName = (productOptions) => {
	if (!productOptions?.length) {
		return ""
	}
	if (productOptions.length === 1) {
		return getProductOptionName(productOptions[0])
	}
	let text = ''
	productOptions.forEach((item) => {
		text += getProductOptionName(item) + ", "
	})
	return text.substring(0, text.length - 2)
}