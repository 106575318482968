import { LOGOUT_RESET, GET_BILLING_DATA_SUCCESS } from '../constants'

const initialState = {}

export const billingData = (state = initialState, action) => {
    switch (action.type) {
        case GET_BILLING_DATA_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}