import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "@iconify/react/dist/iconify";

import "../../assets/css/components/cookie.scss"
import {
	getBillingDataAction,
	showModalPopup,
	userCardsActions
} from "../../redux/actions";
import {BILLING_DATA_TYPE_COMPANY} from "../../utils/model/billingData";
import BillingDataInput from "../account/personalData/BillingDataInput";
import UserCards from "../account/userCard/UserCards";
import {colors} from "../../utils/style/style";
import {getDate} from "../../utils/datetime";
import {getStringProperty} from "../../utils/model/property";
import CheckboxField from "./input/CheckboxField";
import EditButton from "./buttons/EditButton";
import AddButtonSmall from "./buttons/AddButtonSmall";

export const OrderLastStep = ({fullAddress, property, orderGenericDetails, onSubmit, onBack}) => {
	const [isCard, setIsCard] = useState(true)
	const [isCompany, setIsCompany] = useState(false)

	const dispatch = useDispatch()

	const userCards = useSelector(state => state.userCards)
	const selectedCalendar = useSelector(state => state.selectedCalendar)

	const billingData = useSelector(state => state.billingData)
	const user = useSelector(state => state.login)

	useEffect(() => {
		dispatch(userCardsActions())
		dispatch(getBillingDataAction(user?.id, BILLING_DATA_TYPE_COMPANY))
	}, [])

	useEffect(() => {
		if (userCards?.length) {
			setIsCard(true)
		}
	}, [userCards])

	useEffect(() => {
		if (isCompany) {
			if (!billingData?.id) {
				dispatch(showModalPopup('Adauga date', <BillingDataInput/>, null, 'lg', () => setIsCompany(false)))
			}
		}
	}, [isCompany])

	const onShowModal = () => {
		if (billingData?.id) {
			dispatch(showModalPopup('Actualizeaza datele', <BillingDataInput/>, null, 'lg'))
		} else {
			dispatch(showModalPopup('Adauga date', <BillingDataInput/>, null, 'lg'))
		}
	}

	const payAction = () => {
		onSubmit(isCompany)
	}

	const onSubmitOrder = () => {
		if (userCards.length === 0) {
			setIsCard(false)
		} else {
			payAction()
		}
	}

	const navigateToCard = () => {
		dispatch(showModalPopup("Cardurile mele", <UserCards/>, null, 'lg'))
	}

	return (
		<div>
			<div>

				{/* texts */}
				<div className="mb-4">
					<div className="d-flex">
						<Icon icon="akar-icons:calendar" width={20} style={{color: colors.brand, marginRight: '4px'}}/>
						<p className="font-weight-bold color-brand">{getDate(selectedCalendar.startDate)} - {selectedCalendar.localStartTime}</p>
					</div>
					<div>
						<div className="d-flex">
							<Icon icon="bx:bx-map" width={20} style={{color: colors.brand, marginRight: '4px'}}/>
							<p className="mb-0 font-weight-bold color-brand">{fullAddress}</p>
						</div>
						<div style={{marginLeft: '24px'}}>
							<p>{getStringProperty(property)}</p>
						</div>
					</div>
				</div>

				{/* invoice */}
				<div className="mb-4">
					<CheckboxField
						colorType="dark"
						noMargin={true}
						value={isCompany}
						setValue={() => {
							setIsCompany(!isCompany)
						}}
						label={<>Persoana Juridica</>}
					/>
					{
						billingData?.id && billingData.type === BILLING_DATA_TYPE_COMPANY && isCompany
							? <div>
                                <span style={{marginRight: '0.5rem', opacity: '0.5'}}>
                                {billingData.name}
                            </span>
								<EditButton
									onClick={onShowModal}
								/>
							</div>
							: null
					}
				</div>

				{/* card */}
				<div className="cards-container mb-4">
					<div className="d-flex" style={{justifyContent: 'space-between'}}>
						{
							userCards.length > 0
								? <div className="d-flex">
									<Icon icon="fluent:payment-16-filled" width={20} color={colors.brand}
										  style={{marginRight: '0.5rem', marginBottom: '4px'}}/>
									<p>Card: ****{String(userCards[0]?.cardMask).slice(-4)}</p>
								</div>
								: null
						}
						<div onClick={navigateToCard} style={{cursor: 'pointer'}}>
							{
								userCards.length === 0
									? <AddButtonSmall
										text="Adauga card"
										className="bg-transparent"
									/>
									: <EditButton
										text="Modifica"
										className="bg-transparent"
									/>
							}
						</div>
					</div>
				</div>
			</div>

			<div>
				<button
					className="button-back"
					onClick={() => onBack()}
				>
					{"< Inapoi"}
				</button>
				<button className="button-gradient-primary" style={{width: '200px'}} onClick={onSubmitOrder}>
					Plateste {orderGenericDetails.totalPrice} {orderGenericDetails.currency}
				</button>
			</div>
			{!isCard
				&& <div className="mt-2" style={{clear: 'both'}}>
                    <span className="color-red">
                        Adauga un card pentru a putea plati
                    </span>
				</div>
			}
		</div>
	)
}
export default OrderLastStep