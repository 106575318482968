import { GET_SHARE_VOUCHER_SUCCESS, GET_VOUCHERS_SUCCESS, ADD_VOUCHER_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {
    voucherReferral: null,
    userVouchers: []
}

export const vouchers = (state = initialState, action) => {
    switch (action.type) {
        case GET_SHARE_VOUCHER_SUCCESS:
            return { ...state, voucherReferral: action.payload }
        case GET_VOUCHERS_SUCCESS:
            return { ...state, userVouchers: action.payload }
        case ADD_VOUCHER_SUCCESS:
            return { ...state, userVouchers: action.payload }
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}