import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userCardsActions} from "../../../redux/actions";
import Select from "react-select";
import {getCardDate} from "../../../utils/datetime";

export const SelectUserCard = ({onSubmit}) => {
	const [selectedUserCard, setSelectedUserCard] = useState({})
	const [selectUserCards, setSelectUserCards] = useState([])
	const userCards = useSelector(state => state.userCards)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(userCardsActions())
	}, [])

	useEffect(() => {
		if (userCards?.length) {
			setSelectUserCards(userCards.map((item) => {
				return {
					value: item.id,
					label: <div style={{display: 'flex', flexDirection: 'column'}}>
						<span>
						{item.cardMask}
						</span>
						<span style={{fontSize: '14px', opacity: '0.5'}}>
							{getCardDate(item.expirationDate)}
						</span>
					</div>,
				}
			}))
		}
	}, [userCards])

	const onClick = () => {
		if (onSubmit && selectedUserCard?.value) {
			onSubmit(selectedUserCard.value)
		}
	}

	return (
		<div>
			<Select
				options={selectUserCards}
				onChange={(val) => setSelectedUserCard(val)}
				value={selectedUserCard?.value ? selectedUserCard : null}
				classNamePrefix="select"
				className="basic-multi-select mb-3"
				placeholder="Alege card"
			/>
			<button className="button-gradient-primary" onClick={onClick}>
				Seteaza
			</button>
		</div>
	)
}
export default SelectUserCard