import {batch} from 'react-redux'

import {HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const contactActions = (message, stack, screen, params = {}) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.post('/tickets', {
			"messages": [
				{
					"data": message,
					"type": 1 // hardcodat
				}
			]
		})
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {message: 'Mesajul a fost trimis cu succes', stack, screen, params}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const contactUnauthAction = (body) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.post('/contact_forms', body)
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Mesajul a fost trimis cu succes', type: 'success'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
