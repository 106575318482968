import {batch} from 'react-redux'

import {HIDE_SPINNER, RESET_PASSWORD_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const resetPasswordActions = username => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.post('/password_request', {username})

		batch(() => {
			dispatch({type: RESET_PASSWORD_SUCCESS})
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {message: 'Am trimis un email cu instructiunile pentru a reseta parola', type: 'success'}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}