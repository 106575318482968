import React from "react";

export const colors = {
	brand: '#08306B',
	brandDerivate: '#041E41',
	brandSecondary: '#FABA0B',
	brandSecondaryDerivate: '#F9E63B',

	greyLight: '#FAFAFA',
	blueLight: 'ECF2FA',
	white: '#FFF',
	black: '#000',

	green: '#2DA771',

	red: '#FF2A23',
}
