export const containsOnlyNumbers = string => {
    return string.match(/^[0-9]+$/) != null;
}

export const isNumber = string => {
    return containsOnlyNumbers(string)
}

export const isDecimalNumber = string => {
    if (isNumber(string)) {
        return true
    }
    const tokens = string.split('.')
    return isNumber(tokens[0]) && isNumber(tokens[1])
}

export const isEmail = email => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const isPassword = password => {
    return password.length >= 8
}

export const isName = name => {
    return name.length >= 3
}

export const isPhone = phone => {
    return phone.length >= 7 && phone.length <= 15
}

export const isExpirationDate = date => {
    const currentYear = String(new Date().getFullYear()).slice(-2)
    const currentMonth = new Date().getMonth() + 1

    const month = date.split('/')[0]
    const year = date.split('/')[1]
    let isValid = month && year && Number(month) <= 12 && Number(month) >= 1 && Number(year) >= currentYear
    if (isValid && Number(year) === Number(currentYear)) {
        return Number(month) >= currentMonth
    }
    return isValid
}

export const isCardNumber = cardNumber => {
    const cleared = cardNumber.replace(/\s/g, '');
    return cleared.length === 16 && containsOnlyNumbers(cleared)
}

export const isCVC = cvc => {
    return cvc.length === 3 && containsOnlyNumbers(cvc)
}

export const isVoucherCode = voucherCode => {
    return voucherCode.length >= 5
}

export const isMessage = message => {
    return message.length >= 3
}

export const isNotEmpty = text => {
    return text.length >= 1
}