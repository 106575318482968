import React, {useEffect, useState} from 'react'

import "../../../assets/css/guest/services/services.scss"
import "../../../assets/css/guest/services/services_item_column.scss"
import {translate} from "../../../utils/i18n";
import {Link} from "react-router-dom";
import {
	ROUTE_ORDER_FAST_PRODUCT_FORM,
	ROUTE_ORDER_FORM,
	ROUTE_ORDER_STEP,
	ROUTE_SERVICE,
	ROUTE_SERVICES
} from "../../../routes/constants";
import {CATEGORY} from "../../../utils/constants/constants";
import {Col} from "react-bootstrap";
import {PRODUCT_CODE_HOME_MANAGEMENT_SUBSCRIPTION} from "../../../utils/constants/product";
import {getSVGByServiceCode} from "../../../utils/image/svgGetter";
import ServicePrice from "./ServicePrice";

export const ServiceItemColumn = ({service}) => {
	const [link, setLink] = useState('#')
	const [text, setText] = useState('Comanda serviciu')

	useEffect(() => {
		if (service.data.fixedPrice) {
			setLink(`${ROUTE_ORDER_STEP}?productId=${service.data.id}`)
		} else {
			setLink(`${ROUTE_ORDER_FAST_PRODUCT_FORM}?productId=${service.data.id}`)
		}
	}, [service])

	return (
		<Col lg={4} md={6} className="mb-5">
			<div className="service-item-column-container">
				<div className="item-image">
					{
						getSVGByServiceCode(service.data.code)
					}
				</div>

				<h4 className="font-weight-bold mt-4">
					{translate(service.type + '.' + service.data.code)}
				</h4>
				<p className="p-font-size">
					{translate('product.' + service.data.code + '.' + 'desc')}
				</p>
				<ServicePrice
					showPriceLabel={true}
					showOnlyFirstPrice={false}
					product={service.data}
				/>

				<div className="mt-4 mb-4">
					<Link to={link} className="button-gradient-primary p-font-size">
						{text}
					</Link>
				</div>

			</div>
		</Col>
	)
}
export default ServiceItemColumn