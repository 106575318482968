import {batch} from 'react-redux'
import {axiosRequest} from '../../api/axiosRequest';

import {GET_BILLING_DATA_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {showDefaultErrorAction} from "./alertModal.actions";
import {hideModalPopup} from "./modalPopup.actions";

export const getBillingDataAction = (userId, type) => async dispatch => {
    dispatch({ type: SHOW_SPINNER })
    try {
        const resp = await axiosRequest.get(`/users/${userId}/billing_datas?type=${type}`)
        const payload = resp.data?.length ? resp.data[0] : {}
        batch(() => {
            dispatch({ type: HIDE_SPINNER })
            dispatch({ type: GET_BILLING_DATA_SUCCESS, payload: payload })
        })
    } catch (err) {
        dispatch(showDefaultErrorAction(err))
    }
}

export const updateBillingDataAction = (billingDataId, body) => async dispatch => {
    dispatch({ type: SHOW_SPINNER })
    try {
        const resp = await axiosRequest.put(`/billing_datas/${billingDataId}`, body)
        const payload = resp.data
        batch(() => {
            dispatch({ type: HIDE_SPINNER })
            dispatch({ type: GET_BILLING_DATA_SUCCESS, payload: payload })
            dispatch({ type: SHOW_ALERT_MODAL, payload: { type: 'success', message: 'Date actualizate' } })
            dispatch(hideModalPopup())

        })
    } catch (err) {
        dispatch(showDefaultErrorAction(err))
    }
}

export const createBillingDataAction = (userId, body) => async dispatch => {
    dispatch({ type: SHOW_SPINNER })
    try {
        const resp = await axiosRequest.post(`/users/${userId}/billing_datas`, body)
        const payload = resp.data
        batch(() => {
            dispatch({ type: HIDE_SPINNER })
            dispatch({ type: GET_BILLING_DATA_SUCCESS, payload: payload })
            dispatch({ type: SHOW_ALERT_MODAL, payload: { type: 'success', message: 'Date de facturare create' } })
            dispatch(hideModalPopup())
        })
    } catch (err) {
        dispatch(showDefaultErrorAction(err))
    }
}