import {LOGOUT_RESET, SET_ORDER_REQUEST_DETAILS_SUCCESS} from '../constants'
import {orderRequestDetailsInitialState} from "../../utils/model/orderRequestDetails";

export const orderRequestDetails = (state = orderRequestDetailsInitialState, action) => {
	switch (action.type) {
		case SET_ORDER_REQUEST_DETAILS_SUCCESS:
			return action.payload
		case LOGOUT_RESET:
			return orderRequestDetailsInitialState
		default:
			return state
	}
}