import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {configureAnalytics} from "./utils/analytics";

import {store} from './redux/store'
import RoutesComponent from "./routes/RoutesComponent";
import {ModalAlert} from "./components/common/ModalAlert";

import "./assets/css/index.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Spinner} from "./components/common/Spinner";
import ModalPopup from "./components/common/ModalPopup";


configureAnalytics();
const rootElement = document.getElementById('root');



const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <ModalAlert />
                <Spinner/>
                <ModalPopup/>
                <Component />
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(RoutesComponent);

if (module.hot) {
    module.hot.accept('./routes/RoutesComponent', () => {
        const NextApp = require('./routes/RoutesComponent').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();