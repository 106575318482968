import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from "react-router-dom";
import {logoutAction} from "../../redux/actions";

export const Logout = () => {
    const user = useSelector(state => state.login)
    const dispatch = useDispatch()

    if (user) {
        dispatch(logoutAction())
    }

    return <Redirect to="/"/>
}
export default Logout