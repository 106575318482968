import {translate} from "../i18n";
import {getDate} from "../datetime";
import {getAddressFromListing} from "./listing";
import {ROUTE_MY_PRODUCT_FORM} from "../../routes/constants";
import {getProductName} from "./product";

export const buildProductFormModel = (productForm) => {
    return {
        type: 'productForm',
        productCode: productForm?.product?.code,
        attributes: getProductFormAttributes(productForm),
        serviceName: getProductName(productForm?.product),
        isCancelled: [3,4].includes(productForm.status),
        data: productForm,
        url: ROUTE_MY_PRODUCT_FORM,
        sortKey: productForm.createdAt,
    }
}

export const getProductFormAttributes = (productForm) => {
    const attributes = [
        {
            label: 'Descriere',
            value: productForm.description
        },
    ]
    if (productForm.clientValue) {
        attributes.push({
            label: 'Pret',
            value: productForm.clientValue
        })
    }
    attributes.push({
        label: 'Adresa',
        value: getAddressFromListing(productForm.listing)
    })
    attributes.push({
        label: 'Status',
        value: getStatusFromProductForm(productForm)
    })
    attributes.push({
        label: "Plasat la",
        value: getDate(productForm.createdAt)
    })

    return attributes
}

export const getStatusFromProductForm = (productForm) => {
    return productForm.numberOfPendingRequests
        ? translate("product_form.new_info")
        : translate('product_form.status' + productForm.status)
}
