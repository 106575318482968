import React from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import AccountData from "./AccountData";
import UpdatePassword from "./UpdatePassword";
import Addresses from "./Addresses";
import BillingData from "./BillingData";

export const PersonalDataPage = (props) => {
    return (
        <AccountTemplate title="Date personale">
            <AccountData/>
            <UpdatePassword/>
            <hr/>
            <Addresses/>
            <hr className="mt-4"/>
            <BillingData/>
        </AccountTemplate>
    )
}
export default PersonalDataPage