import React, {useEffect, useState} from "react"
import {getDate, getTime} from "../../../utils/datetime";
import {AccountTemplate} from "../template/AccountTemplate";
import {useDispatch, useSelector} from "react-redux";
import {
	changeUserCardForSubscriptionAction,
	getSubscriptionBookingAction,
	hideModalPopup,
	showModalPopup
} from "../../../redux/actions";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {translate} from "../../../utils/i18n";
import {getProductCode, getProductNameFromBooking} from "../../../utils/model/booking";
import {Icon} from "@iconify/react/dist/iconify";
import {colors} from "../../../utils/style/style";
import Booking from "./Booking";
import EditButton from "../../common/buttons/EditButton";
import SelectUserCard from "../userCard/SelectUserCard";

export const SubscriptionBookingPage = (props) => {
	const [productName, setProductName] = useState('')
	const [nextBookings, setNextBookings] = useState([])
	const [currentBooking, setCurrentBooking] = useState({})
	const [previousBookings, setPreviousBookings] = useState([])

	const subscriptionBookingId = getItemFromUrl(props.location.search, 'id', null)
	const subscriptionBooking = useSelector(state => state.subscriptionBooking)
	const dispatch = useDispatch()

	useEffect(() => {
		setNextBookings(subscriptionBooking?.closestBookings?.nextBookings)
		setCurrentBooking(subscriptionBooking?.closestBookings?.currentBooking)
		setPreviousBookings(subscriptionBooking?.closestBookings?.previousBookings)
	}, [subscriptionBooking])

	useEffect(() => {
		let booking = nextBookings?.length ? nextBookings[0] : (previousBookings?.length ? previousBookings[0] : null)
		if (booking) {
			setProductName(getProductNameFromBooking(booking))
		}
	}, [nextBookings, currentBooking])

	useEffect(() => {
		if (subscriptionBookingId) {
			dispatch(getSubscriptionBookingAction(subscriptionBookingId))
		}
	}, [subscriptionBookingId])

	const onShowBooking = (booking) => {
		dispatch(showModalPopup(productName, <Booking booking={booking} subscriptionId={subscriptionBookingId}/>))
	}

	const onChangeUserCard = () => {
		const changeCard = (userCardId) => {
			const callback = () => {
				dispatch(getSubscriptionBookingAction(subscriptionBookingId))
			}
			dispatch(hideModalPopup())
			dispatch(changeUserCardForSubscriptionAction(subscriptionBooking.subscription.id, userCardId, callback))
		}

		dispatch(showModalPopup("Modifica card", <SelectUserCard onSubmit={changeCard}/>))
	}

	const getBookingItemComponent = (booking, type) => {
		if (type === null) {
			type = booking.timeType ?? 0
		}

		let iconName = "akar-icons:check"
		let opacity = 1
		let color = colors.black
		if (type === 0) {
			opacity = 0.5
		}
		if (type === 1) {
			iconName = 'ion:hourglass-outline'
			color = colors.brandSecondary
		}

		return <div className="mb-3" style={{cursor: 'pointer'}} key={booking.id}
					onClick={() => onShowBooking(booking)}>
			<Icon icon="bx:bx-chevron-right-square" width={24}
				  style={{color: colors.brand, marginRight: '0.5rem', marginTop: '-0.25rem'}}/>
			<span style={{color: color}}>{getDate(booking.bookingDate)} - ora {getTime(booking.localStartTime)}</span>
			<Icon icon={iconName} width={24} style={{opacity: opacity, marginLeft: '0.5rem', marginTop: '-0.25rem'}}/>
		</div>
	}

	return (
		<AccountTemplate label="my_subscription" title="Abonamentul meu">

			<h4 className="title-account">
				{productName}
			</h4>
			<p className="font-weight-bold mt-3">
                <span className="font-weight-normal color-black" style={{marginRight: '0.5rem'}}>
                    Urmatoarea plata:
                </span>
				{getDate(subscriptionBooking?.subscription?.nextDueDate)}
			</p>
			<div className="mb-3 flex-item-in-list">
				<div>
					<span className="font-weight-normal color-black" style={{marginRight: '0.5rem'}}>
						Card:
					</span>
					<span className="font-weight-bold">
						{subscriptionBooking?.subscription?.userCard?.cardMask ?? '-'}
					</span>
				</div>

				<EditButton
					onClick={() => onChangeUserCard()}
					text="Modifica"
				/>

			</div>
			<p>
				Sedinte:
			</p>
			{
				nextBookings?.map(el => (
					getBookingItemComponent(el, 0)
				))
			}

			{
				currentBooking &&
				getBookingItemComponent(currentBooking, 1)
			}

			{
				previousBookings?.map(el => (
					getBookingItemComponent(el, 2)
				))
			}
		</AccountTemplate>
	)
}
export default SubscriptionBookingPage