import React, {useRef, useState} from 'react'
import {Icon} from "@iconify/react/dist/iconify";
import {Nav} from "react-bootstrap";

import "../../../assets/css/account/sidebar.scss"
import {useOutsideClickEvent} from "../../../utils/hooks/useOutsideClickEvent";
import UserImageProfile from "./UserImageProfile";
import AccountPageLinksNav from "./nav/AccountPageLinksNav";
import {ROUTE_LOGOUT, ROUTE_ORDER, ROUTE_PERSONAL_DATA} from "../../../routes/constants";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {HIDE_ACCOUNT_SIDEBAR} from "../../../redux";

export const AccountSideBar = (props) => {
	const [activeKey, setActiveKey] = useState(window.location.pathname)
	const sidebarRef = useRef(null)

	const dispatch = useDispatch()
	const onCloseMenu = () => {
		dispatch({type: HIDE_ACCOUNT_SIDEBAR})
	}
	useOutsideClickEvent(sidebarRef, onCloseMenu, [], props.isMobile)

	return (
		<Nav className="sidebar flex-column"
			 activeKey={activeKey}
			 onSelect={(selected) => setActiveKey(selected)}
			 ref={sidebarRef}
		>
			<div className="image-container">
				<UserImageProfile
					name={window.location.pathname !== ROUTE_PERSONAL_DATA}
					editable={window.location.pathname === ROUTE_PERSONAL_DATA}
				/>
				<Icon onClick={onCloseMenu} className="close-icon d-md-none" icon="ant-design:close-outlined"
					  width={15}/>
			</div>

			<div className="page-links">
				<AccountPageLinksNav/>
			</div>

			<Link to={ROUTE_ORDER} className="button-gradient-primary"
				  style={{marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem'}}>
				Comanda
			</Link>

			<div style={{position: 'absolute', bottom: '0'}}>
				<Nav.Link href={ROUTE_LOGOUT}>
					<Icon icon="clarity:logout-line" style={{marginTop: '-4px', marginRight: '8px'}}/>
					Deconectare
				</Nav.Link>
			</div>
		</Nav>
	)
}
export default AccountSideBar