import {GET_CITY_SUCCESS, LOGOUT_RESET} from '../constants'

const initialState = []

export const city = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITY_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}