import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	decreaseOrderRequestDetailsStepAction,
	postCheckoutAction,
	showDefaultErrorAction
} from "../../../../redux/actions";
import OrderLastStep from "../../../common/OrderLastStep";
import {axiosRequest} from "../../../../api/axiosRequest";

export const Step3 = ({}) => {
	const dispatch = useDispatch()

	const userCards = useSelector(state => state.userCards)
	const orderRequestDetails = useSelector(state => state.orderRequestDetails)
	const selectedCalendar = useSelector(state => state.selectedCalendar)
	const productFormRequest = useSelector(state => state.productFormRequest)
	const billingData = useSelector(state => state.billingData)

	const fullAddress = productFormRequest?.productForm?.listing?.property?.address?.fullAddress
	const property = productFormRequest?.productForm?.listing?.property ?? orderRequestDetails.property

	const updateBillingDataForListingAPI = async () => {
		const billingDataBody = {
			billingData: {
				id: billingData.id
			}
		}
		const listingId = productFormRequest?.productForm?.listing?.id
		try {
			const resp = await axiosRequest.put(`/listings/${listingId}`, billingDataBody)
			return resp.data

		} catch (err) {
			dispatch(showDefaultErrorAction(err))
			return null
		}
	}

	const updatePropertyForListingAPI = async () => {
		const propertyId = productFormRequest?.productForm?.listing?.property?.id
		try {
			const resp = await axiosRequest.put(`/properties/${propertyId}`, {...orderRequestDetails.property})
			return resp.data

		} catch (err) {
			dispatch(showDefaultErrorAction(err))
			return null
		}
	}

	const acceptUserProductFormRequestAPI = async () => {
		const productFormRequestId = productFormRequest?.id
		const startDate = selectedCalendar.startDate
		const localStartTime = selectedCalendar.localStartTime

		try {
			const resp = await axiosRequest.put(`/product_form_requests/${productFormRequestId}/user/accept`, {startDate, localStartTime})
			return resp.data
		} catch (err) {
			dispatch(showDefaultErrorAction(err))
			return null
		}
	}


	const onPayService = async (isCompany) => {
		if (isCompany) {
			const listing = await updateBillingDataForListingAPI()
			if (!listing) {
				return
			}
		}
		const property = await updatePropertyForListingAPI()
		if (!property) {
			return
		}

		const order = await acceptUserProductFormRequestAPI()
		if (!order) {
			return
		}


		dispatch(postCheckoutAction(order?.id, userCards[0]?.id))
	}

	return (
		<OrderLastStep
			fullAddress={fullAddress}
			property={property}
			orderGenericDetails={orderRequestDetails}
			onSubmit={onPayService}
			onBack={() => dispatch(decreaseOrderRequestDetailsStepAction(orderRequestDetails))}
		/>
	)
}
export default Step3