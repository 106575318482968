import {Col, Row} from "react-bootstrap";
import {Icon} from "@iconify/react/dist/iconify";
import UserImageProfile from "../UserImageProfile";
import {ROUTE_PERSONAL_DATA} from "../../../../routes/constants";
import React from "react";
import {SHOW_ACCOUNT_SIDEBAR} from "../../../../redux";
import {useDispatch} from "react-redux";
import {colors} from "../../../../utils/style/style";

export const AccountHeaderMobile = (props) => {
    const dispatch = useDispatch()

    const showMenu = () => {
        dispatch({type: SHOW_ACCOUNT_SIDEBAR})
    }

    return (
        <Row>
            <Col xs={2} style={{float: 'left', paddingTop: '10px'}}>
                <div onClick={showMenu} style={{marginLeft: '-8px'}}>
                    <Icon icon="ci:hamburger" width={30} color={colors.brand}/>
                </div>
            </Col>
            <Col xs={8} style={{marginTop: '1rem', textAlign: 'center'}}>
                <UserImageProfile
                    name={window.location.pathname !== ROUTE_PERSONAL_DATA}
                    editable={window.location.pathname === ROUTE_PERSONAL_DATA}
                />
            </Col>
        </Row>
    )
}
export default AccountHeaderMobile