import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {ROUTE_ORDER} from "../../../routes/constants";
import {translate} from "../../../utils/i18n";

import "../../../assets/css/components/breadcrumb/breadcrumb.scss"

export const OrderServiceBreadcrumb = (props) => {
    const categories = props.categories

    if (categories.length !== 1) {
        return <></>
    }

    return (
        <Breadcrumb className="mb-4">
            <Breadcrumb.Item href={ROUTE_ORDER}>Servicii</Breadcrumb.Item>

            {
                categories[0].parentCategoryCode
                ?
                    <Breadcrumb.Item href={`${ROUTE_ORDER}?categoryId=${categories[0].parentCategoryId}`}>{translate('category.' + categories[0].parentCategoryCode)}</Breadcrumb.Item>
                :null
            }
            <Breadcrumb.Item active>{translate('category.' + categories[0].code)}</Breadcrumb.Item>
        </Breadcrumb>
    )
}
export default OrderServiceBreadcrumb