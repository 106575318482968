import React, {useEffect, useRef, useState} from "react";
import zumzi_gift from "../../../assets/images/new/gift.png";
import {Button, Overlay, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getShareVoucherActions} from "../../../redux/actions";
import {getStringValue} from "../../../utils/valueType";
import "../../../assets/css/components/tooltip/tooltip.scss"

export const Reward = (props) => {
	const [bonusValue, setBonusValue] = useState('')
	const [showCopyToClipboard, setShowCopyToClipboard] = useState(false)
	const tooltipTarget = useRef(null)

	const user = useSelector(state => state.login)
	const voucherReferral = useSelector(state => state.vouchers.voucherReferral)
	const dispatch = useDispatch()

	useEffect(() => {
		if (user?.id) {
			dispatch(getShareVoucherActions(user?.id))
		}
	}, [user])

	useEffect(() => {
		if (voucherReferral?.voucher) {
			setBonusValue(getStringValue(voucherReferral?.voucher?.offer.value, voucherReferral?.voucher?.offer.valueType))
		}
	}, [voucherReferral])

	const copyToClipboard = () => {
		const message = `Hey! Descarcă aplicația Zumzi sau intra pe site-ul zumzi.com și utilizează codul meu ${voucherReferral?.voucher?.code} pentru a primi ${bonusValue} credit pentru servicii (se aplică condiții).

AppStore: https://apps.apple.com/ro/app/zumzi/id1577525960?l=ro
      
GooglePlay: https://play.google.com/store/apps/details?id=com.zumzi`

		navigator.clipboard.writeText(message)
		setShowCopyToClipboard(true)
		setTimeout(function () {
			setShowCopyToClipboard(false)
		}, 3000)
	}

	return (
		<>
			<img src={zumzi_gift} alt="zumzi gift" width={100}/>
			<p className="text-center" style={{marginTop: '1rem'}}>
				Primesti {bonusValue} pentru fiecare
				prieten pe care il recomanzi
			</p>
			<p style={{fontWeight: 'bold'}}>
				Distribuie codul tau
			</p>
			<div style={{display: 'flex'}}>
				<button className="button-cta-sec button-small" style={{fontWeight: 'bold', marginRight: '10px'}}>
					{voucherReferral?.voucher?.code}
				</button>
				<Button ref={tooltipTarget} className="button-gradient-primary button-medium" onClick={copyToClipboard}>
					Copiaza
				</Button>
				<Overlay target={tooltipTarget.current} show={showCopyToClipboard} placement="bottom">
					{(props) => (
						<Tooltip {...props}>
							Codul este copiat
						</Tooltip>
					)}
				</Overlay>
			</div>
		</>
	)
}
export default Reward