import React, {useState} from "react";
import {TYPE_AUDIO, TYPE_IMAGE, TYPE_VIDEO} from "../../../utils/constants/constants";
import FileList from "../orderForm/FileList";
import {useSelector} from "react-redux";

export const Attachments = ({onSubmit, isNewData, isLocalSave}) => {
	const [files, setFiles] = useState({
		[TYPE_IMAGE]: [],
		[TYPE_VIDEO]: [],
		[TYPE_AUDIO]: [],
	})
	const [newFiles, setNewFiles] = useState({
		[TYPE_IMAGE]: [],
		[TYPE_VIDEO]: [],
		[TYPE_AUDIO]: []
	})
	const types = [TYPE_IMAGE, TYPE_VIDEO, TYPE_AUDIO]

	const [filesHasChanged, setFilesHasChanged] = useState(false)

	const productForm = useSelector(state => state.productForm)
	const productFormDetails = useSelector(state => state.productFormDetails)

	useState(() => {
		if (productFormDetails?.files?.length) {
			setNewFiles({
				[TYPE_IMAGE]: productFormDetails.files.filter((item) => {
					return item.mediaType === TYPE_IMAGE
				}),
				[TYPE_VIDEO]: productFormDetails.files.filter((item) => {
					return item.mediaType === TYPE_VIDEO
				}),
				[TYPE_AUDIO]: productFormDetails.files.filter((item) => {
					return item.mediaType === TYPE_AUDIO
				}),
			})
		}
	}, [productFormDetails])

	useState(() => {
		if (productForm?.id) {
			setFiles({
				[TYPE_IMAGE]: productForm.listing.images,
				[TYPE_VIDEO]: productForm.listing.videos,
				[TYPE_AUDIO]: productForm.listing.audios,
			})
		}
	}, [productForm])

	const onDeleteExistingFile = (index, type) => {
		setFilesHasChanged(true)
		let updatedFiles = files[type]
		updatedFiles.splice(index, 1);
		setFiles({...files, [type]: updatedFiles})
	}

	const onDeleteNewFile = (index, type) => {
		setFilesHasChanged(true)
		let updatedFiles = newFiles[type]
		updatedFiles.splice(index, 1);
		setNewFiles({...newFiles, [type]: updatedFiles})

	}

	const onAddFile = (uploadedFiles, type) => {
		setFilesHasChanged(true)
		setNewFiles({...newFiles, [type]: newFiles[type].concat(uploadedFiles)})
	}

	const buildFilesToSend = () => {
		const filesToSend = []

		types.forEach((type) => {
			newFiles[type].forEach((file) => {
				file.mediaType = type
				filesToSend.push(file)
			})
		})

		return filesToSend
	}

	const onSubmitFiles = () => {
		const filesToSend = buildFilesToSend()
		if (productForm?.id) {
			onSubmit(files, filesToSend)
		} else {
			onSubmit(filesToSend)
		}
	}

	return <>
		<div className="d-flex">
			{
				types.map((type) => {
					return <FileList
						key={type}
						type={type}
						files={files[type]}
						newFiles={newFiles[type]}
						onDeleteExistingFile={onDeleteExistingFile}
						onDeleteNewFile={onDeleteNewFile}
						onAddFile={onAddFile}
						showIcons={true}
						showFiles={false}
					/>
				})
			}
		</div>
		{
			types.map((type) => {
				return <div className="d-flex flex-wrap align-items-center" key={type}>
					<FileList
						key={type}
						type={type}
						files={files[type]}
						newFiles={newFiles[type]}
						onDeleteExistingFile={onDeleteExistingFile}
						onDeleteNewFile={onDeleteNewFile}
						onAddFile={onAddFile}
						showIcons={false}
						showFiles={true}
					/>
				</div>
			})
		}

		<div className={"d-flex " + (isLocalSave ? "justify-content-center" : "")}>
			{
				!isNewData || filesHasChanged
					? <button
						className="button-gradient-primary mt-5 mb-5"
						onClick={onSubmitFiles}
					>
						{
							isLocalSave
								? "Continua"
								: (
									isNewData ? "Salveaza" : "Trimite"
								)
						}
					</button>
					: null
			}
		</div>
	</>
}
export default Attachments