import {batch} from 'react-redux'

import {GET_SUBSCRIPTION_BOOKING_SUCCESS, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getSubscriptionBookingAction = (subscriptionId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/subscription_bookings/${subscriptionId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_SUBSCRIPTION_BOOKING_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
