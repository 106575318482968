import { GET_CALENDAR_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {}

export const calendar = (state = initialState, action) => {
    switch (action.type) {
        case GET_CALENDAR_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}