import React, {useEffect, useState} from "react";

import "../../../assets/css/account/neighbourhood/assign_neighbourhood.scss"
import {getCitiesActions} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import zumzi_harta from "../../../assets/images/new/harta.png";
import {Col, Row} from "react-bootstrap";
import NewComplex from "./NewComplex";

export const AddNewAddress = () => {
	const [availableCities, setAvailableCities] = useState('')
	const dispatch = useDispatch()

	const cities = useSelector(state => state.selectComplex.cities)

	useEffect(() => {
		dispatch(getCitiesActions())
	}, [])


	useEffect(() => {
		let citiesStr = ''
		cities.forEach((city) => {
			if (city.availableForServices) {
				citiesStr += city.name + ", "
			}
		})
		setAvailableCities(citiesStr)
	}, [cities])

	return (
		<>
			<div className="header-container">
				<Row>
					<Col lg={6} className="left">
						<img alt="harta zumzi" src={zumzi_harta}/>
					</Col>
					<Col lg={5} className="offset-lg-1">
						<p>
							Ne gasiti in {availableCities}in viitor o sa ne extindem serviciile si catre alte orase.
						</p>
					</Col>
				</Row>
			</div>

			<div className="d-flex align-items-center flex-column d-lg-block">
				<NewComplex
					showOnlyAddress={true}/>
			</div>
		</>
	)
}
export default AddNewAddress
