import queryString from 'query-string';

export const getPathWithParams = () => {
	return window.location.pathname + window.location.search
}


export const getItemFromUrl = (url, key, empty = '') => {
	const queryObj = queryString.parseUrl(url);
	return queryObj.query[key] ?? empty;
}