import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Step1 from "./Step1";
import {AccountTemplate} from "../../template/AccountTemplate";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import {
    getProductActions,
    getProductOptionsByProduct,
    resetOrderDetailsAction, setAllProductOptions,
    setSelectedProductOptions
} from "../../../../redux/actions";
import {getItemFromUrl} from "../../../../utils/interface/queryHandler";
import {Redirect} from "react-router-dom";
import {ROUTE_ORDER_FORM} from "../../../../routes/constants";
import {GET_PRODUCT} from "../../../../redux";

import "../../../../assets/css/account/order/order_step.scss"
import OrderStepBreadcrumb from "./OrderStepBreadcrumb";

export const OrderStepPage = (props) => {
    const orderDetails = useSelector(state => state.orderDetails)
    const productId = getItemFromUrl(window.location.search, 'productId')
    const product = useSelector(state => state.product)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetOrderDetailsAction())
        dispatch({type: GET_PRODUCT, payload: {}})

        dispatch(setSelectedProductOptions([]))
        dispatch(setAllProductOptions([]))
    }, [])

    useEffect(() => {
        if (productId) {
            dispatch(getProductActions(productId))
            dispatch(getProductOptionsByProduct(productId))
        }
    }, [productId])


    if (product) {
        if (product.fixedPrice === false) {
            return <Redirect to={`${ROUTE_ORDER_FORM}?productId=${product.id}`}/>
        }
    }

    return (
        <AccountTemplate title="Comanda">
            <OrderStepBreadcrumb/>

            <div className="account-medium-container">
                {
                    orderDetails.currentStep === 1 && <Step1/>
                }
                {
                    orderDetails.currentStep === 2 && <Step2/>
                }
                {
                    orderDetails.currentStep === 3 && <Step3/>
                }
                {
                    orderDetails.currentStep === 4 && <Step4/>
                }
                {
                    orderDetails.currentStep === 5 && <Step5/>
                }
            </div>
        </AccountTemplate>
    )
}
export default OrderStepPage