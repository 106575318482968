import {LOGOUT_RESET, SET_SELECTED_CALENDAR} from '../constants'

const initialState = {
    startDate: '',
    localStartTime: '',
    localTimeZone: 'Europe/Bucharest',
}

export const selectedCalendar = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_CALENDAR:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}