import React, {useEffect, useState} from "react";
import {postCheckoutAction, userCardsActions} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {getCardDate} from "../../../utils/datetime";
import Form from 'react-bootstrap/Form'

export const Checkout = (props) => {
    const dispatch = useDispatch()
    const [selectedCardId, setSelectedCardId] = useState(null)

    const order = props.order
    const userCards = useSelector(state => state.userCards)

    useEffect(() => {
        dispatch(userCardsActions())
    }, [])

    useEffect(() => {
        if (userCards.length) {
            setSelectedCardId(userCards[0].id)
        }
    }, [userCards])

    const onSubmitCheckout = async () => {
        dispatch(postCheckoutAction(order.id, selectedCardId))
    }

    return (
        <>
            <Form.Label className="font-weight-normal">
                Alege card
            </Form.Label>
            <Form.Select
                onChange={(e) => {setSelectedCardId(e.target.value)}}
            >
                {
                    userCards.map((card) => {
                        return <option value={card.id} key={card.id}>
                            {card.cardMask} - {getCardDate(card.expirationDate)}
                        </option>
                    })
                }
            </Form.Select>
            <Form.Label className="font-weight-normal mt-4">
                Suma: {order.value} {order.currency}
            </Form.Label>
            <button className="button-gradient-primary mt-4" onClick={onSubmitCheckout}>
                Plateste
            </button>
        </>
    )
}
export default Checkout