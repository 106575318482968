import InputField from "../../common/input/InputField";
import CheckboxField from "../../common/input/CheckboxField";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isEmail, isMessage, isName, isPhone} from "../../../utils/validation/conditions";
import {translate} from "../../../utils/i18n";
import {isValidObject} from "../../../utils/validation/validate";
import {contactUnauthAction} from "../../../redux/actions";
import React, {useState} from "react";
import {useDispatch} from "react-redux";

export const ContactForm = () => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')
	const [acceptedTerms, setAcceptedTerms] = useState(false)

	const validations = {
		name: useInputValidation(name, isName(name), translate('validation.name.invalid')),
		email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
		phone: useInputValidation(phone, isPhone(phone), translate('validation.phone.invalid')),
		message: useInputValidation(message, isMessage(message), translate('validation.message.invalid')),
		acceptedTerms: useInputValidation(acceptedTerms, acceptedTerms === true, translate('validation.terms.invalid'))
	}

	const dispatch = useDispatch()

	const onSubmitForm = async () => {
		if (isValidObject(validations)) {
			dispatch(contactUnauthAction({email, name, phone, message}))
		}
	}

	return (
		<div className="home-form">
			<h1 className="color-white">
				Devino partener Zumzi
			</h1>
			<p className="color-white mb-4">
				Serviciul de Property management oferă proprietarilor și dezvoltatorilor imobiliari venituri mai mari prin închiriere pe termen scurt și management al oaspeților și locațiilor fără bătai de cap.
			</p>

			<InputField
				type="text"
				placeholder="Nume si prenume"
				value={name}
				setValue={setName}
				validation={validations.name}
			/>
			<InputField
				type="email"
				placeholder="Adresa de email"
				value={email}
				setValue={setEmail}
				validation={validations.email}
			/>
			<InputField
				type="text"
				placeholder="Numar de telefon"
				value={phone}
				setValue={setPhone}
				validation={validations.phone}
			/>
			<InputField
				label=""
				type="text"
				isTextarea={true}
				rows={3}
				placeholder="Detalii despre locatiile tale: adresa, numar camere, stare etc."
				value={message}
				setValue={setMessage}
				validation={validations.message}
				onSubmitForm={onSubmitForm}
			/>
			<CheckboxField
				id="acceptedTerms"
				value={acceptedTerms}
				setValue={() => {
					setAcceptedTerms(!acceptedTerms)
				}}
				label={<span className="color-white">Accept termenii si conditiile</span>}
				validation={validations.acceptedTerms}
			/>
			<button onClick={onSubmitForm} className="button-cta-sec mt-3 p-font-size d-block w-100">
				Trimite mesaj
			</button>
		</div>
	)
}
