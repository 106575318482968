export const ANDROID_URL = "https://play.google.com/store/apps/details?id=com.zumzi";
export const IOS_URL = "https://apps.apple.com/app/id1577525960";

export const getDownloadAppLink = () => {
	if (isIos()) {
		return IOS_URL
	}
	return ANDROID_URL
}

export const isIos = () => {
	return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}