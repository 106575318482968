import {batch} from 'react-redux'

import {GET_PRODUCTS_SUCCESS, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getProductsAction = (toShowSpinner = true) => async dispatch => {
	if (toShowSpinner) {
		dispatch({type: SHOW_SPINNER})
	}
	try {
		const resp = await axiosRequest.get(`/products?phisical=0&type=4`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCTS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
