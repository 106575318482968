import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ProfilePicture from "./ProfilePicture";
import {updateProfileImageAction} from "../../../redux/actions";
import EditButton from "../../common/buttons/EditButton";

export const EditProfilePicture = (props) => {
    const user = useSelector(state => state.login)

    const dispatch = useDispatch()

    const onUploadFile = (e) => {
        dispatch(updateProfileImageAction(user.id, e))
    }

    return (
        <>
            {
                !props.hideImage
                && <ProfilePicture/>
            }

            <label htmlFor="file-input" style={{marginTop: '10px'}}>
                <EditButton/>
            </label>

            <input type="file"
                   onChange={onUploadFile}
                   accept="image/png, image/jpg"
                   id="file-input"
                   style={{display: 'none'}}
            />

        </>
    )
}
export default EditProfilePicture