import {batch} from 'react-redux'

import {GET_USER_CARDS_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const userCardsActions = () => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get('/user_cards?paymentMethod.type=stripe_cleantech')
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_USER_CARDS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const addCardActions = (cardNumber, cardExpMonth, cardExpYear, cardCvc) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	let payment_method = undefined
	// stripe payment methods
	try {
		const responseFetch = await fetch('https://api.stripe.com/v1/payment_methods', {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${window.REACT_APP_STRIPE_PK}`,
				'Content-Type': 'application/x-www-form-urlencoded',
				'Accept': 'application/json'
			},
			body: `type=card&card[number]=${cardNumber}&card[exp_month]=${cardExpMonth}&card[exp_year]=${cardExpYear}&card[cvc]=${cardCvc}`
		})
		const dataJson = await responseFetch.json()
		if (dataJson?.error?.code === 'incorrect_number') {
			batch(() => {
				dispatch({type: HIDE_SPINNER})
				dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Numarul cardului este incorect'}})
			})
		} else {
			payment_method = dataJson?.id
		}
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
	// add card
	if (payment_method) {
		try {
			const resp = await axiosRequest.post('/stripe_customers', {payment_method})
			if (resp.status === 201) {
				dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Card adaugat cu succes'}})
				dispatch(userCardsActions())
			}
		} catch (err) {
			if (err?.response?.status === 400) {
				batch(() => {
					dispatch({type: HIDE_SPINNER})
					dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Cardul introdus este deja adaugat'}})
				})
			} else {
				dispatch(showDefaultErrorAction(err))
			}
		}
	}
}

export const updateCardActions = cardId => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/users_cards/${cardId}/current`, {})
		const resp = await axiosRequest.get('/user_cards?paymentMethod.type=stripe_cleantech')
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_USER_CARDS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Card curent actualizat'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const deleteUserCardAction = cardId => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.delete(`/user_cards/${cardId}`)
		const resp = await axiosRequest.get('/user_cards?paymentMethod.type=stripe_cleantech')
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_USER_CARDS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Card sters cu succes'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}