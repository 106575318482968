import React from "react";
import {useSelector} from "react-redux";
import EditProfilePicture from "../personalData/EditProfilePicture";

export const UserImageProfile = (props) => {
    const user = useSelector(state => state.login)
    const width = props.width ?? '56px'

    return (
        <div className="text-center">
            <img src={user.image} alt="profil" style={{width: width, borderRadius: '100%'}}/>
            {
                props.name
                && <p className="text-center color-blue mt-1 mb-3">
                    {user.name}
                </p>
            }
            {
                props.editable
                && <p className="text-center">
                    <EditProfilePicture
                        hideImage={true}
                    />
                </p>
            }
        </div>
    )
}
export default UserImageProfile