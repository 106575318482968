import {SET_ORDER_DETAILS_SUCCESS} from "../constants";
import {orderDetailsInitialState} from "../../utils/model/orderDetails";

export const increaseOrderDetailsStepAction = (orderDetails, stepSize = 1) => async dispatch => {
    dispatch({ type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, currentStep: orderDetails.currentStep + stepSize} })
}

export const decreaseOrderDetailsStepAction = (orderDetails, stepSize = 1) => async dispatch => {
    dispatch({ type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, currentStep: orderDetails.currentStep - stepSize} })
}

export const goToStepAction = (orderDetails, step) => async dispatch => {
    dispatch({ type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, currentStep: step} })
}

export const setOrderDetailsPropertyAction = (orderDetails, property) => async dispatch => {
    dispatch({ type: SET_ORDER_DETAILS_SUCCESS, payload: {...orderDetails, property} })
}

export const resetOrderDetailsAction = () => async dispatch => {
    dispatch({ type: SET_ORDER_DETAILS_SUCCESS, payload: orderDetailsInitialState })
}
