import React from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import AddNewAddress from "./AddNewAddress";

export const AddNewAddressPage = (props) => {
	return (
		<AccountTemplate>
			<AddNewAddress/>
		</AccountTemplate>
	)
}
export default AddNewAddressPage