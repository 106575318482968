import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isCardNumber, isCVC, isExpirationDate} from "../../../utils/validation/conditions";
import {translate} from "../../../utils/i18n";
import {isValidObject} from "../../../utils/validation/validate";
import InputField from "../../common/input/InputField";

export const AddUserCard = (props) => {
	const [cardNumber, setCardNumber] = useState('')
	const [expirationDate, setExpirationDate] = useState('')
	const [cvc, setCvc] = useState('')

	const validations = {
		cardNumber: useInputValidation(cardNumber, isCardNumber(cardNumber), translate('validation.card_number.invalid')),
		expirationDate: useInputValidation(expirationDate, isExpirationDate(expirationDate), translate('validation.expiration_date.invalid')),
		cvc: useInputValidation(cvc, isCVC(cvc), translate('validation.cvc.invalid')),

	}

	const onChangeExpirationDate = value => {
		let previousValue = expirationDate
		let currentValue = value
		let updatedValue = value
		if (currentValue.length === 2) updatedValue = currentValue + '/'
		if (previousValue.length === 3 && currentValue.length === 2) updatedValue = currentValue.slice(0, -1)
		setExpirationDate(updatedValue)
	}

	const onChangeCardName = value => {
		let previousValue = cardNumber
		let currentValue = value
		let updatedValue = value
		if (currentValue.length === 4 || currentValue.length === 9 || currentValue.length === 14) {
			updatedValue = currentValue + " "
		}
		if ((previousValue.length === 5 && currentValue.length === 4) ||
			(previousValue.length === 10 && currentValue.length === 9) ||
			(previousValue.length === 15 && currentValue.length === 14)
		) {
			updatedValue = currentValue.slice(0, -1)
		}
		const spaceIndexes = [4, 9, 14]
		spaceIndexes.forEach((index) => {
			if (updatedValue.length >= index + 1) {
				if (updatedValue[index] !== " ") {
					updatedValue = updatedValue.substring(0, index) + " " + updatedValue.substring(index, updatedValue.length)
				}
			}
		})


		setCardNumber(updatedValue)
	}


	const onSubmit = () => {
		if (isValidObject(validations)) {
			props.addCard(cardNumber.replace(/\s/g, ''), expirationDate.split('/')[0], expirationDate.split('/')[1], cvc)
		}
	}

	return (
		<div>
			<InputField
				label="Adauga cod"
				labelClassName="font-weight-normal"
				className="input-small"
				type="text"
				placeholder={translate('global.placeholder.card')}
				value={cardNumber}
				setValue={onChangeCardName}
				validation={validations.cardNumber}
				maxLength={19}
			/>

			<div className="d-flex">
				<InputField
					label="Data expirare"
					labelClassName="font-weight-normal"
					className="input-tiny"
					type="text"
					placeholder={translate('global.placeholder.card_expired_date')}
					value={expirationDate}
					setValue={onChangeExpirationDate}
					validation={validations.expirationDate}
					maxLength={5}
				/>
				<div style={{marginLeft: '1rem'}}>
					<InputField
						label="CVC"
						labelClassName="font-weight-normal"
						className="input-tiny"
						type="text"
						placeholder={translate('global.placeholder.card_cvc')}
						value={cvc}
						setValue={setCvc}
						validation={validations.cvc}
						maxLength={3}
					/>
				</div>

			</div>


			<Button className="button-gradient-primary" onClick={() => onSubmit()}>
				Adauga
			</Button>
		</div>
	)
}
export default AddUserCard