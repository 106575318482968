import React from "react";
import {Button} from "react-bootstrap";
import {TYPE_IMAGE, TYPE_VIDEO} from "../../../utils/constants/constants";
import {Icon} from "@iconify/react/dist/iconify";

import "../../../assets/css/components/files/file_item.scss"

export const FileItem = (props) => {
    return <>
        <div className="file-container">
                {
                    props.type === TYPE_IMAGE
                        ? <img src={props.fileUrl} alt=""/>
                        : (
                            props.type === TYPE_VIDEO
                                ? <video className="rounded" controls>
                                    {
                                        props.mimeType === 'video/quicktime'
                                            ? <source src={props.fileUrl}/>
                                            : <source src={props.fileUrl} type={props.mimeType}/>
                                    }
                                </video>
                                : <audio controls>
                                    <source src={props.fileUrl} type={props.mimeType}/>
                                </audio>
                        )
                }

                <button className="button-close" onClick={(e) => props.deleteFile(props.index)}>
                    <Icon icon="ant-design:close-outlined" width={14}/>
                </button>
        </div>
    </>
}
export default FileItem