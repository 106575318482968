import {batch} from 'react-redux'

import {HIDE_SPINNER, LOGIN_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {saveToLocalStorage} from "../../utils/storage";
import {trackEvent} from "../../utils/analytics";

export const registerActions = (name, email, phone, password, keepSpinnerShowing = false) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.post('/register', {name, email, phone, password})
		const data = resp.data
		const payload = {
			id: data.id,
			name: data.name,
			email: data.email,
			phone: data.phone,
			image: data.image,
			token: resp.headers['x-api-key']
		}
		trackEvent('fb_mobile_complete_registration')
		batch(() => {
			if (!keepSpinnerShowing) {
				dispatch({type: LOGIN_SUCCESS, payload})
				dispatch({type: HIDE_SPINNER})
				dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: "Bine ai venit in contul Zumzi"}})
			}
		})
		await saveToLocalStorage('user', payload, 60 * 24 * 365);
	} catch (err) {
		const errMessage = err?.response?.data?.messages[0]

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'error', message: errMessage}})
		})
	}
}