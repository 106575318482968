import React, {useEffect} from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import {useDispatch, useSelector} from "react-redux";
import {getBookingAction} from "../../../redux/actions";

import "../../../assets/css/account/my_orders/my_orders.scss"
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import Booking from "./Booking";
import {getProductCode, getProductNameFromBooking} from "../../../utils/model/booking";

export const BookingPage = (props) => {
	const bookingId = getItemFromUrl(window.location.search, 'id', null)
	const toSetFeedback = getItemFromUrl(window.location.search, 'toSetFeedback', null)
	const booking = useSelector(state => state.booking)
	const dispatch = useDispatch()

	useEffect(() => {
		if (bookingId) {
			dispatch(getBookingAction(bookingId))
		}
	}, [bookingId])

	return (
		<AccountTemplate label="my_booking" title="Sedinta">

			{
				booking?.id
					? <>
						<h4 className="account-title">
							{getProductNameFromBooking(booking)}
						</h4>
						<Booking booking={booking} toSetFeedback={toSetFeedback}/>
					</>
					: null
			}

		</AccountTemplate>
	)
}
export default BookingPage