import React, {useEffect} from 'react';
import GuestTemplate from "../GuestTemplate";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row} from "react-bootstrap";
import ServicesList from "./ServicesList";
import {getProductsAction} from "../../../redux/actions";

export const ServicesPage = (props) => {
	const dispatch = useDispatch()

	const products = useSelector(state => state.products)

	useEffect(() => {
		dispatch(getProductsAction(false))
	}, [])


	return (
		<GuestTemplate label="services" title="Servicii Zumzi"
					   subtitle="Am pregatit o lista de servicii pentru a avea grija de casa ta"
					   image={null}
		>
			<Container className="mb-5">

				<Row>
					{
						products?.length
							? <ServicesList
								type="column"
								categories={[
									{
										products: products,
										subcategories: [],
									}
								]}
							/>
							: null
					}

				</Row>
			</Container>
		</GuestTemplate>
	)
}
export default ServicesPage