import React from "react";
import GuestTemplate from "../guest/GuestTemplate";
import no_data from '../../assets/images/app/common/no_data_available.svg'

export const PageNotFound = (props) => {
	return (
		<GuestTemplate image={no_data} title="Eroare 404" subtitle="Ne pare rau! Aceasta pagina nu mai exista">
		</GuestTemplate>
	)
}
export default PageNotFound