import React, {useRef, useState} from 'react'
import {Nav} from "react-bootstrap";
import {Icon} from "@iconify/react";
import PageLinkNav from "./PageLinkNav";
import ButtonNav from "./ButtonNav";
import IconsNav from "./IconsNav";
import {colors} from "../../../utils/style/style";
import {useOutsideClickEvent} from "../../../utils/hooks/useOutsideClickEvent";
import zumzi_logo from "../../../assets/images/logo_zumzi_text.png";
import {Link} from "react-router-dom";
import {ROUTE_HOME} from "../../../routes/constants";

export const SideBar = (props) => {
	const [activeKey, setActiveKey] = useState(window.location.pathname)
	const sidebarRef = useRef(null)
	useOutsideClickEvent(sidebarRef, props.toggleMenu)

	return (
		<Nav className="sidebar flex-column"
			 activeKey={activeKey}
			 onSelect={(selected) => setActiveKey(selected)}
			 ref={sidebarRef}
		>
			<div className="headerContainer">
				<Link to={ROUTE_HOME}>
					<img src={zumzi_logo} alt="logo zumzi" style={{height: '60px', marginLeft: '1.75rem'}}/>
				</Link>
				<div className="closeContainer">
					<Icon onClick={props.toggleMenu} icon="ant-design:close-outlined" width={15}/>
				</div>
			</div>

			<PageLinkNav/>
			<hr style={{margin: '2rem 0 2rem 0'}}/>
			<ButtonNav
				style={{
					marginLeft: "2rem",
				}}
				className="color-brand"
			/>

			<div className="iconContainer">
				<IconsNav
					color={colors.brand}
				/>
			</div>
		</Nav>
	)
}

export default SideBar