import React from 'react'
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_PARTNER, ROUTE_PARTNER_APP} from "../../../routes/constants";

export const ButtonNav = ({style, className = null, hideSecond}) => {
	const user = useSelector(state => state.login)

	return <Nav>
		<a href={ROUTE_PARTNER_APP} className={"nav-link nav-link-hover mr-2 " + className ?? ""}
			  style={{...style, marginLeft: 0}}>
			Devino Partener
		</a>

		{
			!hideSecond
				? (
					user?.token
						?
						<Link to={ROUTE_DASHBOARD} className="nav-link nav-link-border" style={style}>
							Contul meu
						</Link>
						: <Link to={ROUTE_LOGIN} className="nav-link nav-link-border" style={style}>
							Autentificare
						</Link>
				)
				: null
		}

	</Nav>
}

export default ButtonNav