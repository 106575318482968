import React, {useEffect} from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {useDispatch, useSelector} from "react-redux";


import "../../../assets/css/components/breadcrumb/breadcrumb.scss"
import {goToStepOrderRequestAction, increaseOrderRequestDetailsStepAction} from "../../../redux/actions";

export const OrderRequestStepBreadcrumb = (props) => {
	const orderRequestDetails = useSelector(state => state.orderRequestDetails)

	const titles = ['Adresa', 'Data', 'Finalizare']
	const dispatch = useDispatch()

	return (
		<Breadcrumb className="mb-4">
			{
				titles.map((title, index) => {
					const props = {key: index}
					if (index === orderRequestDetails.currentStep - 1) {
						props.active = true
						props.className = "font-weight-bold color-brand"
					} else if (index > orderRequestDetails.currentStep - 1) {
						props.active = true
					} else {
						props.onClick = () => {
							dispatch(goToStepOrderRequestAction(orderRequestDetails, index + 1))
						}
					}
					return <Breadcrumb.Item {...props}>{title}</Breadcrumb.Item>
				})
			}
		</Breadcrumb>
	)
}
export default OrderRequestStepBreadcrumb