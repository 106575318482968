import React from "react"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {useDispatch, useSelector} from "react-redux";


import "../../../../assets/css/components/breadcrumb/breadcrumb.scss"
import {goToStepAction} from "../../../../redux/actions";

export const OrderStepBreadcrumb = (props) => {
	const orderDetails = useSelector(state => state.orderDetails)
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)

	const titles = ['Detalii', 'Adresa', 'Data', 'Sumar', 'Finalizare']
	const dispatch = useDispatch()

	return (
		<Breadcrumb className="mb-4">
			{
				titles.map((title, index) => {
					if (index === 1 && userNeighbourhood?.userPublicAddress?.address?.property) {
						return null
					}
					const props = {key: index}
					if (index === orderDetails.currentStep - 1) {
						props.active = true
						props.className = "font-weight-bold color-brand"
					} else if (index > orderDetails.currentStep - 1) {
						props.active = true
					} else {
						props.onClick = () => {
							dispatch(goToStepAction(orderDetails, index + 1))
						}
					}
					return <Breadcrumb.Item {...props}>{title}</Breadcrumb.Item>
				})
			}
		</Breadcrumb>
	)
}
export default OrderStepBreadcrumb