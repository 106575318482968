import {batch} from 'react-redux'

import {HIDE_SPINNER, NEIGHBOURHOOD_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {ROUTE_DASHBOARD} from "../../routes/constants";
import {showDefaultErrorAction} from "./alertModal.actions";

export const getUserNeighbourhoodActions = (userId, keepSpinnerShowing = false) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/users/${userId}/neighbourhood/current`)
		let payload = resp.data
		batch(() => {
			dispatch({type: NEIGHBOURHOOD_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const setUserNeighbourhoodActions = (userId, complexId, route = ROUTE_DASHBOARD) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/users/${userId}/public_addresses/${complexId}`, {})
		await dispatch(getUserNeighbourhoodActions(userId))

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			const payloadAlert = {type: 'success', message: 'Adresa adaugata cu succes', route: route}
			if (!route) {
				payloadAlert.message = 'Adresa curenta modificata'
			}
			dispatch({type: SHOW_ALERT_MODAL, payload: payloadAlert})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}