import React from "react";
import {useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {ROUTE_DASHBOARD, ROUTE_HOME} from "../../routes/constants";
import {LAST_AUTH_PATH} from "../../utils/constants/constants";
import {loadFromLocalStorage, saveToLocalStorage} from "../../utils/storage";
import {Col, Container, Row} from "react-bootstrap";

import logo_zumzi from "../../assets/images/logo_zumzi_text.png";

import "../../assets/css/auth/auth.scss"


export const AuthTemplate = ({title, subtitle, children, containerSize = 4, fixedContainer = false}) => {
	const user = useSelector(state => state.login)
	const offsetContainerSize = containerSize === 4 ? 4 : (containerSize === 6 ? 3 : 2)

	if (user?.token) {
		const lastPath = loadFromLocalStorage(LAST_AUTH_PATH)
		if (lastPath) {
			saveToLocalStorage(LAST_AUTH_PATH, null)
			return <Redirect to={lastPath}/>
		}
		return <Redirect to={ROUTE_DASHBOARD}/>
	}
	return (
		<div className="guest-background">
			<div className="auth-container-logo">
				<Link to={ROUTE_HOME}>
					<img src={logo_zumzi} alt="logo zumzi"/>
				</Link>
			</div>

			<div className="vertical-center">
				<Container>
					<Row>
						<Col lg={containerSize} className={"offset-lg-" + offsetContainerSize}>
							<div className={"auth-container-form " + (fixedContainer ? "fixed-container" : "")}>
								<div className="auth-container-inner">
									<h4>
										{title}
									</h4>
									<p>
										{subtitle}
									</p>
									{children}
								</div>

							</div>
						</Col>
					</Row>

				</Container>
			</div>

		</div>
	)
}
