import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {registerActions} from "../../redux/actions";
import {useInputValidation} from "../../utils/hooks/useInputValidation";
import {isEmail, isName, isPassword, isPhone} from "../../utils/validation/conditions";
import {translate} from "../../utils/i18n";
import {isValidObject} from "../../utils/validation/validate";
import {FormGroup} from "react-bootstrap";
import InputField from "../common/input/InputField";
import {Link} from "react-router-dom";
import {ROUTE_LOGIN, ROUTE_RESET_PASSWORD, ROUTE_TERMS_AND_CONDITIONS} from "../../routes/constants";
import CheckboxField from "../common/input/CheckboxField";
import {setProductFormPublicUserAction} from "../../redux/actions/productFormDetails.actions";

export const Register = ({isLocalSave, backCallback}) => {
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [acceptedTerms, setAcceptedTerms] = useState(false)

	const productFormDetails = useSelector(state => state.productFormDetails)

	const validations = {
		email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
		name: useInputValidation(name, isName(name), translate('validation.name.invalid')),
		phone: useInputValidation(phone, isPhone(phone), translate('validation.phone.invalid')),
		password: useInputValidation(password, isPassword(password), translate('validation.password.invalid')),
		acceptedTerms: useInputValidation(acceptedTerms, acceptedTerms === true, translate('validation.terms.invalid'))
	}

	const dispatch = useDispatch()

	const onSubmitRegister = async () => {
		if (isValidObject(validations)) {
			if (isLocalSave) {
				await dispatch(setProductFormPublicUserAction(productFormDetails, name, email, phone, password))
			} else {
				dispatch(registerActions(name, email, phone, password))
			}
		}
	}

	return (
		<>
			<FormGroup>
				<InputField
					className="w-100"
					type="text"
					placeholder="Nume si Prenume"
					icon="user"
					value={name}
					setValue={setName}
					validation={validations.name}
				/>
				<InputField
					className="w-100"
					type="email"
					placeholder="Email"
					icon="email"
					value={email}
					setValue={setEmail}
					validation={validations.email}
				/>
				<InputField
					className="w-100"
					type="text"
					placeholder="Telefon"
					icon="phone"
					value={phone}
					setValue={setPhone}
					validation={validations.phone}
				/>
				<InputField
					className="w-100"
					type="password"
					placeholder={translate('global.placeholder.password')}
					icon="password"
					value={password}
					setValue={setPassword}
					validation={validations.password}
					onSubmitForm={onSubmitRegister}
				/>
			</FormGroup>
			<CheckboxField
				id="acceptedTerms"
				value={acceptedTerms}
				colorType='dark'
				setValue={() => {
					setAcceptedTerms(!acceptedTerms)
				}}
				extraClassName="text-left"
				label={
					<div>
						<p>Accept <Link to={ROUTE_TERMS_AND_CONDITIONS}>{" "}termenii si conditiile</Link></p>
					</div>
				}
				validation={validations.acceptedTerms}
			/>
			{
				!isLocalSave
					? <div className="auth-extra-links">
						<p>
							Ai deja cont?{" "}
							<Link to={ROUTE_LOGIN}>
								Autentifica-te
							</Link>
						</p>
						<Link to={ROUTE_RESET_PASSWORD}>
							Ai uitat parola?
						</Link>
					</div>
					: null
			}

			<div className="d-flex justify-content-center">
				{
					backCallback
						?
						<button className="button-back" onClick={backCallback}>
							{"<"}Inapoi
						</button>

						: null
				}
				<button className="button-gradient-primary" onClick={onSubmitRegister}>
					{isLocalSave ? "Trimite" : "Conectare"}
				</button>

			</div>

			{
				!isLocalSave
					? <div className="text-center">
						<p className="d-lg-none mt-3">
							Ai deja cont?{" "}
							<Link to={ROUTE_LOGIN}>
								Autentifica-te
							</Link>
						</p>
					</div>
					: null
			}

		</>
	)
}
export default Register;