import React, {useEffect, useState} from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {Icon} from "@iconify/react/dist/iconify";
import InputEditableDisabled from "./InputEditableDisabled";


const textToIcon = {
	"email": "ci:mail",
	"password": "clarity:eye-hide-line",
	"user": "bytesize:user",
	"phone": "ant-design:phone-outlined",
}
export const InputField = ({
							   type, label, labelClassName, maxLength, placeholder, value, setValue,
							   validation, disabledObject, disabled, toggleDisabledInput, onCloseEdit,
							   isTextarea, rows, icon, className,
							   onSubmitForm, onUpdate
						   }) => {
	const [toShowPassword, setToShowPassword] = useState(false)
	const [iconName, setIconName] = useState(null)

	const onPressIcon = () => {
		if (type === "password") {
			setToShowPassword(!toShowPassword)
		}
	}

	const onKeyUp = (event) => {
		if (event.key.toLowerCase() === 'enter') {
			if (onUpdate) {
				onUpdate()
			}
			if (onSubmitForm) {
				onSubmitForm()
			}
		}

	}

	useEffect(() => {
		if (icon) {
			setIconName(textToIcon[icon] ?? null)
		}
	}, [])

	return (
		<Form.Group className={className ?? "w-100"}>
			{
				label
					? <Form.Label
						className={labelClassName}
					>{label}</Form.Label>
					: null
			}
			{
				onUpdate && disabledObject
					? <InputEditableDisabled
						value={value === '' ? '-' : value}
						onClick={toggleDisabledInput}
					/>
					: <InputGroup hasValidation={!!validation} className="input-group-flex mb-4">
						<div className="my-input-control">
							<Form.Control
								type={type !== "password" ? type : (toShowPassword ? "text" : "password")}
								as={isTextarea ? "textarea" : "input"}
								rows={rows ?? 1}
								placeholder={placeholder ?? ''}
								className={"input-text " + (icon ? "padding-icon-right" : "")}
								value={value}
								onChange={(e) => setValue(e.target.value)}
								isInvalid={validation && !validation.isValid()}
								disabled={disabled}
								maxLength={maxLength ?? null}
								onKeyUp={onKeyUp}
							/>
							{
								icon
								&& <div onClick={onPressIcon}>
									{
										iconName
											? <>
												<Icon
													icon={iconName}
													className="input-icon"
												/>
											</>
											: <span className="input-right-text">{icon}</span>
									}

								</div>
							}
							{
								validation
								&& <Form.Control.Feedback type="invalid">
									{validation.inputError}
								</Form.Control.Feedback>
							}
						</div>


						{
							onUpdate
								? <div className="input-group-save-button">
                                    <span className="button-medium button-small-cta account-save" onClick={onUpdate}>
                                        Salveaza
                                    </span>
									<Icon icon="ant-design:close-outlined" onClick={onCloseEdit}/>
								</div>
								: null
						}
					</InputGroup>
			}

		</Form.Group>
	)
}
export default InputField