import { SHOW_SPINNER, HIDE_SPINNER, LOGOUT_RESET } from '../constants'

const initialState = false

export const spinner = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SPINNER:
            return true
        case HIDE_SPINNER:
            return false
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}