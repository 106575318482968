import React from "react";
import {useWindowResize} from "../../utils/hooks/useWindowResize";
import {Col, Row, Table} from "react-bootstrap";

import "../../assets/css/components/table/table.scss"

export const SimpleTable = (props) => {
    const {width} = useWindowResize()

    const {headers, entries} = props

    if (!entries?.length) {
        return <></>
    }
    if (width <= 767) {
        return (<>
            {
                entries.map((entry, key) => (
                    <Row key={key} className="table-entry">
                        <Col xs={4} className="table-titles">
                            {
                                entry.map((item, index) => {
                                    return <p key={index}>
                                        {headers[index].value}
                                    </p>
                                })
                            }
                        </Col>
                        <Col xs={8} className="table-values">
                            {
                                entry.map((item, key) => {
                                    return <p key={key}>
                                        {item.value}
                                    </p>
                                })
                            }
                        </Col>
                    </Row>
                ))
            }

        </>)
    }


    return (
        <Table borderless={true} className="simple-table">
            <thead>
                <tr>
                    {
                        headers.map((header, key) => {
                            return <th key={key} className={header.className}>
                                {header.value}
                            </th>
                        })
                    }
                </tr>
            </thead>
            <tbody>
            {
                entries.map((entry, index) => (
                    <tr key={index}>
                        {
                            entry.map((item, index) => {
                                return <td key={index} className={item.className}>
                                    {item.value}
                                </td>
                            })
                        }
                    </tr>
                ))
            }
            </tbody>
        </Table>

    )
}
export default SimpleTable;