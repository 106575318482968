import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import ProductFormStepBreadcrumb from "./ProductFormStepBreadcrumb";
import {AuthTemplate} from "../../authentication/AuthTemplate";
import Step1 from "./Step1";
import {ROUTE_HOME, ROUTE_LOGIN, ROUTE_ORDER_FORM} from "../../../routes/constants";
import {Link, Redirect} from "react-router-dom";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {reAuthAction} from "../../../redux/actions";
import {resetProductFormDetails} from "../../../redux/actions/productFormDetails.actions";

export const ProductFormStepPage = () => {
	const productFormDetails = useSelector(state => state.productFormDetails)
	const productId = getItemFromUrl(window.location.search, 'productId', null)

	const dispatch = useDispatch()
	const user = useSelector(state => state.login)

	useEffect(() => {
		resetProductFormDetails()
	}, [])

	if (!productId) {
		return <Redirect to={`${ROUTE_HOME}`}/>
	}

	if (user?.token) {
		if (user?.goToPage) {
			console.log("a", user?.goToPage)
			setTimeout(() => {
				dispatch(reAuthAction({...user, goToPage: null}))
				dispatch(resetProductFormDetails())
			}, 1000)
			return <Redirect to={user.goToPage}/>
		} else {
			return <Redirect to={`${ROUTE_ORDER_FORM}?productId=${productId}`}/>
		}
	}


	return (
		<AuthTemplate title="Formular de comanda"
					  subtitle={productFormDetails.currentStep === 1 ?
						  <>Ai deja cont? <Link to={ROUTE_LOGIN}>Autentifica-te</Link></>
						  : null}
					  containerSize={6}
					  fixedContainer={true}
		>
			<ProductFormStepBreadcrumb/>
			<div className="text-left">
				{
					productFormDetails.currentStep === 1 && <Step1/>
				}
				{
					productFormDetails.currentStep === 2 && <Step2/>
				}
				{
					productFormDetails.currentStep === 3 && <Step3/>
				}
			</div>

		</AuthTemplate>
	)
}
export default ProductFormStepPage