import { GET_USER_CARDS_SUCCESS, ADD_USER_CARDS_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = []

export const userCards = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_CARDS_SUCCESS:
            return action.payload
        case ADD_USER_CARDS_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}