export const isValidObject = (validations) => {
    let isValid = true

    Object.values(validations).forEach((validation) => {
        if (validation.inputCheckError()) {
            isValid = false
        }
    })

    return isValid
}