import React from "react";
import EditButton from "../buttons/EditButton";
import {Form} from "react-bootstrap";

export const InputEditableDisabled = (props) => {
    const {value, onClick, label} = props

    return (
        <>
            <div>
                {
                    label
                    ? <Form.Label className="w-100 mb-0">
                            {label}
                        </Form.Label>
                    : null
                }
                <span className="color-grey-dark d-block d-lg-inline" style={{marginBottom: '8px', marginRight: '4px'}}>
                    {value}
                </span>
                <EditButton
                    onClick={onClick}
                />
            </div>
        </>
    )
}
export default InputEditableDisabled