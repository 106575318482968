import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AccountTemplate} from "../template/AccountTemplate";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {axiosRequest} from "../../../api/axiosRequest";
import {SHOW_ALERT_MODAL} from "../../../redux";
import {getErrorMessage} from "../../../utils/errorMessage";
import {ROUTE_SERVICES} from "../../../routes/constants";
import {translate} from "../../../utils/i18n";
import ServicesList from "../../guest/services/ServicesList";
import OrderServiceBreadcrumb from "./OrderServiceBreadcrumb";
import {showDefaultErrorAction} from "../../../redux/actions";

export const OrderServicePage = (props) => {
	const [categories, setCategories] = useState([])
	const [title, setTitle] = useState("Servicii")

	const dispatch = useDispatch()
	const categoryId = getItemFromUrl(props.location.search, 'categoryId')
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)

	useEffect(() => {
		if (!categoryId) {
			axiosRequest.get('/categories?active=1&exists[parentCategory]=0')
				.then((resp) => {
					setCategories(resp.data)
				})
				.catch((err) => {
					dispatch(showDefaultErrorAction(err))
				})
		} else {
			axiosRequest.get(`/categories/${categoryId}`)
				.then((resp) => {
					setCategories([resp.data])
				})
				.catch((err) => {
					dispatch({
						type: SHOW_ALERT_MODAL,
						payload: {type: 'error', message: getErrorMessage(err), route: ROUTE_SERVICES}
					})
				})
		}
	}, [categoryId])

	useEffect(() => {
		if (categories.length) {
			if (categories.length === 1) {
				setTitle(translate('category.' + categories[0].code))
			}
		}
	}, [categories])

	return (
		<AccountTemplate title="Comanda">
			<h4 className="account-title color-brand mb-4">
				{title}
			</h4>

			<OrderServiceBreadcrumb
				categories={categories}
			/>
			{
				!userNeighbourhood?.userPublicAddress?.city?.availableForServices
					? <h6 className="color-brand mb-4">
						Nu avem servicii disponibile. Te anuntam de indata ce ajungem in orasul tau
					</h6>
					: null
			}
			{
				categories?.length
					? <ServicesList
						categories={categories}
						type="row"
					/>
					: null
			}
		</AccountTemplate>
	)
}
export default OrderServicePage