import {getPricesPerDurationFromProduct} from "../../../utils/model/product";
import {getTimeFromDuration} from "../../../utils/datetime";
import React from "react";

const ServicePrice = ({product, showPriceLabel = false, showOnlyFirstPrice = false}) => {
	if (!product) {
		return null
	}
	if (product.fixedPrice) {
		return getPricesPerDurationFromProduct(product)?.map((price, index) => {
			if (showOnlyFirstPrice && index) {
				return <div key={index}/>
			}
			return <div key={index}>
				{
					showPriceLabel && !index
					? <span className="font-weight-bold p-font-size">
						Pret:{" "}
					</span>
						: null
				}
				{
					price.oldValue
						? <span className="price-old-value">
							{price.oldValue}
						</span>
						: null
				}
				<span className="font-weight-bold p-font-size" style={{marginLeft: showPriceLabel && index ? 50 : 0}}>
					{price.value} {price.currency} / {product.subscription ? " luna" : getTimeFromDuration(price.duration) + " ore"}
				</span>
			</div>
		})
	}

	return <>
		<span className="p-font-size font-weight-bold d-block">
			{
				(product.startingPrice)
					? "Preturi de la " + parseInt(product.startingPrice) + " " + product.currency
				: <br/>
			}
		</span>

	</>

}
export default ServicePrice