import {batch} from 'react-redux'

import {GET_LOCKBOX_ACTION, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {LOCKBOX_ID} from "../../utils/constants/product";
import {showDefaultErrorAction} from "./alertModal.actions";

export const getLockboxAction = () => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/products/${LOCKBOX_ID}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_LOCKBOX_ACTION, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
