import React from "react";

import star_brand from "../../../assets/images/new/star_brand.png"
import star_white from "../../../assets/images/new/star_white.png"

import "../../../assets/css/components/button/button_vertical.scss"

export const ButtonVertical = (props) => {

    return (
        <div
            className={"button-vertical " + (props.active ? "active" : "")}
            onClick={props.onClick}
        >
            <p>
                {
                    props.title
                }
            </p>
            {
                props.active
                ? <img alt="star active" src={star_brand} style={{margin: 'auto'}}/>
                : <img alt="star" src={star_white} style={{margin: 'auto'}}/>
            }
        </div>
    )
}
export default ButtonVertical