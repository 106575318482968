import React, {useState} from "react";
import {AuthTemplate} from "./AuthTemplate";
import {useDispatch} from "react-redux";
import {resetPasswordWithLogin} from "../../redux/actions";
import {useInputValidation} from "../../utils/hooks/useInputValidation";
import {isPassword} from "../../utils/validation/conditions";
import {translate} from "../../utils/i18n";
import {isValidObject} from "../../utils/validation/validate";
import {FormGroup} from "react-bootstrap";
import {getItemFromUrl} from "../../utils/interface/queryHandler";
import InputField from "../common/input/InputField";
import {Link} from "react-router-dom";
import {ROUTE_LOGIN} from "../../routes/constants";

export const RecoverPassword = (props) => {
    const [password, setPassword] = useState('')
    const token = getItemFromUrl(props.location.search, 'token')

    const validations = {
        password: useInputValidation(password, isPassword(password), translate('validation.password.invalid')),
    }

    const dispatch = useDispatch()

    const onSubmit = () => {
        if (isValidObject(validations)) {
            dispatch(resetPasswordWithLogin(token, password))
        }
    }

    return (
        <AuthTemplate title="Recupereaza parola" subtitle="Introdu noua parola">
            <FormGroup>
                <InputField
                    className="w-100"
                    type="password"
                    placeholder={translate('global.placeholder.password')}
                    icon="password"
                    value={password}
                    setValue={setPassword}
                    validation={validations.password}
                    onSubmitForm={onSubmit}
                />
                <div className="auth-extra-links">
                    <p>
                        Mergi la{" "}
                        <Link to={ROUTE_LOGIN}>
                            autentificare
                        </Link>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="button-gradient-primary"  onClick={onSubmit}>
                        Conectare
                    </button>
                </div>
                <div className="text-center">
                    <p className="d-lg-none mt-3">
                        Mergi la{" "}
                        <Link to={ROUTE_LOGIN}>
                            autentificare
                        </Link>
                    </p>
                </div>
            </FormGroup>
        </AuthTemplate>
    )
}
export default RecoverPassword