import React from "react"

import lockbox_image from "../../../../assets/images/new/lockbox.jpg"

export const Lockbox = (props) => {
    return (
        <div className="text-center">
            <p className="font-weight-bold">
                Un lockbox este un loc pentru depozitarea cheilor
            </p>
            <p>
                Inainte de prima sedinta, un Zumzi va veni sa il monteze chiar la intrarea in locuinta ta
            </p>
            <img className="w-50" alt="lockbox" src={lockbox_image}/>
        </div>

    )
}
export default Lockbox