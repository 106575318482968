import React from 'react';
import {CATEGORY, PRODUCT} from "../../../utils/constants/constants";
import ServiceItemRow from "./ServiceItemRow";
import ServiceItemColumn from "./ServiceItemColumn";

export const ServicesList = ({type, categories}) => {

	const mainCategory = categories?.length === 1 ? categories[0] : null
	const categoriesToShow = categories?.length > 1 ? categories : categories[0]?.subcategories
	const products = categories.length === 1 ? categories[0]?.products : []

	const buildServices = (products, categories) => {
		const services = []

		products.forEach(product => {
			if (product.enabled) {
				services.push({
					type: PRODUCT,
					data: {...product, category: mainCategory}
				})
			}
		})

		categories.forEach(category => {
			if (category.active) {
				services.push({
					type: CATEGORY,
					data: category
				})
			}
		})
		return services
	}
	const services = buildServices(products, categoriesToShow)

	return (
		<>
			{
				services && services.length && services.map((service, index) => {
					if (type === 'column') {
						return <ServiceItemColumn
							service={service}
							key={index}
						/>
					}
					return <ServiceItemRow
						service={service}
						key={index}
					/>
				})
			}
		</>

	)
}
export default ServicesList