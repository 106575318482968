import React from "react";
import {Icon} from "@iconify/react/dist/iconify";
import "../../../assets/css/common/buttons/edit_button.scss"

export const SetCurrentButton = ({text = "Curent", isSelected, onClickButton, className, style}) => {
	return (
		<span
			style={style ?? {}}
			className={"button-small " + (isSelected ? "button-small-current-selected" : "button-small-current") + " " + className ?? ""}
			onClick={() => onClickButton ? onClickButton() : {}}
		>
            {
				!isSelected
					? <Icon icon="ant-design:select-outlined" style={{marginTop: '-2px', marginRight: '4px'}}/>
					: <Icon icon="ep:select" style={{marginTop: '-2px', marginRight: '4px'}}/>
			}

			{text}
        </span>
	)
}
export default SetCurrentButton