import {translate} from "../i18n";

export const getServiceName = (service) => {
	if (!service) {
		return "";
	}

	if (service?.data?.title) {
		return service.data.title;
	}

	return translate(service.type + "." + service.data.code);
}
