import React, {useEffect, useState} from "react";

export const useWindowScroll = () => {
	const [scrollSize, setScrollSize] = useState(0)

	useEffect(() => {
		const handleScroll = () => {
			setScrollSize(window.pageYOffset);
		}

		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, []);

	return scrollSize;
}