import {HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {trackEvent} from "../../utils/analytics";
import {ROUTE_3DS_PAYMENT, ROUTE_PAYMENTS} from "../../routes/constants";
import {PAYMENT_METHOD_STRIPE_ID} from "../../utils/constants/constants";
import {hideModalPopup} from "./modalPopup.actions";
import {showDefaultErrorAction} from "./alertModal.actions";

export const postCheckoutAction = (orderId, cardId, paymentMethodId = PAYMENT_METHOD_STRIPE_ID) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const orderValue = await axiosRequest.post(`/orders/${orderId}/checkout`, {
			cardId,
			paymentMethodId
		})
		dispatch({type: HIDE_SPINNER})
		dispatch(hideModalPopup())

		if (orderValue?.data?.data?.requires_auth === true) {
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					type: 'success',
					message: 'Plata a fost creata cu succes, finalizeaza plata',
					route: `${ROUTE_3DS_PAYMENT}?orderId=${orderValue?.data?.orderId}&clientSecret=${orderValue?.data?.data?.client_secret}`
				}
			})
		} else {
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					type: 'success',
					message: 'Plata a fost creata cu succes',
					route: ROUTE_PAYMENTS + "?successOrder=1"
				}
			})
		}
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const postOrder = (body, orderDetails, createdOrderCallback = null) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const orderValue = await axiosRequest.post('/simple_orders', body)
		trackEvent('fb_mobile_purchase', {valueToSum: orderDetails.totalPrice})
		dispatch({type: HIDE_SPINNER})
		if (createdOrderCallback) {
			createdOrderCallback()
		}
		if (orderValue?.data?.data?.requires_auth === true) {
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					type: 'success',
					message: 'Comanda a fost creata cu succes, finalizeaza plata',
					route: `${ROUTE_3DS_PAYMENT}?orderId=${orderValue?.data?.orderId}&clientSecret=${orderValue?.data?.data?.client_secret}`
				}
			})
		} else {
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					type: 'success',
					message: 'Comanda a fost creata cu succes',
					route: ROUTE_PAYMENTS + "?successOrder=1"
				}
			})
		}
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}