import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {decreaseOrderDetailsStepAction, getUserNeighbourhoodActions, postOrder} from "../../../../redux/actions";
import {LOCKBOX_ID} from "../../../../utils/constants/product";
import OrderLastStep from "../../../common/OrderLastStep";

export const Step5 = ({}) => {
	const dispatch = useDispatch()

	const userNeighbourhood = useSelector(state => state.userNeighbourhood)
	const userCards = useSelector(state => state.userCards)
	const orderDetails = useSelector(state => state.orderDetails)
	const selectedCalendar = useSelector(state => state.selectedCalendar)

	const product = useSelector(state => state.product)
	const selectedProductOptions = useSelector(state => state.selectedProductOptions)


	const vouchers = useSelector(state => state.vouchers.userVouchers)
	const billingData = useSelector(state => state.billingData)
	const user = useSelector(state => state.login)

	const fullAddress = userNeighbourhood?.userPublicAddress?.address?.fullAddress
	const property = userNeighbourhood.userPublicAddress?.address?.property ?? orderDetails.property


	const onPayService = (isCompany) => {
		const body = {
			listing: {
				property: property,
				startDate: selectedCalendar.startDate,
				localStartTime: selectedCalendar.localStartTime,
				localTimeZone: selectedCalendar.localTimeZone,
				type: 4,
				notes: orderDetails.serviceNotes
			},
			purchaseOption: {
				subscription: product.subscription,
				subscriptionDuration: product.subscription ? 12 : null,
				shoppingItems: [
					{
						product: product.id,
						productOptions: selectedProductOptions.map((item) => {return item.id}),
						productQuantity: 1,
					}
				]
			},
		}
		if (isCompany) {
			body.listing.billingData = {id: billingData.id}
		}
		if (orderDetails.buyLockbox) {
			body.purchaseOption.shoppingItems.push({
				product: LOCKBOX_ID,
				productOptions: [],
				productQuantity: 1
			})
		}
		if (vouchers?.length) {
			body.voucher = {
				code: vouchers[0].voucher.code
			}
		}
		if (userCards?.length) {
			body.card = {
				id: userCards[0]?.id
			}
		}
		let createdOrderCallback = null
		if (!property?.id) {
			createdOrderCallback = () => dispatch(getUserNeighbourhoodActions(user?.id))
		}

		dispatch(postOrder(body, orderDetails, createdOrderCallback))
	}

	return (
		<OrderLastStep
			fullAddress={fullAddress}
			property={property}
			orderGenericDetails={orderDetails}
			onSubmit={onPayService}
			onBack={() => dispatch(decreaseOrderDetailsStepAction(orderDetails))}
		/>
	)
}
export default Step5