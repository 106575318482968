import React, {useEffect} from "react";

export const useOutsideClickEvent = (ref, callback, args = [], condition = true) => {

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (condition) {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback(...args)
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}