import React from "react";
import no_data from '../../assets/images/app/common/no_data_available.svg'

export const NoDataAvailable = (props) => {
    return (
        <div style={styles.container}>
            <img src={no_data} alt="fara date"/>
            <p style={styles.title}>
                {props.text}
            </p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 18,
        padding: '0 18',
    },
}

export default NoDataAvailable