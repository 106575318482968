import { LOGIN_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = {}

export const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}