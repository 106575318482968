import {LOGOUT_RESET, SET_ALL_PRODUCT_OPTIONS} from '../constants'

const initialState = []

export const allProductOptions = (state = initialState, action) => {
	switch (action.type) {
		case SET_ALL_PRODUCT_OPTIONS:
			return action.payload
		case LOGOUT_RESET:
			return initialState
		default:
			return state
	}
}