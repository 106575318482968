import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'

import "../../../assets/css/components/button/button_vertical.scss"
import {AccountTemplate} from "../template/AccountTemplate";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {
	getCalendarByProductFormRequestId,
	getProductFormRequestByIdAction, resetProductFormRequestAction, resetProductFormRequestsAction,
	setOrderRequestDetails
} from "../../../redux/actions";
import OrderRequestStepBreadcrumb from "./OrderRequestStepBreadcrumb";
import Step1 from "./step/Step1";
import Step2 from "./step/Step2";
import Step3 from "./step/Step3";
import {orderRequestDetailsInitialState} from "../../../utils/model/orderRequestDetails";
import {GET_PRODUCT_FORM_REQUEST_SUCCESS} from "../../../redux";

export const OrderRequestStepPage = ({}) => {
	const productFormRequestId = getItemFromUrl(window.location.search, 'productFormRequestId')
	const productFormRequest = useSelector(state => state.productFormRequest)
	const orderRequestDetails = useSelector(state => state.orderRequestDetails)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getCalendarByProductFormRequestId(productFormRequestId))
		dispatch(resetProductFormRequestAction())
	}, [])

	useEffect(() => {
		if (productFormRequestId) {
			dispatch(getProductFormRequestByIdAction(productFormRequestId))
		}
	}, [productFormRequestId])

	useEffect(() => {
		if (productFormRequest?.totalValue) {
			const property = Object.assign({}, productFormRequest?.productForm?.listing?.property)
			delete property.id
			delete property.address
			dispatch(setOrderRequestDetails({...orderRequestDetailsInitialState,
				new: Math.random(),
				property: property,
				totalPrice: parseInt(productFormRequest?.totalValue)}))
		}
	}, [productFormRequest])

	return (
		<AccountTemplate title="Comanda">
			<OrderRequestStepBreadcrumb/>

			<div className="account-medium-container">
				{
					orderRequestDetails.currentStep === 1 && <Step1/>
				}
				{
					orderRequestDetails.currentStep === 2 && <Step2/>
				}
				{
					orderRequestDetails.currentStep === 3 && <Step3/>
				}
			</div>
		</AccountTemplate>
	)
}
export default OrderRequestStepPage