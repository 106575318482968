import React from "react";
import {Icon} from "@iconify/react/dist/iconify";
import "../../../assets/css/common/buttons/edit_button.scss"

export const EditButton = ({text = "Editeaza", onClick, className}) => {
    const onClickButton = () => {
        if (onClick) {
            onClick()
        }
    }

    return (
        <span className={"edit-button-container " + (className ?? "")} onClick={onClickButton}>
            <Icon icon="ci:edit"/>
            <span className="button-small button-small-edit">
                {text}
            </span>
        </span>
    )
}
export default EditButton