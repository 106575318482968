import { GET_LOCKBOX_ACTION, LOGOUT_RESET } from '../constants'

const initialState = {}

export const lockbox = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCKBOX_ACTION:
      return action.payload
    case LOGOUT_RESET:
      return initialState
    default:
      return state
  }
}