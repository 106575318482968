import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translation from './translation/ro.json';

const resources = {
    ro: {
        translation: translation
    }
};

i18n
    .use(reactI18nextModule)
    .init({
        resources,
        lng: "ro",

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        returnObjects: true,
    });

export const translate = (str) => {
    let val =  i18n.t(str);
    if (! (val instanceof String) && typeof val !== "string") {
        val = val[0];
    }
    return val;
};

export const getTransLabel = (key, prefix = '') => {
    const camelCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    return translate(`label.${prefix}.${camelCaseKey}`)
}