import { GET_PUBLIC_ADDRESSES_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = []

export const publicAddresses = (state = initialState, action) => {
	switch (action.type) {
		case GET_PUBLIC_ADDRESSES_SUCCESS:
			return action.payload
		case LOGOUT_RESET:
			return initialState
		default:
			return state
	}
}