import {getDate, getTime} from "../datetime";
import {ROUTE_MY_BOOKING} from "../../routes/constants";
import {getProductName} from "./product";
import {getProductOptionsName} from "./productOption";

export const buildBookingModel = (booking) => {
	return {
		type: 'booking',
		productCode: getProductCode(booking),
		attributes: getBookingAttributes(booking),
		serviceName: getServiceName(booking),
		isCancelled: isCancelled(booking),
		data: booking,
		url: ROUTE_MY_BOOKING,
		sortKey: booking.bookingDate,
	}
}

export const getProductCode = (booking) => {
	return booking?.listing?.currentShoppingItem?.product?.code
}

export const getProductNameFromBooking = (booking) => {
	return getProductName(booking?.listing?.currentShoppingItem?.product)
}

export const getProductOptionsNameFromBooking = (booking) => {
	return getProductOptionsName(booking?.listing?.currentShoppingItem?.productOptions)
}


export const getAgentName = (booking) => {
	return booking?.agents?.length ? booking.agents[0]?.user?.name : '-'
}

export const hasAgent = (booking) => {
	return booking?.agents?.length
}

export const getAddress = (booking) => {
	return booking?.listing?.property?.address?.fullAddress
}

export const getServiceName = (booking) => {
	return booking.mainService
}

export const getBookingAttributes = (booking) => {
	return [
		{
			label: 'Data',
			value: getDate(booking.bookingDate),
		},
		{
			label: "Ora",
			value: getTime(booking.localStartTime) + "-" + getTime(booking.localEndTime),
		},
		{
			label: 'Adresa',
			value: getAddress(booking),
		},
		{
			label: 'Partener',
			value: getAgentName(booking)
		}
	]
}

export const canSetBookingFeedback = (booking) => {
	return booking?.timeType === 2 && !booking?.review && hasAgent(booking)
}

export const isCancelled = (booking) => {
	return [1, 3].includes(booking.status)
}

export const isWaitingForAgent = (booking) => {
	return [0, 4].includes(booking.status)
}

export const isFromSubscription = (booking) => {
	return !!booking.sessionNumber
}

export const isFutureBooking = (booking) => {
	return booking?.timeType === 0
}
