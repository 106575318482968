import {Container, Nav, Navbar} from "react-bootstrap";
import zumzi_logo from "../../../assets/images/logo_zumzi_text.png";
import PageLinkNav from "./PageLinkNav";
import ButtonNav from "./ButtonNav";
import React from "react";
import {Link} from "react-router-dom";
import menu_icon from "../../../assets/images/new/menu.png"
import {ROUTE_DASHBOARD} from "../../../routes/constants";
import {useSelector} from "react-redux";
import {useWindowScroll} from "../../../utils/hooks/useWindowScroll";

export const NavBar = (props) => {
	const user = useSelector(state => state.login)
	const scrollPosition = useWindowScroll()

	return (
		//fixed="top" sticky="top"
		<Navbar expand="md"
				style={{
					position: 'fixed',
					top: 0,
					width: '100%',
					backgroundColor: scrollPosition ? '#FAFCFC ' : 'transparent',
					zIndex: '1052',
					boxShadow: scrollPosition ? '0 0 0 0 rgb(0 0 0 / 12%), 0 4px 10px -5px rgb(0 0 0 / 15%)' : 'none',
				}}>
			<Container className="desktop-navbar">
				<Navbar.Brand href="/">
					<img style={{height: '67px'}} src={zumzi_logo} alt="zumzi logo"/>
				</Navbar.Brand>

				<Nav className="navbar-links">
					<PageLinkNav/>
				</Nav>

				{/*<Nav className="navbar-icons">*/}
				{/*	<IconsNav*/}
				{/*		color={colors.white}*/}
				{/*	/>*/}
				{/*</Nav>*/}

				<Nav>
					<ButtonNav
						style={{
							marginLeft: "1rem",
						}}
					/>
				</Nav>
			</Container>

			<div className="mobile-navbar">
				<div  style={{paddingTop: '10px'}}>
					<div onClick={props.toggleMenu} style={{
						display: 'flex',
						alignItems: 'center',
						width: '100px',
						padding: '4px 16px',
						border: '1px solid black',
						borderRadius: '70px',
					}}>
						<img src={menu_icon} alt="meniu"/>
						<span className="color-black" style={{paddingLeft: '6px', paddingTop: '2px'}}>Meniu</span>
					</div>
				</div>
				<div>
					<Link to="/">
						<img style={{height: '72px'}} src={zumzi_logo} alt="zumzi logo alb"/>
					</Link>
				</div>
				<div>
					<Link to={ROUTE_DASHBOARD} className="nav-link"
						  style={{padding: 0, fontSize: '16px', paddingTop: '16px'}}>
						{user?.token ? "Contul meu" : "Autentificare"}
					</Link>
				</div>
			</div>
		</Navbar>
	)
}
export default Navbar