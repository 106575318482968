import React from "react";
import DateTimeCalendar from "../../../common/DateTimeCalendar";
import {useDispatch, useSelector} from "react-redux";
import {
	decreaseOrderDetailsStepAction,
	decreaseOrderRequestDetailsStepAction,
	increaseOrderRequestDetailsStepAction,
	setSelectedCalendarAction
} from "../../../../redux/actions";


export const Step2 = ({}) => {
	const dispatch = useDispatch()
	const orderRequestDetails = useSelector(state => state.orderRequestDetails)
	const selectedCalendar = useSelector(state => state.selectedCalendar)

	const onSubmitCalendar = async (startDate, localStartTime) => {
		dispatch(increaseOrderRequestDetailsStepAction(orderRequestDetails))
		dispatch(setSelectedCalendarAction(selectedCalendar, startDate, localStartTime))
	}

	return (
		<div>
			<DateTimeCalendar
				onSubmitCalendar={onSubmitCalendar}
				buttonText="Continua"
				onBack={() => dispatch(decreaseOrderRequestDetailsStepAction(orderRequestDetails))}
			/>
		</div>
	)
}
export default Step2