import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	addCardActions,
	deleteUserCardAction,
	showModalPopup,
	updateCardActions,
	userCardsActions
} from "../../../redux/actions";
import AddUserCard from "./AddUserCard";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {Redirect} from "react-router-dom";
import {colors} from "../../../utils/style/style";
import {Icon} from "@iconify/react/dist/iconify";
import SetCurrentButton from "../../common/buttons/SetCurrentButton";
import DeleteButton from "../../common/buttons/DeleteButton";

export const UserCards = (props) => {
	const [myCards, setMyCards] = useState([])
	const [showNewCard, setShowNewCard] = useState(false)
	const redirectPath = getItemFromUrl(window.location.search, 'redirectPath')
	const [toRedirect, setToRedirect] = useState(false)

	const dispatch = useDispatch()

	const userCards = useSelector(state => state.userCards)

	useEffect(() => {
		dispatch(userCardsActions())
	}, [])

	useEffect(() => {
		setMyCards(userCards)
		if (userCards.length === 0) {
			setShowNewCard(true)
		} else {
			setShowNewCard(false)
		}
	}, [userCards])

	const onSetCurrentCard = (card) => {
		const callback = () => {
			dispatch(updateCardActions(card.id))
		}
		dispatch(showModalPopup(
			"Schimba card",
			<p>Doresti sa schimbi cardul curent?</p>,
			callback
		))
	}

	const onDeleteUserCard = (card) => {
		const callback = () => {
			dispatch(deleteUserCardAction(card.id))
		}
		dispatch(showModalPopup(
			"Sterge card",
			<p>Doresti sa sterge cardul?</p>,
			callback,
			'md',
			null,
			'delete'
		))
	}

	const addCard = (cardNumber, cardExpMonth, cardExpYear, cardCvc) => {
		dispatch(addCardActions(cardNumber, cardExpMonth, cardExpYear, cardCvc))
		if (redirectPath) {
			setToRedirect(true)
		}
	}

	if (toRedirect) {
		return <Redirect to={redirectPath}/>
	}

	return (
		<>
			{
				myCards?.length
					? <h4 className="account-title">
						Metode de plata:
					</h4>
					: null
			}

			<>
				{
					myCards.map((el, index) => (
						<div key={index} className="flex-item-in-list">
							<div className="flex-item-in-list-container">
								<p style={{
									textTransform: 'uppercase',
									display: 'inline',
									fontWeight: 'bold',
									color: colors.black
								}}>
									{el.cardType}
									<Icon icon="fluent:payment-16-filled" color={colors.brand}
										  style={{marginLeft: '0.5rem', marginBottom: '4px'}}/>
								</p>
								<p>
									{" "}{el.cardMask}
								</p>
								<span>
                                    Exp: {el.expirationDate.split('-')[1]}/{el.expirationDate.split('-')[0].substring(2, 4)}
                                </span>
							</div>
							<div>
								<SetCurrentButton
									style={{marginRight: '0.5rem'}}
									isSelected={!index}
									onClickButton={() => index ? onSetCurrentCard(el) : {}}
								/>

								<DeleteButton
									onClickButton={() => onDeleteUserCard(el)}
								/>
							</div>
						</div>
					))
				}
			</>
			{
				myCards?.length
					? <hr/>
					: null
			}


			{
				showNewCard
					?
					<AddUserCard
						addCard={addCard}
					/>
					: <div style={{display: 'flex', justifyContent: 'center'}}>
						<button className="button-add" onClick={(e) => setShowNewCard(true)}>
							+
						</button>
					</div>
			}

		</>
	)
}
export default UserCards