import { GET_PRODUCT_OPTION, LOGOUT_RESET } from '../constants'

const initialState = {}

export const productOption = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_OPTION:
      return action.payload
    case LOGOUT_RESET:
      return initialState
    default:
      return state
  }
}