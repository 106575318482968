import React from "react";
import NewComplex from "../../account/neighbourhood/NewComplex";
import {useDispatch, useSelector} from "react-redux";
import {decreaseProductFormDetailsStepAction} from "../../../redux/actions/productFormDetails.actions";

export const Step2 = () => {
	const productFormDetails = useSelector(state => state.productFormDetails)
	const dispatch = useDispatch()

	const backCallback = () => {
		dispatch(decreaseProductFormDetailsStepAction(productFormDetails))
	}

	return (
		<div>
			<NewComplex
				showOnlyAddress={true}
				isLocalSave={true}
				backCallback={backCallback}
			/>
		</div>
	)
}
export default Step2