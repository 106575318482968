import React from "react";
import {Icon} from "@iconify/react/dist/iconify";
import "../../../assets/css/common/buttons/delete_button.scss"
import {colors} from "../../../utils/style/style";

export const DeleteButton = ({text = 'Sterge', onClickButton, className}) => {

	const onClick = () => {
		if (onClickButton) {
			onClickButton()
		}
	}

	return (
		<span className={"delete-button-container " + (className ?? "")} onClick={onClick}>
            <Icon icon="ci:close-big" color={colors.red}/>
            <span className="button-small button-small-remove">
                {text}
            </span>
        </span>
	)
}
export default DeleteButton