import React from "react";
import DateTimeCalendar from "../../../common/DateTimeCalendar";
import {useDispatch, useSelector} from "react-redux";
import {
    decreaseOrderDetailsStepAction,
    increaseOrderDetailsStepAction,
    setSelectedCalendarAction
} from "../../../../redux/actions";


export const Step3 = (props) => {
    const dispatch = useDispatch()
    const orderDetails = useSelector(state => state.orderDetails)
    const selectedCalendar = useSelector(state => state.selectedCalendar)
    const userNeighbourhood = useSelector(state => state.userNeighbourhood)

    const stepSize = userNeighbourhood.userPublicAddress?.address?.property ? 2 : 1

    const onSubmitCalendar = async (startDate, localStartTime) => {
        dispatch(increaseOrderDetailsStepAction(orderDetails))
        dispatch(setSelectedCalendarAction(selectedCalendar, startDate, localStartTime))
    }

    return (
        <div>
            <DateTimeCalendar
                onSubmitCalendar={onSubmitCalendar}
                buttonText="Continua"
                onBack={() => dispatch(decreaseOrderDetailsStepAction(orderDetails, stepSize))}
            />
        </div>
    )
}
export default Step3