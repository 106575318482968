import React, {useState} from "react";
import {AuthTemplate} from "./AuthTemplate";
import {useDispatch} from "react-redux";
import {resetPasswordActions} from "../../redux/actions";
import {useInputValidation} from "../../utils/hooks/useInputValidation";
import {isEmail} from "../../utils/validation/conditions";
import {translate} from "../../utils/i18n";
import {isValidObject} from "../../utils/validation/validate";
import {FormGroup} from "react-bootstrap";
import InputField from "../common/input/InputField";
import {Link} from "react-router-dom";
import {ROUTE_LOGIN} from "../../routes/constants";

export const ResetPassword = (props) => {
    const [email, setEmail] = useState('')

    const validations = {
        email: useInputValidation(email, isEmail(email), translate('validation.email.invalid')),
    }

    const dispatch = useDispatch()

    const onSubmit = () => {
        if (isValidObject(validations)) {
            dispatch(resetPasswordActions(email))
        }
    }

    return (
        <AuthTemplate title="Resetare parola" subtitle="Introdu adresa de email pentru a primi un link de resetare a parolei">
            <FormGroup>
                <InputField
                    className="w-100"
                    type="email"
                    placeholder="Email"
                    icon="email"
                    value={email}
                    setValue={setEmail}
                    validation={validations.email}
                    onSubmitForm={onSubmit}
                />
                <div className="auth-extra-links">
                    <p>
                        Mergi la{" "}
                        <Link to={ROUTE_LOGIN}>
                             autentificare
                        </Link>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="button-gradient-primary"  onClick={onSubmit}>
                        Conectare
                    </button>
                </div>
                <div className="text-center">
                    <p className="d-lg-none mt-3">
                        Mergi la{" "}
                        <Link to={ROUTE_LOGIN}>
                            autentificare
                        </Link>
                    </p>
                </div>

            </FormGroup>
        </AuthTemplate>
    )
}
export default ResetPassword