import React from "react";
import {AuthTemplate} from "./AuthTemplate";
import Register from "./Register";

export const RegisterPage = (props) => {

	return (
		<AuthTemplate title="Bine ai venit" subtitle="Dureaza doar cateva secunde sa iti creezi un cont">
			<Register/>
		</AuthTemplate>
	)
}
export default RegisterPage;