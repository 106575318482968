import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDate} from "../../utils/datetime";
import {HIDE_SPINNER, SHOW_SPINNER} from "../../redux";
import Calendar from "react-calendar";

import "../../assets/css/components/date_time_calendar/date_time_calendar.scss"

export const DateTimeCalendar = (props) => {
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedHour, setSelectedHour] = useState('')
    const [blockedDates, setBlockedDates] = useState([])

    const [hours, setHours] = useState([])
    const [startingHours, setStartingHours] = useState([])
    const [dateError, setDateError] = useState(false)

    const calendar = useSelector(state => state.calendar)
    const selectedCalendar = useSelector(state => state.selectedCalendar)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SHOW_SPINNER})
    }, [])

    useEffect(() => {
        if (calendar?.minDate) {
            dispatch({type: HIDE_SPINNER})
            setHours(calendar.startingHours.map(() => { return false }))

            setStartingHours(calendar.startingHours)
            setBlockedDates(
                calendar.blockedDates.map((strDate) => {
                    return getDate(strDate)
                })
            )
        }
    }, [calendar])

    useEffect(() => {
        if (hours.length) {
            if (calendar && JSON.stringify(calendar.startingHours.map(() => { return false })) === JSON.stringify(hours)) {
                if (selectedCalendar.startDate) {
                    markDay(new Date(selectedCalendar.startDate), selectedCalendar.localStartTime)
                }
            }
        }
    }, [hours])

    const buildDateStr = (date) => {
        let month = date.getMonth() + 1
        let day = date.getDate()
        if (month < 10) {
            month = "0" + month
        }
        if (day < 10) {
            day = "0" + day
        }

        return date.getFullYear() + '-' + month + '-' + day
    }

    const markDay = (date, selHour = null) => {
        const selDate = buildDateStr(date)
        setSelectedDate(selDate)
        const availableHours = calendar.availableHoursByDate[selDate]
        const oldHours = hours.map(() => { return false })

        if (availableHours) {
            availableHours.map((el) => {
                let hour = el[0].split('T')[1].split(':')[0]
                startingHours.forEach((itemHour, index) => {
                    if (hour === itemHour.substr(0, 2)) {
                        oldHours[index] = true
                    }
                })
            })
            if (typeof selHour === 'object') {
                setSelectedHour(null)
            } else {
                setSelectedHour(selHour)
            }
            setHours(oldHours)
        }
    }

    const markHour = hour => {
        setSelectedHour(hour)
        setDateError(false)
    }

    const setDisabledDate = ({activeStartDate, date, view}) => {
        return blockedDates.includes(buildDateStr(date))
    }

    const setClassName = ({activeStartDate, date, view}) => {
        return selectedDate === buildDateStr(date) ? 'date date-active' : 'date'
    }

    const HourButton = (props) => {
        const {index, hour} = props
        return <>
            <button
                className={"button-hour " + (selectedHour === hour ? "button-hour-selected" : "")}
                style={{ opacity: hours[index] ? 1 : 0.5}}
                onClick={() => markHour(hour)}
                disabled={!hours[index]}
            >
                {hour}
            </button>
        </>
    }

    const onSubmit = () => {
        if (!selectedHour || !selectedDate) {
            setDateError(true)
        } else {
            props.onSubmitCalendar(selectedDate, selectedHour)
        }
    }

    return (
        <>
            <div className="calendar-container">
                {
                    calendar?.minDate
                    && <Calendar
                        onChange={markDay}
                        minDate={new Date(calendar.minDate)}
                        maxDate={new Date(calendar.maxDate)}
                        tileDisabled={setDisabledDate}
                        tileClassName={setClassName}
                    />
                }
            </div>
            <div className="mb-4" style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: '350px'}}>
                {
                    startingHours.map((hour, index) => {
                        return <HourButton hour={hour} index={index} key={index}/>
                    })
                }
            </div>
            <div>
                {
                    props.onBack
                    ?   <button
                            className="button-back"
                            onClick={props.onBack}
                        >
                        {"< Inapoi"}
                    </button>
                    : null
                }


                <button
                    className="button-gradient-primary clearfix"
                    onClick={onSubmit}
                >
                    {props.buttonText}
                </button>
            </div>
            { dateError
                && <div style={{clear: 'both'}}>
                    <span className="color-red">
                        Selecteaza o data si o ora cand vrei sa venim
                    </span>
                </div>
            }
        </>
    )
}
export default DateTimeCalendar