import {LOGOUT_RESET, SET_PRODUCT_FORM_DETAILS} from '../constants'
import {productFormDetailsInitialState} from "../../utils/model/productFormDetails";

export const productFormDetails = (state = productFormDetailsInitialState, action) => {
	switch (action.type) {
		case SET_PRODUCT_FORM_DETAILS:
			return action.payload
		case LOGOUT_RESET:
			return productFormDetailsInitialState
		default:
			return state
	}
}