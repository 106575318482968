import React, {useEffect, useState} from "react";
import AccountSideBar from "./AccountSideBar";
import {useWindowResize} from "../../../utils/hooks/useWindowResize";
import AccountNavbar from "./AccountNavbar";
import {HIDE_ACCOUNT_SIDEBAR, SHOW_ACCOUNT_SIDEBAR} from "../../../redux";
import {useDispatch, useSelector} from "react-redux";


export const AccountMenu = (props) => {
	const [isMobile, setIsMobile] = useState(false)

	const {width} = useWindowResize()
	const dispatch = useDispatch()

	const accountSidebar = useSelector(state => state.accountSidebar)

	useEffect(() => {
		dispatch({type: SHOW_ACCOUNT_SIDEBAR})
	}, [])

	useEffect(() => {
		if (width) {
			if (accountSidebar && width < 991) {
				dispatch({type: HIDE_ACCOUNT_SIDEBAR})
			}
			if (width > 991) {
				setIsMobile(false)
				dispatch({type: SHOW_ACCOUNT_SIDEBAR})
			} else {
				setIsMobile(true)
				dispatch({type: HIDE_ACCOUNT_SIDEBAR})
			}
		}
	}, [width])

	return (
		<div>
			<AccountNavbar/>

			{
				accountSidebar
				&& (
					width <= 991
						? <div className="sidebar-wrapper">
							<AccountSideBar
								isMobile={isMobile}
							/>
						</div>
						: <AccountSideBar
							isMobile={isMobile}
						/>
				)
			}
		</div>
	)
}
export default AccountMenu