import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {ROUTE_ADD_ADDRESS} from "../../../routes/constants";
import {getPublicAddressesAction, setUserNeighbourhoodActions, showModalPopup} from "../../../redux/actions";
import {getStringProperty} from "../../../utils/model/property";
import {useDispatch, useSelector} from "react-redux";
import PropertyData from "./PropertyData";
import EditButton from "../../common/buttons/EditButton";

import "../../../assets/css/account/personal_data/address.scss"
import SetCurrentButton from "../../common/buttons/SetCurrentButton";

const AddressItem = ({el, setPublicAddress, setCurrentAddress, isFirst = false}) => {
	return (
		<div key={el.id} className="address-item">
			<div>
				<p style={{marginBottom: '0px'}}>
					{el.address.fullAddress}
				</p>
				{
					el.address.property?.id && <p className="address-details-text">
						{getStringProperty(el.address.property)}
					</p>
				}
			</div>

			<div className="address-item-buttons">
                    <span onClick={() => setPublicAddress(el)} style={{marginRight: '1rem'}}>
                            <EditButton/>
                        </span>
				<SetCurrentButton
					isSelected={isFirst}
					onClickButton={() => setCurrentAddress(el, isFirst)}
				/>
			</div>
		</div>
	)
}

export const Addresses = (props) => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.login)
	const publicAddresses = useSelector(state => state.publicAddresses)
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)
	const orderDetails = useSelector(state => state.orderDetails)

	useEffect(() => {
		dispatch(getPublicAddressesAction(user?.id))
	}, [])

	const setPublicAddress = (publicAddress) => {
		dispatch(showModalPopup("Modifica detalii adresa",
			<div className="property-container">
				<PropertyData
					property={publicAddress.address.property ?? orderDetails.property}
					toSubmit={true}
				/>
			</div>
		))
	}

	const setCurrentAddress = (el, isFirst) => {
		const callback = () => {
			dispatch(setUserNeighbourhoodActions(user.id, el.id, null))
		}
		if (!isFirst) {
			dispatch(showModalPopup(
				"Schimba adresa",
				<p>Doresti sa schimbi adresa curenta?</p>,
				callback
			))
		}
	}

	return (
		<>
			<h6 className="mb-4">
				Adresele mele
			</h6>
			<div>
				{
					userNeighbourhood?.userPublicAddress &&
					<AddressItem
						el={userNeighbourhood.userPublicAddress}
						setCurrentAddress={setCurrentAddress}
						setPublicAddress={setPublicAddress}
						isFirst={true}
					/>
				}
				{
					publicAddresses.map(el => {
							if (el.id === userNeighbourhood?.userPublicAddress?.id) {
								return null
							}
							return <AddressItem
								el={el}
								setCurrentAddress={setCurrentAddress}
								setPublicAddress={setPublicAddress}
							/>
						}
					)
				}
			</div>
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<Link className="button-add" to={`${ROUTE_ADD_ADDRESS}`}>
					+
				</Link>
			</div>
		</>
	)
}
export default Addresses