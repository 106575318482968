import {combineReducers} from 'redux'

import {introSlider} from './introSlider.reducer'
import {spinner} from './spinner.reducer'
import {alertModal} from './alertModal.reducer'
import {login} from './login.reducer'
import {selectComplex} from './selectComplex.reducer'
import {userNeighbourhood} from './userNeighbourhood.reducer'
import {calendar} from './calendar.reducer'
import {userCards} from './userCards.reducer'
import {vouchers} from './vouchers.reducer'
import {prices} from './prices.reducer'
import {subscriptionBookings} from './subscriptionBookings.reducer'
import {subscriptionBooking} from './subscriptionBooking.reducer'
import {payments} from './payments.reducer'
import {categories} from './categories.reducer'
import {product} from './product.reducer'
import {productOption} from './productOption.reducer'
import {lockbox} from './lockbox.reducer'
import {bookings} from './bookings.reducer'
import {booking} from './booking.reducer'
import {recommendations} from './recommendations.reducer'
import {path} from './path.reducer'
import {marketingConsents} from './marketingConsent.reducer'
import {publicAddresses} from './publicAddresses.reducer'
import {city} from './city.reducer'
import {orderDetails} from './orderDetails.reducer'
import {selectedCalendar} from './selectedCalendar.reducer'
import {purchaseOption} from './purchaseOption.reducer'
import {productForm} from './productForm.reducer'
import {productForms} from './productForms.reducer'
import {products} from './products.reducer'
import {accountSidebar} from './accountSidebar.reducer'
import {modalPopup} from './modalPopup.reducer'
import {subscriptions} from './subscriptions.reducer'
import {billingData} from "./billingData.reducer";
import {productFormDetails} from "./productFormDetails.reducer";
import {productFormRequests} from "./productFormRequests.reducer";
import {productFormRequest} from "./productFormRequest.reducer";
import {orderRequestDetails} from "./orderRequestDetails.reducer";
import {productOptions} from "./productOptions.reducer";
import {selectedProductOptions} from "./selectedProductOptions.reducer";
import {allProductOptions} from "./allProductOptions";

export const rootReducer = combineReducers({
	introSlider,
	spinner,
	alertModal,
	login,
	selectComplex,
	userNeighbourhood,
	calendar,
	userCards,
	vouchers,
	prices,
	payments,
	categories,
	product,
	productOption,
	lockbox,
	bookings,
	subscriptionBookings,
	subscriptionBooking,
	booking,
	recommendations,
	path,
	marketingConsents,
	publicAddresses,
	city,
	orderDetails,
	selectedCalendar,
	purchaseOption,
	productForm,
	productForms,
	products,
	accountSidebar,
	modalPopup,
	subscriptions,
	billingData,
	productFormDetails,
	productFormRequests,
	productFormRequest,
	orderRequestDetails,
	productOptions,
	selectedProductOptions,
	allProductOptions
})