import {getDate} from "../datetime";
import {getAddressFromListing} from "./listing";
import {translate} from "../i18n";
import {ROUTE_MY_SUBSCRIPTION} from "../../routes/constants";
import {getProductName} from "./product";

export const buildSubscriptionModel = (subscription) => {
	return {
		type: 'subscription',
		productCode: subscription?.payable?.data?.currentShoppingItem?.product.code,
		attributes: getSubscriptionAttributes(subscription),
		serviceName: getProductNameFromSubscription(subscription),
		isCancelled: subscription.status !== 1,
		data: subscription,
		url: ROUTE_MY_SUBSCRIPTION,
		sortKey: subscription.nextDueDate,
	}
}


export const getSubscriptionAttributes = (subscription) => {
	return [
		{
			label: 'Urmatoarea plata',
			value: getDate(subscription.nextDueDate),
		},
		{
			label: "Data de inceput",
			value: getDate(subscription.startAt),
		},
		{
			label: 'Adresa',
			value: getAddressFromListing(subscription?.payable?.data),
		},
		{
			label: 'Card',
			value: subscription?.userCard?.cardMask ?? '-'
		}
	]
}

export const getProductNameFromSubscription = (subscription) => {
	return getProductName(subscription?.payable?.data?.currentShoppingItem?.product)
}