import React, {useEffect, useState} from "react";

// import {loadStripe} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements, useStripe,} from '@stripe/react-stripe-js';
import {PageLoader} from "../../common/PageLoader";
import {axiosRequest} from "../../../api/axiosRequest";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {Redirect} from "react-router-dom";
import {ROUTE_PAYMENTS} from "../../../routes/constants";


export const ThreeDSSecurePaymentPage = (props) => {
	const [isValid, setIsValid] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const [errorModal, setErrorModal] = useState(false)
	const [toRedirect, setToRedirect] = useState(false)

	const [stripePromise, setStripePromise] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const clientSecret = getItemFromUrl(props.location.search, 'clientSecret')
	const orderId = getItemFromUrl(props.location.search, 'orderId')
	const isFromApp = getItemFromUrl(props.location.search, 'isFromApp', false)

	useEffect(() => {
		setStripePromise(loadStripe(window.REACT_APP_STRIPE_PK, {
			'locale': 'ro',
		}))
		setLoaded(false)
		validatePayment()
	}, [])

	useEffect(() => {
		if (successModal || errorModal) {
			if (!isFromApp) {
				setTimeout(() => {
					setToRedirect(true)
				}, 2000)
			}
		}
	}, [successModal, errorModal])


	const validatePayment = async () => {
		const body = {
			payment_intent_id: clientSecret,
			order_id: orderId,
		}
		try {
			const resp = await axiosRequest.post(`/payments_methods/stripe/validate_auth`, body)
			if (resp) {
				setIsValid(true)
			}
		} catch (err) {
			setErrorModal(true)
		}
	}

	const confirmPayment = async (paymentIntentId = null) => {
		const body = {
			payment_intent_id: paymentIntentId,
			order_id: orderId,
		}
		try {
			const resp = await axiosRequest.post(`/payments_methods/stripe/confirm_auth`, body)
			setSuccessModal(true)
		} catch (err) {
			setErrorModal(true)
		}
	}

	const CompleteWindow = () => {
		const stripe = useStripe();

		const handleAsync = () => {
			if (stripe && !loaded) {
				setLoaded(true)
				stripe.handleCardAction(clientSecret)
					.then((result) => {
						if (result.error) {
							setErrorModal(true)
						} else {
							confirmPayment(result.paymentIntent.id)
						}
					})
					.catch((e) => {
						setErrorModal(true)
					})
			}
		}
		handleAsync()

		return <></>
	};


	if (!isValid) {
		return <PageLoader/>
	}

	if (toRedirect) {
		const url = ROUTE_PAYMENTS + (successModal ? "?successOrder=1" : "")
		return <Redirect to={url}/>
	}

	return <>
		<div className="m-4">
			{
				successModal
				&& <h5 className="color-green">Plata a fost realizata cu succes</h5>
			}
			{
				errorModal
				&& <h5 className="color-red">Ne pare rau, dar plata nu a s-a putut realiza</h5>
			}
		</div>

		<Elements stripe={stripePromise}>
			<CompleteWindow
				confirmPayment={confirmPayment}
			/>
		</Elements>
	</>
}
export default ThreeDSSecurePaymentPage