export const productFormDetailsInitialState = {
	currentStep: 1,
	product: {
		id: null
	},
	description: '',
	clientValue: null,
	files: [],
	publicAddress: {},
	user: {
		name: '',
		email: '',
		phone: '',
		password: '',
	},
}