import {batch} from 'react-redux'

import {HIDE_SPINNER, LOGIN_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {saveToLocalStorage} from "../../utils/storage";
import {showDefaultErrorAction} from "./alertModal.actions";

export const loginActions = (username, password) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.post('/login', {username, password})
		const data = resp.data
		const payload = {
			id: data.id,
			name: data.name,
			email: data.email,
			phone: data.phone,
			image: data.image,
			token: resp.headers['x-api-key']
		}
		batch(() => {
			dispatch({type: LOGIN_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Bine ai revenit in contul tau'}})
		})
		await saveToLocalStorage('user', payload, 60 * 24 * 365)
	} catch (err) {
		dispatch({type: HIDE_SPINNER})
		if (err?.message?.includes('401')) {
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'error', message: 'Emailul sau parola sunt incorecte'}})
		} else {
			dispatch(showDefaultErrorAction(err))
		}
	}
}

export const reAuthAction = payload => async dispatch => {
	dispatch({type: LOGIN_SUCCESS, payload})
}

export const resetPasswordWithLogin = (token, password) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.post('/password_reset_with_login', {token, password})
		const data = resp.data
		const payload = {
			id: data.id,
			name: data.name,
			email: data.email,
			phone: data.phone,
			image: data.image,
			token: resp.headers['x-api-key']
		}
		batch(() => {
			dispatch({type: LOGIN_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
		await saveToLocalStorage('user', payload, 60 * 24 * 365)
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const loginGoogleActions = id_token => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.post('/auth/social', {
			id_token,
			'idpId': 'google'
		})
		const data = resp.data
		const payload = {
			id: data.id,
			name: data.name,
			email: data.email,
			phone: data.phone,
			image: data.image,
			token: resp.headers['x-api-key']
		}
		batch(() => {
			dispatch({type: LOGIN_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
		await saveToLocalStorage('user', payload, 60 * 24 * 365)
		dispatch({type: HIDE_SPINNER})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

