import moment from "moment";
import {getLocale} from "./i18n/utils";
moment.locale(getLocale())

export const getDate = (strDateTime) => {
    if (!strDateTime) {
        return ''
    }
    return strDateTime.split('T')[0]
}

export const getTime = (strDateTime) => {
    if (!strDateTime) {
        return ''
    }
    return strDateTime.substring(11, 16)
}

export const getDayFromDateString = (strDate) => {
    if (!strDate) {
        return ''
    }
    const day = (new Date(strDate)).toLocaleDateString(getLocale(), { weekday: 'long' })
    return day.charAt(0).toUpperCase() + day.slice(1)
}

export const getCardDate = (strDateTime) => {
    return strDateTime.substring(5, 7) + "/" + strDateTime.substring(2, 4)
}

export const getFormatDateTime = () => {
    return "YYYY-MM-DD HH:mm";
}

export const getFormatDate = () => {
    return "YYYY-MM-DD";
}

export const getBookingLocalDateTimeInterval = (booking) => {
    return moment(booking.bookingDate).format("ddd DD MMMM YYYY, ") +
        booking.localStartTime.substr(11, 5) + " - " +
        booking.localEndTime.substr(11, 5);
}

export const getBookingLocalDateTime = (booking) => {
    return booking.bookingDate.substr(0, 10) + " " + booking.localStartTime.substr(11, 5);
}

export const convertToServerDateTime = (strDateTime) => {
    let d = new Date(strDateTime).toLocaleString('en-US', { timeZone: "UTC" });
    d = new Date(d);

    return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
        + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
}

export const getLocalTime = (strDateTime) => {
    return strDateTime.substr(11, 5);
}

export const getTimeFromDuration = (duration) => {
    let time = ''
    if (!duration) {
        return time
    }
    if (duration[0] === '1') {
        time += '1'
    }
    time += duration[1]
    if (duration[3] !== '0') {
        time += ":" + duration[3] + duration[4]
        // const value = (parseInt(duration[3] + duration[4]) * 10.0 / 6).toString()
        // time += "." + value.toString().substring(0, value[1] === '0' ? 1 : 2)
    }
    return time
}