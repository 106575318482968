import React from "react";


export const PageLoader = () => {
	return (
		<></>
		// <div className="loader-container">
		//     <div className="loader-container-inner">
		//         <div className="text-center">
		//             <Loader type="line-scale-pulse-out"/>
		//         </div>
		//         <h6 className="mt-3">
		//             Va rugam sa asteptati...
		//         </h6>
		//     </div>
		// </div>
	)
}