import {batch} from 'react-redux'

import {GET_SUBSCRIPTIONS_SUCCESS, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getSubscriptionsAction = (userId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/subscriptions?user.id=${userId}&sort[createdAt]=desc`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_SUBSCRIPTIONS_SUCCESS, payload})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
