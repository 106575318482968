import React from 'react'
import {Nav} from "react-bootstrap";
import {
    ROUTE_MY_ORDERS,
    ROUTE_PAYMENT_METHODS,
    ROUTE_PAYMENTS,
    ROUTE_PERSONAL_DATA,
    ROUTE_TERMS_AND_CONDITIONS,
    ROUTE_VOUCHERS
} from "../../../../routes/constants";
import {Icon} from "@iconify/react/dist/iconify";

export const AccountPageLinksNav = (props) => {
	const links = [
		{
			route: ROUTE_PERSONAL_DATA,
			text: "Date personale",
			icon: "bx:bxs-user",
		},
		{
			route: ROUTE_MY_ORDERS,
			text: "Comenzile mele",
			icon: "fluent:document-bullet-list-20-filled",
		},
		{
			route: ROUTE_PAYMENTS,
			text: "Plati",
			icon: "fluent:wallet-28-filled",
		},
		{
			route: ROUTE_PAYMENT_METHODS,
			text: "Metode de plata",
			icon: "fluent:payment-16-filled",
		},
		{
			route: ROUTE_VOUCHERS,
			text: "Recompense",
			icon: "ant-design:gift-filled",
		},
		{
			route: ROUTE_TERMS_AND_CONDITIONS,
			text: "Juridic",
			icon: "akar-icons:question-fill",
		},
	]


	return <>
		{
			links.map((item, index) => {
				return <Nav.Link href={item.route} key={index}>
					<Icon icon={item.icon}/>
					{item.text}
				</Nav.Link>
			})
		}
	</>
}

export default AccountPageLinksNav