import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getTransLabel, translate} from "../../../utils/i18n";
import {createBillingDataAction, updateBillingDataAction} from "../../../redux/actions";
import InputField from "../../common/input/InputField";
import {BILLING_DATA_TYPE_COMPANY, getEmptyBillingData, getNormalizedAddress} from "../../../utils/model/billingData";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isEmail, isMessage, isName, isNotEmpty, isPhone} from "../../../utils/validation/conditions";
import {isValidObject} from "../../../utils/validation/validate";

export const BillingDataInput = ({parentBack}) => {

	const [currentBillingData, setCurrentBillingData] = useState(getEmptyBillingData())

	const dispatch = useDispatch()

	const billingData = useSelector(state => state.billingData)
	const user = useSelector(state => state.login)

	const validations = {
		email: useInputValidation(currentBillingData.email, isEmail(currentBillingData.email), translate('validation.email.invalid')),
		name: useInputValidation(currentBillingData.name, isName(currentBillingData.name), translate('validation.name.invalid')),
		phone: useInputValidation(currentBillingData.phone, isPhone(currentBillingData.phone), translate('validation.phone.invalid')),
		address: useInputValidation(currentBillingData.address, isMessage(currentBillingData.address)),
		taxId: useInputValidation(currentBillingData.taxId, isNotEmpty(currentBillingData.taxId)),
		idNumber: useInputValidation(currentBillingData.idNumber, isNotEmpty(currentBillingData.idNumber)),
	}

	useEffect(() => {
		if (billingData?.id) {
			const auxBillingData = Object.assign({}, billingData)
			delete auxBillingData.id
			delete auxBillingData.type
			auxBillingData.address = billingData?.address?.streetAddress ?? ""
			setCurrentBillingData(auxBillingData)
		} else {
			setCurrentBillingData(getEmptyBillingData())
		}

	}, [billingData])

	const updateBillingDataAttribute = (key, value) => {
		setCurrentBillingData({...currentBillingData, [key]: value})
	}

	const onUpdate = () => {
		if (isValidObject(validations)) {
			dispatch(updateBillingDataAction(billingData.id, getNormalizedAddress(currentBillingData)))
		}
	}

	const onCreate = () => {
		if (isValidObject(validations)) {
			dispatch(createBillingDataAction(user.id, getNormalizedAddress({
				...currentBillingData,
				type: BILLING_DATA_TYPE_COMPANY
			})))
		}
	}

	const getInput = (key) => {
		const extraProps = {}
		if (Object.keys(validations).includes(key)) {
			extraProps.validation = validations[key]
		}

		return <Col xs={6} key={key}>
			<InputField
				label={getTransLabel(key, 'billing_data')}
				type="text"
				value={currentBillingData[key]}
				setValue={(value) => updateBillingDataAttribute(key, value)}
				{...extraProps}
			/>
		</Col>
	}

	return (
		<>
			<Row>
				{
					Object.keys(currentBillingData).map((key) => {
						return getInput(key)
					})
				}
			</Row>

			<div>
				{
					parentBack
					&& <button className="button-back" onClick={parentBack}>
						{"< Inapoi"}
					</button>
				}
				{
					billingData?.id
						? <button className="button-gradient-primary" onClick={onUpdate}>
							Actualizeaza
						</button>
						: <button className="button-gradient-primary" onClick={onCreate}>
							Salveaza
						</button>
				}
			</div>

		</>
	)
}
export default BillingDataInput