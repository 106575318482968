import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

import GuestTemplate from "../GuestTemplate";
import "../../../assets/css/guest/leagal.scss"

export const TermsAndConditionsPage = () => {
	return (
		<GuestTemplate label="terms" title="Termeni si conditii" image={null}>
			<Container>
				<Row>
					<Col sm={12} className="terms-content">
						<p>Prezentele condiții de utilizare („Condițiile”) reglementează accesarea și utilizarea de
							către dumneavoastră, persoană fizică sau juridică, a website-ului, aplicației mobile,
							produselor și serviciilor (denumite în mod colectiv „Serviciile”) puse la dispoziție de
							Zumzi („Zumzi”), o societate cu răspundere limitată, CASTA CLEAN TECH S.R.L., înființată în
							România, cu sediul în Municipiul Pitești, Bulevardul Republicii, nr. 222, Județul Argeș,
							înregistrată la Registrul Comerțului cu numărul J3/1866/2019.
						</p>
						<br/><br/>
						<p className="text-center bg-color-sec pt-2 pb-2">VĂ RUGĂM SĂ CITIȚI CU ATENȚIE ACESTE CONDIȚII
							ÎNAINTE DE A ACCESA SAU UTILIZA SERVICIILE.</p>
						<br/><br/>
						<p>
							Accesarea și utilizarea de către dumneavoastră a Serviciilor constituie acordul
							dumneavoastră de a respecta aceste Condiții, fapt ce dă naștere unei relații contractuale
							între dumneavoastră și Zumzi. Dacă nu sunteți de acord cu aceste Condiții, nu puteți accesa
							sau utiliza Serviciile. Prin acordul dumneavoastră de a respecta aceste Condiții, declarați
							și garantați că aveți 18 ani și capacitatea necesară pentru a încheia un astfel de acord. În
							cazul în care încheiați acest acord în numele unei persoane juridice, declarați și garantați
							că aveți autoritatea de a acționa în numele acelei entități și de a o angaja în prezentul
							acord.
							<br/><br/>
							În cazul în care anumite Servicii determină existența unor Condiții suplimentare (de exemplu
							politici pentru un anumit eveniment, o anumită activitate sau promoție), respectivele
							condiții suplimentare vă vor fi prezentate, ele se adaugă acestor Condiții și vor fi
							considerate parte integrantă din acestea. Condițiile suplimentare vor prevala față de aceste
							Condiții în cazul unui conflict cu privire la Serviciile aplicabile.
							<br/><br/>
							Zumzi poate modifica periodic prezentele Condiții. Modificările vor intra în vigoare în
							momentul publicării respectivelor Condiții actualizate în acest loc. Accesarea sau
							utilizarea în continuare de către dumneavoastră a Serviciilor după respectiva publicare
							constituie acordul dumneavoastră de a respecta Condițiile modificate.
						</p>
						<br/>

						<ol style={{'padding': '0'}}>
							<li>Servicii
								<p>Zumzi, prin intermediul aplicației pentru mobil (denumite împreună „Platforma
									Zumzi”), permite conectarea persoanelor care doresc să beneficieze de servicii
									pentru casa (Utilizatorii) cu persoanele care doresc să presteze astfel de servicii
									(„Profesioniști terți” sau „Colaboratori”). În acest ultim caz, Zumzi intermediază
									(stabilește și programează) prestarea serviciilor de între Utilizatori și
									Profesioniști terți/Colaboratori, cu ajutorul Platformei Zumzi. Indiferent de
									calitatea celui care prestează în mod efectiv serviciile, respectiv Profesionist
									terț/Colaborator (denumiți împreună „Agenți”), tipul, modalitatea de utilizare și de
									prestare a serviciilor pentru casa, precum și calitatea acestora este aceeași, acest
									aspect fiind relevant numai în relația dintre Zumzi și Agenți nu și în relația
									dintre Zumzi și Utilizatori.
									<br/><br/>
									Descrierea detaliată a serviciilor prestate se regăsește pe Platforma Zumzi
								</p>
							</li>
							<li>Utilizarea Serviciilor de către dumneavoastră
								<p><u>Plasarea unei comenzi.</u> Pentru a beneficia de servici trebuie să plasați o
									comandă prin intermediul Platformei Zumzi, prin completarea unui formular de
									comandă. Deși conținutul formularului poate varia, completarea acestuia presupune
									întotdeauna transmiterea către Zumzi a anumitor informații cu caracter personal, cum
									ar fi: numele, adresa, numărul de telefon, adresa de email. Orice informație cu
									caracter personal furnizată în continuare va fi supusă condițiilor din Politica de
									confidențialitate.
									<br/><br/>
									<u>Contul de utilizator.</u> Pentru simplificarea procesului de comandă vă puteți
									crea și menține un cont personal activ de utilizator al Serviciilor, datele
									dumneavoastră fiind astfel salvate, fără a mai fi necesară introducerea lor pentru
									următoarele comenzi.
									<br/><br/>
									Vă obligați să vă asigurați că informațiile din contul dumneavoastră sunt corecte și
									complete și că veți face actualizările necesare în cazul în care datele
									dumneavoastră se modifică. Dacă furnizați orice informații care sunt neadevărate,
									inexacte sau incomplete, Zumzi poate suspenda sau înceta dreptul dumneavoastră de a
									accesa și utiliza Serviciile.
									<br/><br/>
									Sunteți singurul utilizator autorizat al contului dumneavoastră și vă asumați
									responsabilitatea pentru păstrarea confidențialității datelor de acces în Contul
									dumneavoastră. Sunteți singurul responsabil pentru toate activitățile desfășurate
									prin Contul dumneavoastră de utilizator cu excepția faptului că Zumzi poate, în
									anumite circumstanțe, să acceseze Contul pentru a efectua modificări pe care le
									solicitați. Dacă suspectați desfășurarea unei activități neautorizate prin Contul
									dumneavoastră sau orice altă încălcare a securității, trebuie să ne contactați
									imediat la <a href="tel:+40746639059">0746 639 059</a>
									<br/><br/>
									<u>Comunicarea cu Zumzi.</u> Prin crearea unui Cont, sunteți de acord că Zumzi vă
									poate apela sau trimite mesaje SMS sau email-uri pentru a facilita prestarea
									Serviciilor, în cadrul operațiunilor normale asociate cu utilizarea de către
									dumneavoastră a Serviciilor. Puteți opta oricând să nu primiți mesaje SMS sau
									emailuri de la Zumzi, trimițând pe adresa <a
										href="mailto:hello@zumzi.com">hello@zumzi.com</a> un email în care să precizați
									că nu mai doriți să primiți aceste mesaje, care să includă numărul de telefon sau
									adresa de email pe care sunt primite mesajele.
									<br/><br/>
									Dumneavoastră puteți contacta Zumzi în legătură cu orice nemulțumire, sugestie sau
									orice alt aspect legat utilizarea Serviciilor prin formularul de contact disponibil
									pe site, la adresa de email sau la numărul de telefon care se regăsesc pe Platformă.
									De asemenea, veți putea contacta direct Agentul la numărul de telefon care vă va fi
									furnizat.
									<br/><br/>
									Zumzi poate să vă permită periodic să publicați sau să puneți la dispoziția Zumzi,
									prin intermediul Platformei, conținut și informații în format text, audio și/sau
									vizual, inclusiv comentarii și opinii despre Servicii. Întregul conținut furnizat de
									către dumneavoastră ca utilizator va rămâne proprietatea dumneavoastră Totuși, prin
									furnizarea de conținut către Zumzi dumneavoastră ca utilizator, acordați Zumzi
									dreptul de a utiliza, a copia, a modifica, a crea opere derivate, a distribui, a
									afișa public, a reprezenta public și a exploata în orice alt mod respectivul
									conținut furnizat de utilizator în toate formatele și pe toate canalele de
									distribuție, fără a vă informa și fără a fi necesar acordul dumneavoastră, și fără a
									fi nevoie să vă plătească fie pe dumneavoastră, fie o altă persoană fizică sau
									juridică.
									<br/><br/>
									Vă obligați să nu transmiteți un conținut cu caracter denigrator, calomnios, care
									incită la ură, violent, obscen, pornografic, ilegal sau jignitor. Zumzi are
									posibilitatea, dar nu și obligația, de a examina, a monitoriza sau a șterge
									conținutul furnizat de utilizatori, la latitudinea exclusivă a Zumzi, în orice
									moment, indiferent de motiv, fără a vă anunța.
									<br/><br/>
									<u>Accesul la rețea și dispozitivele.</u> Vă revine responsabilitatea de a obține
									accesul la rețeaua de date necesar pentru a utiliza Serviciile. Se pot aplica
									tarifele și prețurile percepute de rețeaua dumneavoastră mobilă pentru transferul de
									date și mesaje, dacă accesați sau utilizați Serviciile de pe un dispozitiv care
									permite comunicarea wireless, și vă revine responsabilitatea de a achita aceste
									tarife și prețuri. Vă revine responsabilitatea de a procura și a actualiza
									echipamentele hardware sau dispozitivele necesare pentru a accesa și a utiliza
									Serviciile prin intermediul Platformei Zumzi precum și de a procura orice
									actualizări ale acestora. Zumzi nu garantează că Serviciile sau o porțiune a
									acestora vor funcționa pe un anumit echipament hardware sau un anumit dispozitiv. În
									plus, Serviciile pot fi afectate de defecțiuni și întârzieri inerente în utilizarea
									internetului și a comunicațiilor electronice.
									<br/><br/>
									<u>Desfășurarea activității.</u> Deplasarea Agentului la sediul dumneavoastră se va
									face la adresa și în intervalul orar indicate de către dumneavoastră, cu condiția ca
									acestea să respecte acoperirea teritorială și intervalul orar de funcționare
									înscrise pe site.
									<br/><br/>
									Va obligați să asigurați accesul Agentului în imobil și agreați o întârziere din
									partea acestuia de 30 de minute. Vă obligați să îi prezentați Agentului la sosire
									zonele care doriți să fie curățate și să îi aduceți la cunoștință toate sursele de
									pericol existente în imobil. Trebuie să aveți o conduită decentă în prezența
									Agentului, fără comportamente ofensatoare în orice mod sau de natură să-l împiedice
									să-și desfășoare activitatea. Agentul poate refuza activități care îi pun în pericol
									sănătatea și/sau integritatea corporală.
									<br/><br/>
									Va obligați să fiți prezent la finalul sedintei și să vă asigurați că imobilul a
									fost predat în mod corespunzător, fără lipsuri, iar activitatea s-a desfășurat
									potrivit cerințelor dumneavoastră.
								</p>
							</li>
							<li>Plata
								<p>Utilizarea serviciilor determină costuri pe care trebuie să le achitați. Prețurile
									sunt cele afișate pe Platformă, iar acestea pot fi modificate în orice moment, fără
									ca modificarea să aibă efect asupra comenzilor deja plasate.
									<br/><br/>
									Plasarea comenzii și implicit prestarea serviciilor sunt condiționate de efectuarea
									plății.
									<br/><br/>
									Plata se va efectua exclusiv online, prin procesatorul de plati <a
										href="https://stripe.com" target="_blank" rel="noopener noreferrer">stripe</a>.
									<br/><br/>
									Prețurile pot crește substanțial în perioadele cu cerere mare. Zumzi va depune
									diligențele necesare pentru a vă informa cu privire la prețurile aplicabile.
									<br/><br/>
									Zumzi nu alocă nicio porțiune din plata dumneavoastră drept bacșiș pentru Agenți.
									Înțelegeți și conveniți că, deși sunteți liber să oferiți sume suplimentare drept
									bacșiș oricărui Agent care vă furnizează serviciile, nu aveți nicio obligație de a
									da bacșiș. Aceste gratuități sunt voluntare.
								</p>
							</li>
							<li>Politica de anulare a comenzii
								<p>Utilizatorul poate anula comanda efectuată cu cel puțin 24 de ore înainte de data și
									ora stabilită pentru prestarea serviciului. În acest caz, vi se va rambursa prețul
									plătit pentru serviciul de ales. Dacă veți anula comanda cu mai puțin de 24 de ore
									înainte de data și ora stabilită pentru prestarea serviciului dar nu mai puțin de 2
									ore, vi se va percepe o taxă de anulare în valoare de 20%. Dacă veți anula comanda
									cu mai puțin de 2 ore înainte de data și ora stabilită pentru prestarea serviciului,
									nu veți beneficia de rambursarea prețului plătit.
									<br/><br/>
									Dacă doriți încetarea abonamentului dumneavoastră, anularea va intra în vigoare la
									finalul lunii de abonament în curs.
									<br/><br/>
									În cazul în care Agentul ales de dumneavoastră devine indisponibil, Zumzi va depune
									toate diligențele pentru asigurarea unui alt Agent în intervalul orar ales de
									dumneavoastră, iar în subsidiar reprogramarea activității. Dacă nu sunteți de acord
									cu niciuna dintre variantele propuse, dumneavoastră sau Zumzi poate anula comanda cu
									rambursarea prețului plătit.
									<br/><br/>
									În cazul în care a intervenit o eroare de preț, iar dumneavoastră nu puteți fi
									contactat pentru a fi înștiințat cu privire la acest aspect, sau dacă nu doriți să
									reconfirmați comanda după luarea la cunoștință a prețului corect, Zumzi poate anula
									comanda cu rambursarea prețului plătit.
								</p>
							</li>
							<li>Răspunderea
								<p>Zumzi nu este responsabilă pentru inadvertențe, erori sau omisiuni în cadrul
									informațiilor furnizate de către Agenții precum si pentru corectitudinea si
									exactitatea acestor informații.
									<br/><br/>
									Zumzi nu va fi ținută responsabilă pentru nicio acțiune judiciară sau
									extrajudiciară, care provine ca urmare a utilizării incorecte sau frauduloase a
									Serviciilor. Pentru caz de forță majoră, Zumzi și Agenții sunt exonerați total de
									răspundere.
									<br/><br/>
									În ceea ce privește serviciile furnizate, Zumzi nu își asumă nicio răspundere pentru
									situațiile independente de voința reprezentanților și/sau a Agenților, incluzând dar
									fără a se limita la: impedimente de forță majoră, războaie, acte de terorism,
									proteste, răscoale, tulburări civile, incendii, explozii, inundații, epidemii,
									greve, condiții meteo nefavorabile și altele asemenea.
									<br/><br/>
									Zumzi răspunde pentru prejudiciile materiale cauzate de Agenții, numai dacă acestea
									se datorează neglijenței sau imprudenței, în limita a 5000 de lei. Răspunderea Zumzi
									va fi angajată în acest caz numai în situația în care prejudiciul este adus la
									cunoștința Zumzi în termen de 72 de ore de la data și ora stabilită pentru
									desfășurarea activității. După acest interval, Zumzi nu va mai putea fi ținută
									responsabilă pentru nici un prejudiciu.
									<br/><br/>
									Luați la cunoștință și sunteți de acord că Zumzi nu este responsabilă pentru niciun
									fel de prejudiciu cert sau eventual, în legătură cu utilizarea de către
									dumneavoastră a serviciilor, cu excepția răspunderii detaliate în clauza imediat
									anterioară.
									<br/><br/>
									În cazul în care Agenții Colaboratori nu au efectuat instructajul PSI sau SSM, Zumzi
									nu poate fi răspunzătoare pentru neîndeplinirea acestor obligații prevăzute de lege.
								</p>
							</li>
							<li>Dispoziții diverse
								<p><u>Proprietatea.</u> Serviciile și toate drepturile asociate cu acestea sunt și vor
									rămâne proprietatea Zumzi. Conținutul acestui site nu poate fi utilizat, reprodus,
									distribuit, transmis, expus, in alte scopuri decât cele expres și legal permise.
									Extragerea oricăror informații urmată de orice utilizare în scop comercial care
									depășește sfera copiei private reglementate de lege sau pentru vânzare ori
									licențiere și fără a avea în prealabil un consimțământ scris al titularilor
									drepturilor de proprietate, constituie o încălcare a termenilor și condițiilor.
									<br/><br/>
									<u>Restricții.</u> Vă sunt interzise următoarele: (i) să îndepărtați marcajele
									privind drepturile de autor, mărcile comerciale sau alte drepturi de proprietate de
									pe vreo porțiune a Serviciilor; (ii) să reproduceți, să modificați, să realizați
									opere derivate, să distribuiți, să licențiați, să închiriați, să vindeți, să
									revindeți, să transferați, să afișați public, să reprezentați public, să
									transmiteți, să faceți streaming, să difuzați sau să exploatați în orice alt mod
									Serviciile, cu excepțiile permise în mod expres de Zumzi; (iii) să decompilați, să
									refaceți prin inginerie inversă sau să dezasamblați Serviciile, cu eventualele
									excepții permise de legislația aplicabilă; (iv) să faceți linkuri, mirror-uri sau
									frame-uri către vreo porțiune a Serviciilor; (v) să determinați sau să lansați
									programe sau scripturi care fac scraping, indexează, analizează sau efectuează orice
									alte operațiuni de extragere de date din vreo porțiune a Serviciilor, sau care
									îngreunează sau împiedică operarea și/sau funcționalitatea oricărui aspect al
									Serviciilor; sau (vi) să încercați să obțineți accesul neautorizat sau să afectați
									vreun aspect al Serviciilor sau al sistemelor sau rețelelor corelate cu acestea.
									<br/><br/>
									<u>Legislația aplicabilă și jurisdicția.</u> Utilizarea serviciilor de către
									dumneavoastră este guvernată de legislația română.
									<br/><br/>
									Orice litigii apărute între dumneavoastră și Zumzi vor fi soluționate pe cale
									amiabilă sau, în cazul în care acest lucru nu va fi posibil, litigiile vor fi
									soluționate de către instanțele judecătorești competente.
								</p>
							</li>
						</ol>
					</Col>
				</Row>
			</Container>
		</GuestTemplate>
	)
}
export default TermsAndConditionsPage