import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useWindowResize} from "../../../utils/hooks/useWindowResize";
import {useHistory} from "react-router-dom";

export const OrderDetailsItem = ({model}) => {
    const {productCode, attributes, serviceName, isCancelled, data, url}  = model

    const {width} = useWindowResize()
    const history = useHistory();
    const [image, setImage] = useState(null)

    useEffect(() => {
        try {
            const img = require(`../../../assets/images/new/services/icons/${productCode}.svg`)
            if (img) {
                setImage(img)
            }
        } catch (e) {}
    }, [productCode])

    const onDetailsClick = () => {
        if (!isCancelled) {
            history.push(url + "?id=" + data.id)
        }
    }

    const ActionContainer = () => {
        return (
            <div className={"my-orders-bottom-container " + (isCancelled ? "canceled" : "")} onClick={onDetailsClick}>
                {isCancelled ? "Anulat" : "Detalii"}
            </div>
        )
    }
    if (!image) {
        return null
    }

    return (
        <Row className="my-order-item-container">
            <Col lg={2} className="my-orders-image-container">
                <div>
                    {
                        productCode
                            ? <img src={image}  alt="serviciu"/>
                            : null
                    }
                    <h6>
                        {serviceName}
                    </h6>
                </div>

                {
                    width > 991
                    ? <ActionContainer/>
                    : null
                }



            </Col>
            <Col lg={10} className="my-orders-details-container">
                <div>
                    {
                        attributes.map((attribute, key) => {
                            return <p key={key}>
                            <span className="font-weight-bold" style={{marginRight: '0.5rem'}}>
                                {attribute.label}:
                            </span>
                                {attribute.value}
                            </p>
                        })
                    }
                </div>
                {
                    width <= 991
                        ? <ActionContainer/>
                        : null
                }

            </Col>
        </Row>
    )
}
export default OrderDetailsItem
