import React from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import Vouchers from "./Vouchers";

export const VouchersPage = (props) => {
    return (
        <AccountTemplate title="Recompense">
            <Vouchers/>
        </AccountTemplate>
    )
}
export default VouchersPage