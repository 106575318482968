import {batch} from 'react-redux'

import {GET_CITIES_SUCCESS, GET_COMPLEXES_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";
import {ROUTE_DASHBOARD} from "../../routes/constants";
import {getUserNeighbourhoodActions} from "./userNeighbourhood.actions";

export const getCitiesActions = () => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get('/cities')
		const payload = resp.data.map(el => ({
			id: String(el.id),
			name: el.name,
			availableForServices: el.availableForServices
		}))
		batch(() => {
			dispatch({type: GET_CITIES_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const getComplexesActions = cityId => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/cities/${cityId}/public_addresses`)
		const payload = resp.data.map(el => ({
			id: String(el.id),
			name: el.name,
			address: el.address.streetAddress
		}))
		batch(() => {
			dispatch({type: GET_COMPLEXES_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}


export const setCustomComplexActions = (obj, userId, keepSpinnerShowing = false) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.post('/public_addresses?addToNeighbourhood=1', obj)
		if (!keepSpinnerShowing) {
			await dispatch(getUserNeighbourhoodActions(userId))
		}

		batch(() => {
			if (!keepSpinnerShowing) {
				dispatch({type: HIDE_SPINNER})
				dispatch({
					type: SHOW_ALERT_MODAL,
					payload: {type: 'success', message: 'Adresa adaugata cu succes', route: ROUTE_DASHBOARD}
				})
			}
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}