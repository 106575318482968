import {batch} from 'react-redux'

import {GET_PRODUCT_FORM_REQUEST_SUCCESS, HIDE_SPINNER, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";
import {getProductFormAction} from "./productForm.actions";

export const getProductFormRequestByIdAction = (productFormRequestId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/product_form_requests/${productFormRequestId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_FORM_REQUEST_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const rejectUserProductFormRequestAction = (productFormRequest) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/product_form_requests/${productFormRequest.id}/user/cancel`, {})
		await dispatch(getProductFormAction(productFormRequest?.productForm.id))

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Propunere respinsa'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}


export const setProductFormRequestAction = (productFormRequest) => async dispatch => {
	dispatch({type: GET_PRODUCT_FORM_REQUEST_SUCCESS, payload: productFormRequest})
}

export const resetProductFormRequestAction = () => async dispatch => {
	dispatch({type: GET_PRODUCT_FORM_REQUEST_SUCCESS, payload: {}})
}