import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {batch, useDispatch} from "react-redux";
import {SHOW_ALERT_MODAL, SHOW_SPINNER} from "../../../redux";
import {axiosRequest} from "../../../api/axiosRequest";
import {getTransLabel} from "../../../utils/i18n";
import {hideModalPopup, showDefaultErrorAction} from "../../../redux/actions";
import InputField from "../../common/input/InputField";

export const PropertyData = ({property, toSubmit, parentSubmit, parentBack}) => {

	const [currentProperty, setCurrentProperty] = useState(Object.assign({}, property))
	delete currentProperty.id;
	const dispatch = useDispatch()

	const onSubmit = async () => {
		dispatch({type: SHOW_SPINNER})
		try {
			await axiosRequest.put(`/properties/${property.id}`, currentProperty)

			batch(() => {
				dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Date actualizate'}})
				dispatch(hideModalPopup())
			})
			window.location.reload()
		} catch (err) {
			dispatch(showDefaultErrorAction(err))
			dispatch(hideModalPopup())

		}
	}

	const updateProperty = (key, value) => {
		setCurrentProperty({...currentProperty, [key]: value})
	}

	const getInput = (key) => {
		return <Col xs={6} key={key}>
			<InputField
				label={getTransLabel(key, 'property')}
				type="text"
				value={currentProperty[key]}
				setValue={(value) => updateProperty(key, value)}
			/>
		</Col>
	}

	return (
		<>
			<Row>
				{
					Object.keys(currentProperty).map((key) => {
						return getInput(key)
					})
				}
			</Row>

			{
				toSubmit
				&& <div className="text-center">
                    <span className="button-medium button-small-save" style={{marginRight: '8px'}} onClick={onSubmit}>
                        Actualizeaza
                    </span>
				</div>
			}
			<div>
				{
					parentBack
					&& <button className="button-back" onClick={parentBack}>
						{"< Inapoi"}
					</button>
				}
				{
					parentSubmit
					&& <button className="button-gradient-primary" onClick={() => parentSubmit(currentProperty)}>
						Continua
					</button>
				}
			</div>

		</>
	)
}
export default PropertyData