import React from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import UserCards from "./UserCards";

export const UserCardsPage = (props) => {

    return (
        <AccountTemplate title="Metode de plata">
                <UserCards/>
        </AccountTemplate>
    )
}
export default UserCardsPage