import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropertyData from "../../personalData/PropertyData";
import {SET_ORDER_DETAILS_SUCCESS} from "../../../../redux";
import {decreaseOrderDetailsStepAction} from "../../../../redux/actions";


export const Step2 = (props) => {

	const dispatch = useDispatch()
	const orderDetails = useSelector(state => state.orderDetails)
	const userNeighbourhood = useSelector(state => state.userNeighbourhood)
	const [property, setProperty] = useState(orderDetails.property)

	useEffect(() => {
		if (userNeighbourhood?.userPublicAddress) {
			setProperty(userNeighbourhood.userPublicAddress.address.property)
		}
	}, [userNeighbourhood])
	const onSubmitNextStep = (property) => {
		dispatch({
			type: SET_ORDER_DETAILS_SUCCESS,
			payload: {...orderDetails, currentStep: orderDetails.currentStep + 1, property}
		})
	}
	return (
		<div>
			<PropertyData
				property={property}
				toSubmit={false}
				parentSubmit={onSubmitNextStep}
				parentBack={() => dispatch(decreaseOrderDetailsStepAction(orderDetails))}
			/>
		</div>
	)
}
export default Step2