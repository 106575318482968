import {batch} from 'react-redux'

import {GET_PRODUCT_FORM_SUCCESS, HIDE_SPINNER, LOGIN_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {trackEvent} from "../../utils/analytics";
import {ROUTE_MY_PRODUCT_FORM} from "../../routes/constants";
import {showDefaultErrorAction} from "./alertModal.actions";
import {loadFromLocalStorage} from "../../utils/storage";

export const getProductFormAction = (productFormId) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get(`/product_forms/${productFormId}`)
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_FORM_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updateProductFormAction = (productFormId, key, value) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.put(`/product_forms/${productFormId}`, {[key]: value})
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PRODUCT_FORM_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Date actualizate', type: 'success'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}
export const createProductFormAction = (description, productId, userId, clientValue, files, toLoadUserToken = false) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {

		const body = {
			product: {
				id: productId
			},
			description,
			clientValue: clientValue ? clientValue.toString() : null
		}
		const responseProductForm = await axiosRequest.post(`/users/${userId}/product_forms`, body)
		await dispatch(setFiles(responseProductForm.data.id, [], files))

		trackEvent('SubmitApplication')
		if (toLoadUserToken) {
			await dispatch({
				type: LOGIN_SUCCESS,
				payload: {
					...loadFromLocalStorage('user'),
					goToPage: ROUTE_MY_PRODUCT_FORM + "?id=" + responseProductForm.data.id
				}
			})
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					message: 'Mesajul a fost trimis cu succes',
					type: 'success',
				}
			})
			return
		}

		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {
					message: 'Mesajul a fost trimis cu succes',
					type: 'success',
					route: `${ROUTE_MY_PRODUCT_FORM}?id=${responseProductForm.data.id}`
				}
			})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const setProductFormFilesAction = (productForm, files, newFiles) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await dispatch(setFiles(productForm?.id, files, newFiles))
		await axiosRequest.put(`/product_forms/${productForm?.id}`, {description: productForm?.description})
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: 'Date actualizate', type: 'success'}})
			dispatch(getProductFormAction(productForm?.id))
		})

	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}

}

const setFiles = (productFormId, files, newFiles) => async dispatch => {
	let toBreak = false

	// create media object entity foreach image/video/audios
	if (newFiles?.length) {
		await Promise.all(newFiles.map(async (file, index) => {
			const formData = new FormData();
			formData.append("file", file);
			const resp = await axiosRequest.post('/media_objects', formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			if (!resp?.data?.id) {
				toBreak = file.mediaType.charAt(0).toUpperCase() + file.mediaType.slice(1)
				return
			}
			newFiles[index].id = resp.data.id
		}))
	}

	if (toBreak) {
		batch(() => {
			dispatch({type: HIDE_SPINNER})
			dispatch({
				type: SHOW_ALERT_MODAL,
				payload: {message: `${toBreak} - tipul de date incarcat nu este acceptat. Va rugam sa incarcati alte fisier`}
			})
		})
		return
	}

	// foreach media type, set media object to product form
	const dataTypes = ['images', 'videos', 'audios']
	await Promise.all(dataTypes.map(async (dataType) => {
		const filteredFiles = newFiles.filter((file) => {
			return (file.mediaType + 's') === dataType
		})
		if (files && files[dataType.substring(0, dataType.length - 1)]) {
			files[dataType.substring(0, dataType.length - 1)].forEach((file) => {
				filteredFiles.push(file)
			})
		}

		if (filteredFiles.length) {
			const finalFiles = filteredFiles.map((file) => {
				return {id: file.id}
			})
			await axiosRequest.put(`/product_forms/${productFormId}/${dataType}`, {
				listing: {
					[dataType]: finalFiles
				}
			})
		} else {
			await axiosRequest.put(`/product_forms/${productFormId}/${dataType}`, {
				listing: {
					[dataType]: []
				}
			})
		}
	}))
}