import {GET_SUBSCRIPTIONS_SUCCESS, LOGOUT_RESET} from "../constants";

const initialState = null

export const subscriptions = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}