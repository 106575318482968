import React, {useEffect, useState} from "react";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {useDispatch, useSelector} from "react-redux";
import {
	createProductFormAction,
	getProductActions,
	getProductFormAction,
	setProductFormFilesAction,
	showModalPopup,
	updateProductFormAction
} from "../../../redux/actions";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {isDecimalNumber, isMessage} from "../../../utils/validation/conditions";
import {translate} from "../../../utils/i18n";
import {isValidObject} from "../../../utils/validation/validate";
import Attachments from "./Attachments";
import {GET_PRODUCT, GET_PRODUCT_FORM_SUCCESS} from "../../../redux";
import InputField from "../../common/input/InputField";
import {Redirect} from "react-router-dom";
import {ROUTE_ORDER_STEP} from "../../../routes/constants";
import {getAddressFromListing} from "../../../utils/model/listing";
import {Form} from "react-bootstrap";
import {setProductFormSpecificDetailsAction} from "../../../redux/actions/productFormDetails.actions";
import {getStatusFromProductForm} from "../../../utils/model/productForm";
import SeeDetailsButton from "../../common/buttons/SeeDetailsButton";
import ProductFormRequests from "../productFormRequest/ProductFormRequests";
import {getProductName} from "../../../utils/model/product";


export const ProductFormInput = ({isLocalSave = false}) => {
	const [description, setDescription] = useState('')
	const [clientValue, setClientValue] = useState('')
	const [editable, setEditable] = useState(false)
	const validations = {
		description: useInputValidation(description, isMessage(description), translate('validation.message.invalid')),
		clientValue: useInputValidation(clientValue, isDecimalNumber(clientValue) || clientValue.length === 0, translate('validation.price.invalid')),
	}
	const [disabledObject, setDisabledObject] = useState({
		description: true,
		clientValue: true,
	})

	const user = useSelector(state => state.login)

	const dispatch = useDispatch()
	const productFormId = getItemFromUrl(window.location.search, 'productFormId')
	const productForm = useSelector(state => state.productForm)
	const productFormDetails = useSelector(state => state.productFormDetails)

	const productId = getItemFromUrl(window.location.search, 'productId')
	const toShowRequests = getItemFromUrl(window.location.search, 'toShowRequests', null)

	const product = useSelector(state => state.product)

	useEffect(() => {
		if (productFormId) {
			dispatch(getProductFormAction(productFormId))
		} else if (productId) {
			dispatch({type: GET_PRODUCT_FORM_SUCCESS, payload: {}})
			dispatch(getProductActions(productId))
		}
		mapFromProductFormDetails()

		// if (toShowRequests) {
		// 	onShowRequests()
		// }

		return () => {
			dispatch({type: GET_PRODUCT, payload: {}})
			dispatch({type: GET_PRODUCT_FORM_SUCCESS, payload: {}})
		}
	}, [])

	useEffect(() => {
		if (productForm?.id) {
			dispatch({type: GET_PRODUCT, payload: productForm.product})
			setDescription(productForm.description)
			setEditable(true)
			if (productForm.clientValue) {
				setClientValue(productForm.clientValue)
			}
		}
	}, [productForm])

	const mapFromProductFormDetails = () => {
		if (isLocalSave && productFormDetails?.description) {
			setDescription(productFormDetails?.description)
			setClientValue(productFormDetails?.clientValue)
		}
	}

	const resetValues = (key) => {
		if (productForm?.id) {
			setDescription(productForm.description ?? '')
			setClientValue(productForm.clientValue ?? '')
		}
		toggleDisableInput(key)
	}

	const toggleDisableInput = (key) => {
		setDisabledObject({...disabledObject, [key]: !disabledObject[key]})
	}

	const onSubmitUpdate = (key, value) => {
		if (!validations[key].inputCheckError()) {
			dispatch(updateProductFormAction(productForm.id, key, value))
			toggleDisableInput(key)
		}
	}

	const onLocalSaveNew = (files) => {
		if (isValidObject(validations)) {
			dispatch(setProductFormSpecificDetailsAction(productFormDetails, description, product.id, clientValue, files))
		}
	}

	const onSubmitNew = (files) => {
		if (isValidObject(validations)) {
			if (!product.canUserSetPrice) {
				dispatch(createProductFormAction(description, product.id, user?.id, null, files))
			} else {
				dispatch(createProductFormAction(description, product.id, user?.id, clientValue, files))
			}
		}
	}

	const onSubmitUpdateFiles = (files, newFiles) => {
		dispatch(setProductFormFilesAction(productForm, files, newFiles))
	}

	const onSubmit = (files, newFiles) => {
		if (isLocalSave) {
			onLocalSaveNew(files)
			return
		}
		if (!productForm?.id) {
			onSubmitNew(files)
		} else {
			onSubmitUpdateFiles(files, newFiles)
		}
	}

	const onShowRequests = () => {
		dispatch(showModalPopup("Propuneri din partea agentilor", <ProductFormRequests goToEditText={() => toggleDisableInput('description')}/>, null, 'lg'))
	}

	const getExtraProps = (key, value) => {
		if (!editable) {
			return {}
		}
		return {
			onUpdate: () => onSubmitUpdate(key, value),
			onCloseEdit: () => resetValues(key),
			disabledObject: disabledObject[key],
			toggleDisabledInput: () => toggleDisableInput(key)
		}
	}

	if (product) {
		if (product.fixedPrice === true) {
			return <Redirect to={`${ROUTE_ORDER_STEP}?productId=${product.id}`}/>
		}
	}

	return (
		<>
			<div className="account-medium-container">
				<h4 className="account-title">
					{getProductName(product)}
				</h4>
				{
					!productForm?.id
						? <>
							<p className="mb-4">
								{translate('product.' + product?.code + ".label.desc")}
							</p>
							{
								product?.startingPrice
									? <Form.Label className="mb-4">
										Pret:
										<span className="font-weight-normal">
											{" "}de la{" "}{product?.startingPrice} {product.currency}
										</span>
									</Form.Label>
									: null
							}
						</>
						: null
				}
				{
					productForm?.id
						? <Form.Label className="mb-4 w-100">
							Status:{" "}
							<span className="font-weight-normal">
								{getStatusFromProductForm(productForm)}{" "}
							</span>
							{/*{*/}
							{/*	productForm.numberOfPendingRequests*/}
							{/*	? <SeeDetailsButton*/}
							{/*		onClickButton={onShowRequests}*/}
							{/*		/>*/}
							{/*	: null*/}
							{/*}*/}
						</Form.Label>
						: null
				}
				{
					productForm?.id
						? <Form.Label className="mb-4">
							Adresa:{" "}
							<span className="font-weight-normal">
								{getAddressFromListing(productForm?.listing, true)}
							</span>
						</Form.Label>
						: null
				}
				<div className="mb-4">
					<InputField
						label="Cum te putem ajutam?"
						placeholder={translate('product.' + product?.code + '.placeholder')}
						type="text"
						value={description}
						setValue={setDescription}
						validation={validations.description}
						isTextarea={true}
						maxLength={700}
						rows={4}

						{...getExtraProps('description', description)}

					/>
				</div>

				<div className="mb-4">
					{
						product.canUserSetPrice
						&&
						<InputField
							label="Ce buget ai pentru asta?"
							type="text"
							value={clientValue}
							setValue={setClientValue}
							validation={validations.clientValue}
							icon="RON"

							{...getExtraProps('clientValue', clientValue)}
						/>
					}
				</div>
			</div>

			<Attachments
				isNewData={!!productForm?.id}
				isLocalSave={isLocalSave}
				onSubmit={onSubmit}
			/>
		</>
	)
}
export default ProductFormInput