import React from 'react';

import Dropzone from 'react-dropzone';
import FileItem from "./FileItem";
import {Icon} from "@iconify/react/dist/iconify";
import {colors} from "../../../utils/style/style";

const typeIcon = {
    'audio': 'fluent:mic-on-20-filled',
    'image': 'ic:baseline-photo-camera',
    'video': 'bx:bxs-video',
}
export const FileList = (props) => {

    const {files, newFiles, type, onDeleteExistingFile, onDeleteNewFile, onAddFile, showIcons, showFiles} = props

    const getIconName = () => {
      return typeIcon[type]
    }

    return (
        <>
            {
                showFiles && files.map((file, index) => {
                    return <FileItem
                        key={index}
                        index={index}
                        type={type}
                        fileUrl={file.absoluteUrl}
                        fileName={file.contentUrl}
                        deleteFile={(index) => onDeleteExistingFile(index, type)}
                        mimeType={file.mimeType}
                    />;
                })
            }
            {
                showFiles && newFiles.map((newFile, index) => {

                    return <FileItem
                        key={index + files.length}
                        index={index + files.length}
                        type={type}
                        fileUrl={window.URL.createObjectURL(newFile)}
                        fileName={newFile.name}
                        deleteFile={(index) => onDeleteNewFile(index, type)}
                        mimeType={newFile.type}
                    />;
                })
            }
            {
                showIcons
                ? <Dropzone
                    onDrop={(e) => onAddFile(e, type)}
                    onFileDialogCancel={()=>{}}
                    accept={type + "/*"}
                    >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone-content">
                                <Icon icon={getIconName()} width={24} style={{marginRight: '0.5rem', color: colors.brand, cursor: 'pointer'}}/>
                            </div>
                        </div>
                    )}
                </Dropzone>
                    : null
            }
        </>
    );

}

export default FileList;