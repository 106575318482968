import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {cancelSessionActions, hideModalPopup, showModalPopup, updateBookingNotesAction} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {ROUTE_CONTACT, ROUTE_MY_SUBSCRIPTION} from "../../../routes/constants";
import {getDate, getDayFromDateString, getTime} from "../../../utils/datetime";
import BookingFeedback from "./BookingFeedback";
import {getStringProperty} from "../../../utils/model/property";
import {
	canSetBookingFeedback,
	getProductOptionsFromBooking, getProductOptionsNameFromBooking,
	isCancelled,
	isFutureBooking,
	isWaitingForAgent
} from "../../../utils/model/booking";
import {Icon} from "@iconify/react/dist/iconify";
import InputField from "../../common/input/InputField";
import {Overlay, Tooltip} from "react-bootstrap";
import BookingChangeDateTime from "./BookingChangeDateTime";
import "../../../assets/css/components/tooltip/tooltip.scss"

export const Booking = ({booking, subscriptionId, toSetFeedback}) => {
	const history = useHistory()
	const dateString = booking.id ? `${getDayFromDateString(booking.bookingDate)}, ${getDate(booking.bookingDate)}` : ''
	const agentName = booking?.agents ? booking.agents[0]?.user?.name : '-'
	const type = booking.timeType ?? 0;
	const [notes, setNotes] = useState(booking.notes ?? '-')
	const [showTooltipStatus, setShowTooltipStatus] = useState(false)
	const tooltipStatusTarget = useRef(null)

	const [disabledObject, setDisabledObject] = useState({
		notes: true,
	})
	const dispatch = useDispatch()


	useEffect(() => {
		if (toSetFeedback && canSetBookingFeedback(booking)) {
			goToFeedback()
		}
	}, [])

	const toggleDisableInput = (key) => {
		setDisabledObject({...disabledObject, [key]: !disabledObject[key]})
	}
	const goToContact = () => {
		dispatch(hideModalPopup())
		history.push(ROUTE_CONTACT)
	}

	const goToFeedback = () => {
		dispatch(showModalPopup("Parerea ta conteaza", <BookingFeedback booking={booking}
																		subscriptionId={subscriptionId}/>))
	}

	const onCancelSession = () => {
		const callback = () => {
			const params = [booking.id]
			if (subscriptionId) {
				params.push(ROUTE_MY_SUBSCRIPTION + "?id=" + subscriptionId)
			}
			dispatch(hideModalPopup())
			dispatch(cancelSessionActions(...params))
		}
		dispatch(showModalPopup(
			"Anuleaza sedinta",
			<p>Doresti sa anulezi sedinta?</p>,
			callback,
			'md',
			null,
			'delete',
			'Anuleaza',
		))
	}

	const onCloseEdit = (keyValue) => {
		setNotes(booking.notes ?? '-')
		toggleDisableInput(keyValue)
	}
	const onUpdateNotes = () => {
		dispatch(updateBookingNotesAction(booking?.id, notes))
		toggleDisableInput('notes')
	}

	const onClickTooltip = () => {
		setShowTooltipStatus(true)
		setTimeout(function () {
			setShowTooltipStatus(false)
		}, 3000)
	}

	const onUpdateDateTime = () => {

		dispatch(showModalPopup(
			"Actualizeaza data si ora",
			<BookingChangeDateTime booking={booking} subscriptionId={subscriptionId}/>,
			null,
			'md',
			null,
			null,
		))
	}

	return (
		<div className="mb-4">
			<p className="font-weight-bold">
				{
					isCancelled(booking)
						? <span className="status-cancelled">Sedinta anulata</span>
						: null
				}
				{
					isWaitingForAgent(booking)
						? <span className="status-info">
							In cautare de agent{"  "}
							<span style={{cursor: 'pointer'}} ref={tooltipStatusTarget}
								  onClick={onClickTooltip}>
								<Icon icon="akar-icons:info" width={18}/>
							</span>
									<Overlay target={tooltipStatusTarget.current} show={showTooltipStatus}
											 placement="bottom">
								{(props) => (
									<Tooltip {...props}>
										Timpul de asteptare pentru confirmarea partenerului dureaza pana la maxim 24 de
										ore.
									</Tooltip>
								)}
							</Overlay>
						</span>
						: null
				}
			</p>
			<p className="mt-4 mb-4">
				<Icon icon="material-symbols:home-repair-service-outline" width={24}
							style={{fontWeight: 'bold', marginRight: '4px', marginTop: '-4px'}}/>
				{
					getProductOptionsNameFromBooking(booking)
				}
			</p>
			<p className="mt-4 mb-4">
				<Icon icon="akar-icons:calendar" width={24}
					  style={{fontWeight: 'bold', marginRight: '4px', marginTop: '-4px'}}/>
				{dateString}
			</p>
			<p className="mt-4 mb-4">
				<Icon icon="bx:time-five" width={24}
					  style={{fontWeight: 'bold', marginRight: '4px', marginTop: '-4px'}}/>
				{`${getTime(booking.localStartTime)} - ${getTime(booking.localEndTime)}`}
			</p>
			<div className="mb-4">
				<p className="mb-0"><span
					className="font-weight-bold">
					<Icon icon="bx:bx-map" width={24}
						  style={{fontWeight: 'bold', marginRight: '4px', marginTop: '-4px'}}/>
					</span>{booking?.listing?.property.address.fullAddress}</p>
				<p>{getStringProperty(booking?.listing?.property)}</p>
			</div>

			{
				booking.sessionNumber
					? <>
						<p>Sedinta {booking.orderSession}/{booking.maxOrderSession}</p>
					</>
					: null
			}

			{
				agentName
					? <><p className="font-weight-bold mb-0">
						Agent:
					</p>
						<p>
							{agentName ?? "-"}
						</p>
					</>
					: null
			}


			<div className="mb-5">
				<InputField
					label="Cum ai vrea sa te mai ajutam?"
					className="w-100"
					type="textarea"
					isTextarea={true}
					maxLength={255}
					rows={4}

					value={notes}
					setValue={setNotes}

					onUpdate={() => onUpdateNotes()}
					onCloseEdit={() => onCloseEdit('notes')}

					disabledObject={disabledObject.notes}
					toggleDisabledInput={() => toggleDisableInput('notes')}
				/>
			</div>


			{
				!isCancelled(booking)
					? <div className="w-100 mt-4 mb-4">

						{
							isFutureBooking(booking)
								? <button
									onClick={onUpdateDateTime}
									className="button-cta-sec button-medium mb-4"
									style={{marginRight: '0.5rem'}}
								>
									Schimba data si ora
								</button>
								: null
						}

						<button
							onClick={goToContact}
							className="button-cta-sec button-medium mb-4"
							style={{marginRight: '0.5rem'}}
						>
							! Cere ajutor
						</button>

						{
							canSetBookingFeedback(booking)
								?
								<button
									className="button-cta-sec button-medium mb-4"
									onClick={goToFeedback}
								>
									Vreau sa dau feedback
								</button>
								: null
						}

						{
							isFutureBooking(booking)
								?
								<button
									className="button-cta-sec button-medium"
									onClick={onCancelSession}
								>
									Vreau sa anulez sedinta
								</button>
								: null
						}
					</div>
					: null
			}


		</div>
	)
}
export default Booking