import {GET_PRODUCT_FORMS_SUCCESS, LOGOUT_RESET} from "../constants";

const initialState = null

export const productForms = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_FORMS_SUCCESS:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}