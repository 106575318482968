import { GET_PATH_SUCCESS, LOGOUT_RESET } from '../constants'

const initialState = null

export const path = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATH_SUCCESS:
      return action.payload
    case LOGOUT_RESET:
      return initialState
    default:
      return state
  }
}