import {batch} from 'react-redux'

import {GET_PAYMENTS_SUCCESS, HIDE_SPINNER, SHOW_SPINNER} from '../constants'
import {axiosRequest} from '../../api/axiosRequest';
import {showDefaultErrorAction} from "./alertModal.actions";

export const getPaymentsActions = () => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.get('/orders_simple_format')
		const payload = resp.data
		batch(() => {
			dispatch({type: GET_PAYMENTS_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}