import React, {useEffect} from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentsActions, showModalPopup} from "../../../redux/actions";
import NoDataAvailable from "../../common/NoDataAvailable";
import {getDate} from "../../../utils/datetime";
import {Icon} from "@iconify/react/dist/iconify";
import {colors} from "../../../utils/style/style";

import SimpleTable from "../../common/SimpleTable";
import Checkout from "./Checkout";

export const PaymentsPage = (props) => {
    const dispatch = useDispatch()
    const payments = useSelector(state => state.payments)

    useEffect(() => {
        dispatch(getPaymentsActions())
    }, [])

    const showCheckout = (order) => {
        dispatch(showModalPopup("Finalizeaza plata", <Checkout order={order}/>))
    }

    return (
        <AccountTemplate title="Plati">
            <h4 className="account-title">
                Platile mele
            </h4>
            {
                payments && !payments.length
                    ? <NoDataAvailable
                        text="Nu ai realizat nicio plata"
                    />

                    : payments && payments.length &&
                    <SimpleTable
                        headers={[
                            {
                                value: "Cod",
                                className: ""
                            },
                            {
                                value: "Serviciu",
                                className: "text-center",
                            },
                            {
                                value: "Data",
                                className: "text-center"
                            },
                            {
                                value: "Suma",
                                className: "text-center"
                            },
                            {
                                value: "Status",
                                className: "text-center"
                            }
                        ]}
                        entries={payments.map((entry) => {
                            return [
                                {
                                    value: '#' + entry.orderNumber,
                                    className: "",
                                },
                                {
                                    value: entry.mainService,
                                    className: "text-center",
                                },
                                {
                                    value: getDate(entry.createdAt),
                                    className: "text-center",
                                },
                                {
                                    value: <>{entry.value} {entry.currency}</>,
                                    className: "text-center",
                                },
                                {
                                    value: entry.orderStatus === 3 || !(entry.value > 0)
                                        ? <Icon icon="bi:check-circle-fill" color={colors.green}/>
                                        : <button className="button-small button-small-save"  onClick={() => showCheckout(entry)}>
                                            Plateste
                                        </button>,
                                    className: "text-center",
                                },
                            ]
                        })}
                    />
            }
        </AccountTemplate>
    )
}
export default PaymentsPage