import React from 'react';
import GuestTemplate from "../GuestTemplate";
import {Col, Container, Row} from "react-bootstrap";
import "../../../assets/css/guest/leagal.scss"

export const CookiesPage = () => {
	const label = "cookies"

	return (
		<GuestTemplate label={label} title="Politica de utilizare cookies" image={null}>
			<Container>
				<Row className="no-gutters">

					<Col sm={12} className="pl-4 pr-4 terms-content">
						<p className="text-center bg-color-sec pt-2 pb-2">Platforma Zumzi foloseste cookie-uri, atat
							proprii cat si de la terti, pentru a furniza vizitatorilor o experienta mult mai buna de
							navigare si servicii adaptate nevoilor si interesului fiecaruia.</p>
						<br/><br/>
						<p>In ceea ce numim "web 2.0", "cookie"-urile joaca un rol important in facilitarea accesului si
							livrarii multiplelor servicii de care utilizatorul se bucura pe internet, cum ar fi:
						</p>
						<ul style={{marginLeft: "20px"}}>
							<li>Personalizarea anumitor setari precum: limba in care este vizualizat un site, moneda in
								care se exprima anumite preturi sau tarife, pastrarea optiunilor pentru diverse produse
								in cosul de cumparaturi - generandu-se astfel flexibilitatea "cosului de cumparaturi";
							</li>
							<li>Cookie-urile ofera detinatorilor de site-uri un feedback valoros asupra modului cum sunt
								utilizate site-urile lor de catre utilizatori, astfel incat sa le poata face si mai
								eficiente si mai accesibile pentru utilizatori;
							</li>
							<li>Permit aplicatiilor multimedia sau de alt tip de pe alte site-uri sa fie incluse intr-un
								anumit site pentru a crea o experienta de navigare mai valoroasa, mai utila si mai
								placuta
							</li>
							<li>Imbunatatesc eficienta publicitatii online.</li>
						</ul>

						<br/>
						<ol>
							<li>CE ESTE UN "COOKIE"?
								<p>Un "Internet Cookie" (termen cunoscut si ca "browser cookie" sau "HTTP cookie" sau
									pur si simplu "cookie") este un fisier de mici dimensiuni, format din litere si
									numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale
									unui utilizator de pe care se acceseaza Internetul.
									<br/><br/>
									Cookie-ul este instalat prin solicitara emisa de catre un web-server unui browser si
									este complet "pasiv" (nu contine programe software, virusi sau spyware si nu poate
									accesa informatiile de pe hard driveul utilizatorului). Un cookie este format din 2
									parti: numele si continutul sau valoarea cookie-ului. Mai mult, durata de existenta
									a unui cookie este determinata; tehnic, doar webserverul care a trimis cookie-ul il
									poate accesa din nou in momentul in care un utilizator se intoarce pe website-ul
									asociat webserverului respectiv.
									<br/><br/>
									Cookie-urile in sine nu solicita informatii cu caracter personal pentru a putea fi
									utilizate si, in cele mai multe cazuri, nu identifica personal utilizatorii de
									internet.
									<br/><br/>
									Exista 2 categorii mari de cookie-uri:
									<ul className="terms-ul">
										<li>Cookieuri de sesiune - acestea sunt stocate temporar in dosarul de
											cookie-uri al browserului web pentru ca acesta sa le memoreze pana cand
											utilizatorul iese de pe web-siteul respectiv sau inchide fereastra
											browserului ( ex: in momentul logarii/delogarii pe un cont de webmail sau pe
											retele de socializare);
										</li>
										<li>Cookieuri Persistente - Acestea sunt stocate pe hard-drive-ul unui computer
											sau echipament (si in general depinde de durata de viata prestabilita pentru
											cookie). Cookie-urile persistente le includ si pe cele plasate de un alt
											website decat cel pe care il viziteaza utilizatorul la momentul respectiv -
											cunoscute sub numele de "third party cookies" (cookieuri plasate de terti) -
											care pot fi folosite in mod anonim pentru a memora interesele unui
											utilizator, astfel incat sa fie livrata publicitate cat mai relevanta pentru
											utilizatori.
										</li>
									</ul>
								</p>

							</li>
							<li>CARE SUNT AVANTAJELE COOKIE-URILOR?
								<p>Un cookie contine informatii care fac legatura intre un web-browser (utilizatorul) si
									un web-server anume (website-ul). Daca un browser acceseaza acel web-server din nou,
									acesta poate citi informatia deja stocata si reactiona in consecinta. Cookie-urile
									asigura userilor o experienta placuta de navigare si sustin eforturile multor
									websiteuri pentru a oferi servicii confortabile utilizatorillor: ex - preferintele
									in materie de confidentialitate online, optiunile privind limba site-ului, cosuri de
									cumparaturi sau publicitate relevanta.
								</p>
							</li>
							<li>CARE ESTE DURATA DE VIATA A UNUI COOKIE?
								<p>Cookieurile sunt administrate de webservere. Durata de viata a unui cookie poate
									varia semnificativ, depinzand de scopul pentru care este plasat. Unele cookie-uri
									sunt folosite exclusive pentru o singura sesiune (session cookies) si nu mai sunt
									retinute odata de utilizatorul a parasite website-ul si unele cookie-uri sunt
									retinute si refolosite de fiecare data cand utilizatorul revine pe acel website
									("cookie-uri permanente"). Cu toate acestea, cookie-urile pot fi sterse de un
									utilizator in orice moment prin intermediul setarilor browserului.
								</p>
							</li>
							<li>CUM SUNT FOLOSITE COOKIE-URILE DE CATRE ACEST SITE?
								<p>O vizita pe acest site poate plasa cookie-uri in scopuri de:
									<ul className="terms-ul">
										<li>Cookie-uri de performanta a site-ului</li>
										<li>Cookie-uri de analiza a vizitatorilor</li>
										<li>Cookie-uri pentru geotargetting</li>
										<li>Cookie-uri de inregistrare</li>
										<li>Cookie-uri pentru publicitate</li>
									</ul>
								</p>
							</li>
							<li>COOKIE-URI DE PERFORMANTA
								<p>Acest tip de cookie retine preferintele utilizatorului pe acest site, asa incat nu
									mai este nevoie de setarea lor la fiecare vizitare a site-ului.
								</p>
							</li>
							<li>COOKIE-URI PENTRU ANALIZA VIZITATORILOR
								<p>De fiecare data cand un utilizator viziteaza acest site softul de analytics furnizat
									de o terta parte genereaza un cookie de analiza a utilizatorului. Acest cookie ne
									spune daca ati mai vizitat acest site pana acum. Browserul ne va spune daca aveti
									acest cookie, iar daca nu, vom genera unul. Acesta permite monitorizarea
									utilizatorilor unici care ne viziteaza si cat de des o fac.
									<br/><br/>
									Atat timp cat nu sunteti inregistrat pe acest site, acest cookie nu poate fi folosit
									pentru a identifica persoanele fizice, ele sunt folosite doar in scop statistic.
									Daca sunteti inregistrat putem sti, de asemenea, detaliile pe care ni le-ati
									furnizat, cum ar fi adresa de e-mail si username-ul - acestea fiind supuse
									confidentialitatii si prevederilor din Termeni si Conditii, Politica de
									confidentialitate precum si prevederilor legislatiei in vigoare cu privire la
									protejarea datelor cu caracter personal.
								</p>
							</li>
							<li>COOKIE-URI PENTRU GEOTARGETTING
								<p>Aceste cookie-uri sunt utilizate de catre un soft care stabileste din ce tara
									proveniti. Este complet anonim si este folosit doar pentru a targeta continutul -
									chiar si atunci cand sunteti pe pagina noastra in limba romana sau in alta limba
									primiti aceeasi reclama.
								</p>
							</li>
							<li>COOKIE-URI PENTRU INREGISTRARE
								<p>Atunci cand va inregistrati pe acest site, generam un cookie care ne anunta daca
									sunteti inregistrat sau nu. Serverele noastre folosesc aceste cookie-uri pentru a ne
									arata contul cu care sunteti ingregistrat si daca aveti permisiunea pentru un
									serviciu anume. De asemenea, ne permite sa asociem orice comentariu pe care il
									postati pe site-ul nostru cu username-ul dvs.
								</p>
							</li>
							<li>CE TIP DE INFORMATII SUNT STOCATE SI ACCESATE PRIN INTERMEDIUL COOKIE-URILOR?
								<p>Cookie-urile pastreaza informatii intr-un fisier text de mici dimensiuni care permit
									unui website sa recunoasca un browser. Webserverul va recunoaste browserul pana cand
									cookie-ul expira sau este sters. Cookie-ul stocheaza informatii importante care
									imbunatatesc experienta de navigare pe Internet (ex: setarile limbii in care se
									doreste accesarea unui site; pastrarea unui user logat in contul de webmail;
									securitatea online banking; pastrarea produselor in cosul de cumparaturi)
								</p>
							</li>
							<li>DE CE SUNT COOKIE-URILE IMPORTANTE PENTRU INTERNET?
								<p>Cookie-urile reprezinta punctul central al functionarii eficiente a Internetului,
									ajutand la generarea unei experiente de navigare prietenoase si adaptata
									preferintelor si intereselor fiecarui utilizator. Refuzarea sau dezactivarea
									cookieurilor poate face unele site-uri imposibil de folosit.
									<br/><br/>
									Refuzarea sau dezactivarea cookie-urilor nu inseamna ca nu veti mai primi
									publicitate online - ci doar ca aceasta nu va mai putea tine cont de preferintele si
									interesele dvs, evidentiate prin comportamentul de navigare.
								</p>
							</li>
							<li>SECURITATE SI PROBLEME LEGATE DE CONFIDENTIALITATE
								<p>Cookieurile NU sunt virusi! Ele folosesc formate tip plain text. Nu sunt alcatuite
									din bucati de cod asa ca nu pot fi executate nici nu pot auto-rula. In consecinta,
									nu se pot duplica sau replica pe alte retele pentru a se rula sau replica din nou.
									Deoarece nu pot indeplini aceste functii, nu pot fi considerate virusi.
									<br/><br/>
									Cookie-urile pot fi totusi folosite pentru scopuri negative. Deoarece stocheaza
									informatii despre preferintele si istoricul de navigare al utilizatorilor, atat pe
									un anume site cat si pe mai multe alte site-uri, cookieurile pot fi folosite ca o
									forma de Spyware. Multe produse anti-spyware sunt constiente de acest fapt si in mod
									constant marcheaza cookie-urile pentru a fi sterse in cadrul procedurilor de
									stergere/scanare anti-virus/anti-spyware.
									<br/><br/>
									In general browserele au integrate setari de confidentialitate care furnizeaza
									diferite nivele de acceptare a cookieurilor, perioada de valabilitate si stergere
									automata dupa ce utilizatorul a vizitat un anumit site.
								</p>
							</li>
							<li>CUM POT OPRI COOKIE-URILE?
								<p>Dezactivarea si refuzul de a primi cookie-uri pot face anumite site-uri
									impracticabile sau dificil de vizitat si folosit. De asemenea, refuzul de a accepta
									cookie-uri nu inseamna ca nu veti mai primi/vedea publicitate online.
									<br/><br/>
									Este posibila setarea din browser pentru ca aceste cookie-uri sa nu mai fie
									acceptate sau poti seta browserul sa accepte cookie-uri de la un site anume. Dar, de
									exemplu, daca nu esti inregistat folosind cookie-urile, nu vei putea lasa
									comentarii.
									<br/><br/>
									Toate browserele moderne ofera posibilitatea de a schimba setarile cookie-urilor.
									Aceste setari se gasesc de regula in "optiuni" sau in meniul de "preferinte" al
									browserului tau.
									<br/><br/>
									Pentru a intelege aceste setari, puteti folosi optiunea "ajutor" a browserului
									pentru mai multe detalii.
									<ul className="terms-ul">
										<li>Cookie settings in Internet Explorer</li>
										<li>Cookie settings in Microsoft Edge</li>
										<li>Cookie settings in Firefox</li>
										<li>Cookie settings in Google Chrome</li>
										<li>Cookie settings in Safari</li>
									</ul>
								</p>
							</li>
						</ol>
					</Col>
				</Row>
			</Container>

		</GuestTemplate>
	)
}
export default CookiesPage