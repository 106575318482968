import React from "react";
import {AccountTemplate} from "../template/AccountTemplate";
import ProductFormInput from "../productForm/ProductFormInput";

export const OrderFormPage = (props) => {
    return (
        <AccountTemplate title="Formular de comanda">
            <ProductFormInput/>
        </AccountTemplate>
    )
}
export default OrderFormPage