import React from "react";
import {Icon} from "@iconify/react/dist/iconify";
import "../../../assets/css/common/buttons/edit_button.scss"

export const AddButtonSmall = (props) => {
    const text = props.text ?? "Adauga"

    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    return (
        <span className={"edit-button-container " + (props.className ?? "")} onClick={onClick}>
            <Icon icon="ci:plus"/>
            <span className="button-small button-small-edit">
                {text}
            </span>
        </span>
    )
}
export default AddButtonSmall