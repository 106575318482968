import {HIDE_SPINNER, LOGIN_SUCCESS, SHOW_ALERT_MODAL, SHOW_SPINNER} from "../constants";
import {axiosRequest} from "../../api/axiosRequest";
import {batch} from "react-redux";
import {loadFromLocalStorage, saveToLocalStorage} from "../../utils/storage";
import {HEADER_CONTENT_TYPE, HEADER_CONTENT_TYPE_FORM_DATA} from "../../utils/constants/constants";
import {showDefaultErrorAction} from "./alertModal.actions";

export const updateUserAction = (userId, body) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		const resp = await axiosRequest.put(`/users/${userId}`, body)
		const payload = getNewUser(resp)
		await saveToLocalStorage('user', payload, 60 * 24 * 365)

		batch(() => {
			dispatch({type: LOGIN_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
			dispatch({type: SHOW_ALERT_MODAL, payload: {message: "Date actualizate", type: 'success'}})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updatePasswordAction = (password, oldPassword) => async dispatch => {
	dispatch({type: SHOW_SPINNER})
	try {
		await axiosRequest.put(`/me/password`, {password, oldPassword})

		batch(() => {
			dispatch({type: SHOW_ALERT_MODAL, payload: {type: 'success', message: 'Parola actualizata'}})
			dispatch({type: HIDE_SPINNER})
		})
	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

export const updateProfileImageAction = (userId, event) => async dispatch => {
	dispatch({type: SHOW_SPINNER})

	let formData = new FormData();
	formData.append('file', event.target.files[0]);

	try {
		const resp = await axiosRequest.post(`/users/${userId}/images`, formData, {
			headers: {
				[HEADER_CONTENT_TYPE]: HEADER_CONTENT_TYPE_FORM_DATA,
			}
		})
		const payload = getNewUser(resp)
		await saveToLocalStorage('user', payload, 60 * 24 * 365)
		batch(() => {
			dispatch({type: LOGIN_SUCCESS, payload})
			dispatch({type: HIDE_SPINNER})
		})

	} catch (err) {
		dispatch(showDefaultErrorAction(err))
	}
}

const getNewUser = (resp) => {
	const data = {
		name: resp.data.name,
		email: resp.data.email,
		phone: resp.data.phone,
		image: resp.data.image,
	}

	return {...loadFromLocalStorage('user'), ...data}
}
