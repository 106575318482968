import React, {useEffect} from "react";
import {getBillingDataAction, showModalPopup} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import EditButton from "../../common/buttons/EditButton";

import "../../../assets/css/account/personal_data/address.scss"
import {BILLING_DATA_TYPE_COMPANY} from "../../../utils/model/billingData";
import BillingDataInput from "./BillingDataInput";
import AddButtonSmall from "../../common/buttons/AddButtonSmall";

export const BillingData = (props) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.login)
    const billingData = useSelector(state => state.billingData)

    useEffect(() => {
        dispatch(getBillingDataAction(user?.id, BILLING_DATA_TYPE_COMPANY))
    }, [])

    const onShowModal = () => {
        if (billingData?.id) {
            dispatch(showModalPopup('Actualizeaza datele', <BillingDataInput />, null, 'lg'))
        } else {
            dispatch(showModalPopup('Adauga date', <BillingDataInput />, null, 'lg'))
        }
    }

    return (
        <>
            <h6 className="mb-4">
                Date facturare
            </h6>
            {
                billingData?.id && billingData.type === BILLING_DATA_TYPE_COMPANY
                    ? <div>
                                <span style={{marginRight: '0.5rem', opacity: '0.5'}}>
                                {billingData.name}
                            </span>
                        <EditButton
                            onClick={onShowModal}
                        />
                    </div>
                    : <AddButtonSmall onClick={onShowModal} text="Adauga companie"/>
            }


        </>
    )
}
export default BillingData