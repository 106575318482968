import {Link} from "react-router-dom";
import React, {useState} from "react";
import "../../assets/css/components/cookie.scss"
import {loadFromLocalStorage, saveToLocalStorage} from "../../utils/storage";

export const CookieBanner = (props) => {
    const [toHide, setToHide] = useState(loadFromLocalStorage('cookies', null))

    const setCookies = () => {
        setToHide(true)
        saveToLocalStorage("cookies", true, 60 * 24 * 365 * 10)
    }

    return (
        <>
            {
                !toHide
                    ? <div className="cookie-banner">
                        <div className="cookie-banner-container">
                            <div style={{marginLeft: '1rem'}}>
                                <p className="mb-0">
                                    Acest site utilizează cookies, pentru o experiență de utilizator mai bună. Continuarea
                                    navigării înseamnă acceptarea folosirii acestora. Află mai multe în
                                    <Link to="/politica-de-utilizare-cookie" style={{marginLeft: '0.5rem'}}>Politica de Utilizare Cookie</Link>
                                </p>
                            </div>

                            <div>
                                <button className="button-medium button-small-save" onClick={setCookies}>
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
export default CookieBanner