import { LOGOUT_RESET, GET_ROOT_CATEGORIES, GET_CATEGORY } from '../constants'

const initialState = []

export const categories = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROOT_CATEGORIES:
            return action.payload
        case GET_CATEGORY:
            return action.payload
        case LOGOUT_RESET:
            return initialState
        default:
            return state
    }
}