import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addVoucherActions,
    getShareVoucherActions,
    getVouchersActions,
    showModalPopup,
    updateVoucherActions
} from "../../../redux/actions";
import {Button, Overlay, Tooltip} from "react-bootstrap";
import {isVoucherCode} from "../../../utils/validation/conditions";
import {translate} from "../../../utils/i18n";
import {useInputValidation} from "../../../utils/hooks/useInputValidation";
import {getItemFromUrl} from "../../../utils/interface/queryHandler";
import {isValidObject} from "../../../utils/validation/validate";
import {getDate} from "../../../utils/datetime";
import {getStringValue} from "../../../utils/valueType";
import {Redirect} from "react-router-dom";
import InputField from "../../common/input/InputField";

import "../../../assets/css/components/tooltip/tooltip.scss"
import "../../../assets/css/account/vouchers.scss"
import SetCurrentButton from "../../common/buttons/SetCurrentButton";

export const Vouchers = (props) => {
    const [voucherCode, setVoucherCode] = useState(getItemFromUrl(window.location.search, 'voucherCode'))
    const [toRedirect, setToRedirect] = useState(false)
    const redirectPath = getItemFromUrl(window.location.search, 'redirectPath')

    const [referralPrice, setReferralPrice] = useState('')
    const userVouchers = useSelector(state => state.vouchers.userVouchers)
    const user = useSelector(state => state.login)
    const voucherReferral = useSelector(state => state.vouchers.voucherReferral)

    const dispatch = useDispatch()
    const tooltipTarget = useRef(null)
    const [showCopyToClipboard, setShowCopyToClipboard] = useState(false)

    const validations = {
        voucherCode: useInputValidation(voucherCode, isVoucherCode(voucherCode), translate('validation.voucher_code.invalid'))
    }

    useEffect(() => {
        dispatch(getVouchersActions(user?.id))
        dispatch(getShareVoucherActions(user?.id))
    }, [])

    useEffect(() => {
        if (voucherReferral?.voucher?.offer) {
            setReferralPrice(getStringValue(voucherReferral?.voucher?.offer.value, voucherReferral?.voucher?.offer.valueType))
        }
    }, [voucherReferral])

    const onSubmitVoucherCode = () => {
        if (!isValidObject(validations)) {
            return
        }

        dispatch(addVoucherActions(user?.id, voucherCode))
        if (redirectPath) {
            setToRedirect(true)
        }
    }

    const copyToClipboard = async () => {
        const message = `Hey! Descarcă aplicația Zumzi sau intra pe site-ul zumzi.com și utilizează codul meu ${voucherReferral?.voucher?.code} pentru a primi ${referralPrice} credit pentru servicii (se aplică condiții).

AppStore: https://apps.apple.com/ro/app/zumzi/id1577525960?l=ro
      
GooglePlay: https://play.google.com/store/apps/details?id=com.zumzi`

        navigator.clipboard.writeText(message)
        setShowCopyToClipboard(true)
        setTimeout(function () {
            setShowCopyToClipboard(false)
        }, 3000)
    }

    const setCurrentVoucher = (voucher) => {
        const callback = () => {
            dispatch(updateVoucherActions(user?.id, voucher?.id))
        }
        dispatch(showModalPopup(
            "Schimba voucher",
            <p>Doresti sa schimbi voucher-ul curent?</p>,
            callback
        ))
    }

    if (toRedirect) {
        return <Redirect to={redirectPath}/>
    }

    return (
        <>
            <h4 className="account-title">
                Ai un voucher?
            </h4>
            <InputField
                label="Adauga cod"
                labelClassName="font-weight-normal"
                className="input-small"
                type="text"
                placeholder={translate('global.placeholder.voucher_code')}
                value={voucherCode}
                setValue={setVoucherCode}
                validation={validations.voucherCode}
                onSubmitForm={onSubmitVoucherCode}
            />
            <Button className="button-gradient-primary" onClick={() => onSubmitVoucherCode()}>
                Activeaza
            </Button>
            {
                redirectPath && <Button onClick={() => setToRedirect(true)}>
                    Inapoi
                </Button>
            }

            {
                userVouchers?.length
                ? <h4 className="account-title" style={{marginTop: '5rem'}}>
                    Codurile mele:
                </h4>
                : ""
            }

        <div>
            {
                userVouchers.map((el, index) => (
                    <div key={index} className="flex-item-in-list">
                        <div className="flex-item-in-list-container">
                            <p>
                                {el.voucher.code} - {getStringValue(el?.voucher?.offer?.value, el?.voucher?.offer?.valueType)}
                            </p>
                            <span>
                                Exp: {getDate(el.voucher?.offer?.expireAt)}
                            </span>
                        </div>
                        <div>
                            <SetCurrentButton
                                isSelected={!index}
                                onClickButton={() => index ? setCurrentVoucher(el) : {}}
                            />
                        </div>
                    </div>
                ))
            }
        </div>

        {
            !props.hideReferral
            ? <>
                    <hr style={{marginTop: '5rem'}}/>

                    <div className="voucher-referral-container">
                        <h4 className="account-title" style={{margin: '2rem 0'}}>
                            Trimite codul unui prieten si primesti {referralPrice} pentru fiecare recomandare
                        </h4>
                        <p style={{fontWeight: 'bold'}}>
                            Distribuie codul tau
                        </p>

                        <div style={{display: 'flex'}}>
                <span className="button-cta-sec" style={{fontWeight: 'bold', marginRight: '10px'}}>
                    {voucherReferral?.voucher?.code}
                </span>
                            <Button ref={tooltipTarget} className="button-gradient-primary button-medium" onClick={copyToClipboard}>
                                Copiaza
                            </Button>
                            <Overlay target={tooltipTarget.current} show={showCopyToClipboard} placement="bottom">
                                {(props) => (
                                    <Tooltip {...props}>
                                        Codul este copiat
                                    </Tooltip>
                                )}
                            </Overlay>
                        </div>
                    </div>
            </>
            : null
        }
        </>
    )
}
export default Vouchers