export const orderDetailsInitialState = {
    serviceNotes: '',
    property: {
        number: '',
        block: '',
        stairwell: '',
        floorNumber: '',
        apartment: '',
        intercom: '',
        accessMode: '',
    },
    buyLockbox: false,
    currentStep: 1,
    totalPrice: '',
    currency: 'RON'
}